import React, { useState } from 'react';
import {
  Controller,
  ErrorMessage as FormErrorMessage,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from 'src/components/Button';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import PasswordInput from 'src/components/PasswordInput';
import TextField from 'src/components/TextField';
import { Title } from 'src/components/FormComponents';
import UnverifiedUserSection from 'src/components/UnverifiedUserSection';
import { DEFAULT_CONTACT_EMAIL, MD_BREAK_POINT } from 'src/utils/constants';
import { signUp } from 'src/restClient';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
  }
  @media (max-width: ${MD_BREAK_POINT}) {
    display: block;
    > div {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const Text = styled.p`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-ink-notasdark);
`;

const Link = styled.a`
  margin: 0 2px;
  color: var(--color-brand-tend);
  font-size: 16px;
  text-decoration: none;
`;

const Divider = styled.div`
  width: 100%;
  min-width: 100%;
  height: 0;
  margin: 32px 0;
  background: none;
  border-top: solid var(--color-support-lines) 1px;
`;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  companyName: Yup.string().required(),
  phoneNumber: Yup.string()
    .required()
    .test('phoneNumber', 'Not a valid phone number', (value) => {
      return parsePhoneNumberFromString(value, 'US')?.isValid() || false;
    }),
  email: Yup.string().email('Invalid email').required(),
  password: Yup.string()
    .required(' ')
    .min(8, 'Your password must be at least 8 characters.')
    .max(255, 'Your password must be less than 255 characters.'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
});

type FormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  password: string;
  passwordConfirmation?: string;
};

const SignUpForm: React.FC = () => {
  const {
    control,
    errors,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onBlur',
    validationSchema: SignupSchema,
  });
  const [signedUp, setSignedUp] = useState(false);
  const [error, setError] = useState(undefined);
  const [email, setEmail] = useState<string>();
  const onSubmit = async (values: FormValues) => {
    delete values.passwordConfirmation;
    values.phoneNumber =
      parsePhoneNumberFromString(values.phoneNumber, 'US')?.number.toString() ||
      values.phoneNumber;
    const response = await signUp(values);

    if (response.ok) {
      setEmail(values.email);
      setSignedUp(true);
    } else {
      const { errors: responseErrors, message } = await response.json();

      if (responseErrors?.length) {
        setError(responseErrors[0].email || responseErrors[0].phoneNumber);
      } else {
        setError(message);
      }
    }
  };
  return signedUp && email ? (
    <UnverifiedUserSection email={email} />
  ) : (
    <>
      <Title>Get qualified staff on Demand</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer>
          <div>
            <Label required>First Name</Label>
            <TextField>
              <Controller
                name="firstName"
                control={control}
                as={<Input type="text" isValid={!errors.firstName} />}
              />
            </TextField>
          </div>
          <div>
            <Label required>Last Name</Label>
            <TextField>
              <Controller
                name="lastName"
                control={control}
                as={<Input type="text" isValid={!errors.lastName} />}
              />
            </TextField>
          </div>
        </FlexContainer>
        <Label required>Company Name</Label>
        <TextField>
          <Controller
            name="companyName"
            control={control}
            as={<Input type="text" isValid={!errors.companyName} />}
          />
        </TextField>
        <Label required>Phone Number</Label>
        <TextField>
          <Controller
            name="phoneNumber"
            control={control}
            as={<Input type="text" isValid={!errors.phoneNumber} />}
          />
        </TextField>
        <Label required>Email</Label>
        <TextField>
          <Controller
            name="email"
            control={control}
            as={<Input type="email" isValid={!errors.email} />}
          />
        </TextField>
        <Label required>Password</Label>
        <Controller
          name="password"
          control={control}
          as={
            <PasswordInput
              label=" "
              name="password"
              isValid={!errors.password}
            />
          }
        />
        <FormErrorMessage errors={errors} name="password" as={ErrorMessage} />
        <Label required>Confirm Password</Label>
        <Controller
          name="passwordConfirmation"
          control={control}
          as={
            <PasswordInput
              label=" "
              name="passwordConfirmation"
              isValid={!errors.passwordConfirmation}
            />
          }
        />
        <FormErrorMessage
          errors={errors}
          name="passwordConfirmation"
          as={ErrorMessage}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Container>
          <Button type="submit" disabled={isSubmitting}>
            Sign up
          </Button>
          <div>
            Questions?&nbsp;
            <Link target="_blank" href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>
              Schedule a call
            </Link>
          </div>
        </Container>
      </form>
      <Divider />
      <Text>Message and data rates may apply.</Text>
      <Text>
        By clicking Sign up you agree on our&nbsp;
        <Link href="https://app.termly.io/document/terms-of-use-for-website/66181a94-e9b6-4c64-b0cb-d2554d502bfc">
          Terms &amp; Conditions&nbsp;
        </Link>
        and&nbsp;
        <Link href="https://app.termly.io/document/privacy-policy-for-website/a9f74286-8242-4240-a49f-90f179416cea">
          Privacy Policy
        </Link>
      </Text>
    </>
  );
};

export default SignUpForm;
