import styled from 'styled-components';
import React from 'react';

const AvatarPlaceholder = styled.div`
  background-color: var(--color-primary-light);
  height: 100%;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 16px;
`;

const AvatarLabel = styled.p`
  color: var(--color-primary);
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 0;
  padding-top: 18px;
`;

type Props = {
  firstName: string;
  lastName: string;
};

const BlankAvatar: React.FC<Props> = ({ firstName, lastName }) => (
  <AvatarPlaceholder>
    <AvatarLabel>
      {firstName.charAt(0).toUpperCase()}
      {lastName.charAt(0).toUpperCase()}
    </AvatarLabel>
  </AvatarPlaceholder>
);

export default BlankAvatar;
