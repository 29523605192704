import gql from 'graphql-tag';
import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import SHIFT_FOR_JOB_DETAILS from '../fragments/ShiftForJobDetails';
import INSTRUCTIONS_FOR_JOB_DETAILS from '../fragments/InstructionsForJobDetails';
import {
  DeleteShiftFromClientJob,
  DeleteShiftFromClientJobVariables,
} from './__generated__/DeleteShiftFromClientJob';

const DELETE_SHIFT_FROM_CLIENT_JOB = gql`
  mutation DeleteShiftFromClientJob($shiftId: ID!) {
    deleteShiftFromClientJob(shiftId: $shiftId) {
      id
      startDateTime
      endDateTime
      total
      instructions {
        ...InstructionsForJobDetails
      }
      shifts {
        ...ShiftForJobDetails
      }
    }
  }
  ${INSTRUCTIONS_FOR_JOB_DETAILS}
  ${SHIFT_FOR_JOB_DETAILS}
`;

type MutationOptions = MutationHookOptions<
  DeleteShiftFromClientJob,
  DeleteShiftFromClientJobVariables
>;
export const useDeleteShiftFromClientJobMutation = (
  shiftId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<
    DeleteShiftFromClientJob,
    DeleteShiftFromClientJobVariables
  >(DELETE_SHIFT_FROM_CLIENT_JOB, {
    ...options,
    onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
      (data) => {
        track(EVENTS.DELETED_SHIFT, { 'Shift ID': shiftId });
        onCompleted?.(data);
      },
      [shiftId, onCompleted],
    ),
  });
};

export default DELETE_SHIFT_FROM_CLIENT_JOB;
