import styled from 'styled-components';

interface Props {
  required?: boolean;
  bold?: boolean;
}

const Label = styled.p<Props>`
  font-weight: ${({ bold = true }) => (bold ? '600' : '500')};
  margin-bottom: 7px;
  color: var(--color-ink-dark);
  ${({ required }) =>
    required &&
    `
    &::before {
      content: "* ";
      color: var(--color-status-destructive);
      font-weight: bold;
    }
  `}
`;

export default Label;
