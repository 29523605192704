/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ClientTenderStatus {
  BLOCKED = "BLOCKED",
  FAVORITE = "FAVORITE",
  LESS_PREFERRED = "LESS_PREFERRED",
}

export enum DeprecatedParkingEnum {
  DIFFICULT = "DIFFICULT",
  LOT_FREE = "LOT_FREE",
  LOT_NEARBY = "LOT_NEARBY",
  LOT_TO_BE_CONFIRMED = "LOT_TO_BE_CONFIRMED",
  NO_PARKING = "NO_PARKING",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  STREET_AMPLE = "STREET_AMPLE",
  STREET_FAR = "STREET_FAR",
  STREET_LIMITED = "STREET_LIMITED",
}

export enum ParkingEnum {
  LOT_FREE = "LOT_FREE",
  LOT_NEARBY = "LOT_NEARBY",
  LOT_TO_BE_CONFIRMED = "LOT_TO_BE_CONFIRMED",
  NO_PARKING = "NO_PARKING",
  STREET_AMPLE = "STREET_AMPLE",
  STREET_FAR = "STREET_FAR",
  STREET_LIMITED = "STREET_LIMITED",
}

export enum TipType {
  ALLOW_JAR = "ALLOW_JAR",
  INCLUDE_TIP = "INCLUDE_TIP",
  NO_TIP = "NO_TIP",
  ON_SITE = "ON_SITE",
}

export enum VenueType {
  COMMERCIAL = "COMMERCIAL",
  RESIDENTIAL = "RESIDENTIAL",
}

export interface AddShiftToClientJobInput {
  quantity: number;
  positionId: string;
  startDateTime: any;
  endDateTime: any;
  tipType?: TipType | null;
  tipAmount?: number | null;
  unpaidBreakMinutes?: number | null;
  attire?: string | null;
  blackNonSlipShoes?: boolean | null;
  blackApron?: boolean | null;
  barKit?: boolean | null;
  knifeKit?: boolean | null;
}

export interface AddressInput {
  streetName?: string | null;
  streetNumber?: string | null;
  interiorNumber?: string | null;
  neighborhood?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  lat: number;
  lng: number;
}

export interface ChangeTenderStatusAsClientInput {
  tenderId: string;
  staffingId: string;
}

export interface ClientPasswordChangeInput {
  password: string;
}

export interface ContactEditInput {
  name: string;
  phoneNumber?: string | null;
  instructions?: string | null;
  id?: string | null;
}

export interface ContactInput {
  instructions?: string | null;
  name: string;
  phoneNumber?: string | null;
}

export interface CoordsInput {
  lat: number;
  lng: number;
}

export interface CreateJobAsClientInput {
  venue: VenueInput;
  job: JobInput;
  locationContact?: ContactInput | null;
  shifts: ShiftInput[];
  jobInstructions: JobInstructionInput[];
}

export interface CreateJobAsGuestInput {
  guest: GuestInput;
  venue: VenueInput;
  job: JobInput;
  contact?: ContactInput | null;
  locationContact?: ContactInput | null;
  shifts: ShiftInput[];
  jobInstructions: JobInstructionInput[];
}

export interface EditJobLocationInput {
  venue: VenueInput;
  locationContact: ContactEditInput;
  mealProvided: boolean;
  useBackDoor: boolean;
}

export interface GuestInput {
  name: string;
  phoneNumber: string;
  email: string;
}

export interface JobInput {
  description?: string | null;
  detailsForApprovedTenders?: string | null;
  endDateTime?: any | null;
  mealProvided?: boolean | null;
  name: string;
  startDateTime?: any | null;
  useBackDoor?: boolean | null;
}

export interface JobInstructionInput {
  attire: string;
  blackApron: boolean;
  blackNonSlipShoes: boolean;
  maskRequired: boolean;
  barKit?: boolean | null;
  knifeKit?: boolean | null;
  positionId: string;
}

export interface JobInstructionUpdateInput {
  id: string;
  attire?: string | null;
  blackApron?: boolean | null;
  blackNonSlipShoes?: boolean | null;
  barKit?: boolean | null;
  knifeKit?: boolean | null;
  maskRequired?: boolean | null;
}

export interface MyClientJobsFilters {
  name?: string | null;
  upcoming?: boolean | null;
  past?: boolean | null;
}

export interface ShiftInput {
  endDateTime: any;
  positionId: string;
  quantity: number;
  startDateTime: any;
  tipAmount?: number | null;
  tipType?: TipType | null;
  unpaidBreakMinutes?: number | null;
}

export interface UpdateShiftInClientJobInput {
  quantity?: number | null;
  startDateTime?: any | null;
  endDateTime?: any | null;
  tipType?: TipType | null;
  tipAmount?: number | null;
  unpaidBreakMinutes?: number | null;
}

export interface UploadJobTimesheetFileInput {
  name: string;
  fileContents: string;
  fileContentType: string;
}

export interface VenueInput {
  address: AddressInput;
  name?: string | null;
  otherInstructions?: string | null;
  parkingInstructions?: string | null;
  parkingType: ParkingEnum;
  type: VenueType;
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
