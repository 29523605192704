import React from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';

const Divider = styled.span<{ touched?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  cursor: ${({ touched }) => touched && 'pointer'};
`;

const Title = styled.p<{ isPrevOrCurrent: boolean }>`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  color: ${({ isPrevOrCurrent }) =>
    !isPrevOrCurrent && 'var(--color-ink-notasdark)'};
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const Circle = styled.div<{
  isPrevOrCurrent: boolean;
  touched?: boolean;
  isActive?: boolean;
}>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? 'var(--color-primary)' : 'var(--color-ink-clear)'};
  border-color: ${({ isPrevOrCurrent }) =>
    isPrevOrCurrent ? 'var(--color-primary)' : 'var(--color-ink-notasdark)'};
  color: ${({ isPrevOrCurrent, isActive }) =>
    isPrevOrCurrent
      ? isActive
        ? 'var(--color-ink-clear)'
        : 'var(--color-primary)'
      : 'var(--color-ink-notasdark)'};
  border-width: 2px;
  border-style: solid;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

interface StepProps {
  title: string;
  current?: number;
  stepNumber?: number;
  onClick?: (current: number) => void;
  touched?: boolean;
}

const Step: React.FC<StepProps> = (props) => {
  const { current, stepNumber, title, onClick, touched } = props;
  if (!current || !stepNumber || !onClick) {
    return null;
  }
  const isActive = current === stepNumber;
  const isPrevOrCurrent = touched || isActive;
  const content = touched ? <MaterialIcon icon="done" /> : stepNumber;
  const handleClick = (next: number) => {
    if (next > current) {
      return;
    }
    onClick(stepNumber);
  };
  return (
    <Divider touched={touched}>
      <Circle
        isActive={isActive}
        touched={touched}
        isPrevOrCurrent={isPrevOrCurrent}
        onClick={() => handleClick(stepNumber)}
      >
        {content}
      </Circle>
      <Title isPrevOrCurrent={isPrevOrCurrent}>{title}</Title>
    </Divider>
  );
};

export default Step;
