import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { format, isFuture } from 'date-fns';

import { MyClientJob_myClientJob as Job } from 'src/graphql/queries/__generated__/MyClientJob';
import { GetShiftsForMyJob_getShiftsForMyJob as Shift } from 'src/graphql/queries/__generated__/GetShiftsForMyJob';
import { GET_SHIFTS_FOR_MY_JOB } from 'src/graphql/queries/getShiftsForMyJob';

import Spinner from 'src/components/Spinner';
import { GetShiftsForMyJob } from 'src/graphql/queries/__generated__/GetShiftsForMyJob';
import { Snackbar } from 'src/components/BookingStyles/BookingStyles.styled';
import TenderCard from 'src/components/TenderCard';
import { EmptyState } from 'src/components/TenderListEmptyState';
import SearchBar from 'src/components/SearchBar';
import Text from 'src/components/Text';

import {
  Separator,
  SpinnerContainer,
  TenderListContainer,
  WidthLimiter,
} from 'src/components/BookingStyles/BookingStyles.styled';

type Props = {
  booking: Job;
};

type ShiftRowProps = {
  shift: Shift;
};

const ShiftRow: React.FC<ShiftRowProps> = ({ shift }) => {
  const formatDate = (date: Date) => {
    return format(date, 'MMMM	Lo h:mmaaaa').replace(/\./g, '');
  };
  return (
    <>
      <WidthLimiter>
        <Text preset="preset4" bold color="var(--color-ink-notasdark)">
          {`${shift.staffings?.length} ${shift.position.name}${
            shift.staffings?.length ? 's' : ''
          }`}
        </Text>
        <Text preset="preset4" color="var(--color-ink-notasdark)">
          {`${formatDate(new Date(shift.startDateTime))} - ${formatDate(
            new Date(shift.endDateTime),
          )}`}
        </Text>
      </WidthLimiter>
      <TenderListContainer>
        {shift.staffings?.map(
          (staffing) =>
            staffing.tender && (
              <TenderCard
                key={staffing.id}
                staffingId={staffing.id}
                tender={staffing.tender}
                shift={shift}
              />
            ),
        )}
      </TenderListContainer>
    </>
  );
};

const BookingStaffTab: React.FC<Props> = ({ booking }) => {
  const history = useHistory();
  const snackMessage = history.location.state?.snackMessage;
  const [searchInput, setSearchInput] = useState<string>();
  const { data, loading } = useQuery<GetShiftsForMyJob>(GET_SHIFTS_FOR_MY_JOB, {
    variables: { jobId: booking.id, filter: searchInput },
    notifyOnNetworkStatusChange: true,
  });
  const shifts = data?.getShiftsForMyJob;
  if (isFuture(new Date(booking.startDateTime))) {
    return <Redirect to={history.location.pathname.replace('/staff', '')} />;
  }

  return (
    <>
      {snackMessage && (
        <Snackbar
          timeoutMs={5000}
          message={snackMessage}
          actionText="×"
          onClose={() => {
            history.replace(history.location.pathname, {});
          }}
        />
      )}
      <WidthLimiter>
        <SearchBar onChange={setSearchInput} />
        <Separator />
      </WidthLimiter>
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : shifts?.length ? (
        shifts.map((shift) => <ShiftRow key={shift.id} shift={shift} />)
      ) : (
        <EmptyState
          title={'There are no Tenders staffed in this Job just yet'}
          subtitle={
            'As soon as we have Tenders staffed for you, they will show up here'
          }
          search={searchInput}
        />
      )}
    </>
  );
};

export default BookingStaffTab;
