import * as React from 'react';
import { decodeToken } from 'src/auth';
import { getToken, removeToken, setToken } from 'src/storage';

const useAuthHandler = (defaultToken: string) => {
  const [authToken, setAuth] = React.useState(defaultToken);
  const [decodedAuthToken, setDecodedToken] = React.useState(
    decodeToken(defaultToken),
  );
  const setAuthToken = (token: string) => {
    setToken(token);
    setDecodedToken(decodeToken(token));
    setAuth(token);
  };
  const removeAuthToken = () => {
    removeToken();
    setDecodedToken(decodeToken(getToken()));
    setAuth(getToken());
  };
  return {
    authToken,
    decodedAuthToken,
    setAuthToken,
    removeAuthToken,
  };
};

export default useAuthHandler;
