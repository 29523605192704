import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  ParkingGroups,
  ParkingGroups_parkingGroups as ParkingGroup,
} from 'src/graphql/queries/__generated__/ParkingGroups';
import { GET_PARKING_GROUPS } from 'src/graphql/queries/getParkingGroups';

const DEPRECATED_PARKING_TYPES = ['DIFFICULT', 'PUBLIC', 'PRIVATE'];

export const getParkingType = (
  parkingData?: ParkingGroups,
  value?: string | null,
): ParkingGroup | undefined => {
  return parkingData?.parkingGroups.find((parkingGroup) => {
    if (value && parkingGroup.name === value) {
      return true;
    }
    return parkingGroup.options?.some((parking) => parking.value === value);
  });
};

const useParking = (value: string | undefined | null) => {
  const { data: parkingData } = useQuery<ParkingGroups>(GET_PARKING_GROUPS);

  const [parkingType, setParkingType] = useState(value);
  const [parking, setParking] = useState(
    getParkingType(parkingData, parkingType),
  );
  useEffect(() => {
    setParking(getParkingType(parkingData, value));
  }, [parkingData, value]);

  const updateParking = (newValue?: string) => {
    if (newValue && DEPRECATED_PARKING_TYPES.includes(newValue)) {
      return undefined;
    }
    const newParking = getParkingType(parkingData, newValue);
    setParking(newParking);
    if (newParking?.options?.length === 1) {
      setParkingType(newParking.options[0].value);
    } else if (newParking?.name === newValue) {
      setParkingType(undefined);
    } else {
      setParkingType(newValue);
    }
  };

  return {
    parkingType,
    parking,
    parkingOptions: parkingData?.parkingGroups,
    updateParking,
  };
};

export default useParking;
