import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { AttireType } from 'src/components/TenderInstructionsInput/TenderInstructionsInput';
import { Modal, ModalContext } from 'src/components/Modal';
import TenderInstructionsInput from 'src/components/TenderInstructionsInput';
import {
  MyClientJob_myClientJob_instructions as AttireQuery,
  MyClientJob_myClientJob_instructions_position as Position,
} from 'src/graphql/queries/__generated__/MyClientJob';
import { EditJobAttireVariables } from 'src/graphql/mutations/__generated__/EditJobAttire';
import { JobInstructionUpdateInput } from 'src/__generated__/globalTypes';
import { useEditJobAttireMutation } from 'src/graphql/mutations/EditJobAttire';
import Footer from './Footer';

type Props = {
  attires: AttireQuery[] | null;
  jobId: string;
};

type AttireEditForm = {
  customAttire?: string;
  attire?: AttireType;
  blackApron?: boolean | null;
  blackNonSlipShoes?: boolean | null;
  barKit?: boolean | null;
  knifeKit?: boolean | null;
  position?: Position;
};

interface Attire extends AttireEditForm {
  id: string;
}

type Instructions = { [key: string]: Attire };
type InstructionsForm = { [key: string]: AttireEditForm };

type FormData = {
  jobInstructions: Instructions;
};

const parseAttire = (shiftAttire: AttireQuery): Attire => {
  const hasCustomAttire =
    shiftAttire.attire !== 'All Black' &&
    shiftAttire.attire !== 'Black Bistro' &&
    shiftAttire.attire !== 'White Chef Coat';
  const attireOrUndefined = shiftAttire.attire || undefined;
  const { __typename, ...rest } = shiftAttire;
  return {
    ...rest,
    attire: hasCustomAttire ? 'custom' : (attireOrUndefined as AttireType),
    customAttire: hasCustomAttire ? attireOrUndefined : undefined,
  };
};
const sanitizeJobInstructions = (
  formData: FormData,
): EditJobAttireVariables['input'] => {
  const instructions: JobInstructionUpdateInput[] = [];
  for (const key in formData.jobInstructions) {
    const { customAttire, position, ...instruction } =
      formData.jobInstructions[key];
    instructions.push({
      ...instruction,
      attire: customAttire || instruction.attire,
    });
  }
  return instructions;
};
const singleAttireValidationSchema = Yup.object().shape({
  attire: Yup.string().required('* Please select an attire option.'),
  customAttire: Yup.string().when(['attire'], {
    is: 'custom',
    then: Yup.string().required(),
  }),
});

const EditAttireModal: React.FC<Props> = ({ attires, jobId }) => {
  let allAttiresValidationSchema = Yup.object();
  const modalContext = useContext(ModalContext);
  const defaultValues: FormData = {
    jobInstructions: {},
  };
  attires?.forEach((attire) => {
    allAttiresValidationSchema = allAttiresValidationSchema.concat(
      Yup.object().shape({
        [attire.position.id]: singleAttireValidationSchema,
      }),
    );
    defaultValues.jobInstructions[attire.position.id] = parseAttire(attire);
  });
  const validationSchema = Yup.object().shape({
    jobInstructions: allAttiresValidationSchema,
  });
  const methods = useForm<FormData>({ validationSchema, defaultValues });
  methods.register('jobInstructions');
  const [editJobAttire, { loading }] = useEditJobAttireMutation(jobId);
  const onSubmit = async (attireData: FormData) => {
    await editJobAttire({
      variables: {
        input: sanitizeJobInstructions(attireData),
        jobId,
      },
    });
    modalContext?.setIsModalOpen(false);
  };
  const errors = methods.errors;
  const handleInstructionsInputChange = (instructions: InstructionsForm) => {
    const newInstructions = methods.getValues().jobInstructions;
    for (const key in instructions) {
      newInstructions[key] = {
        ...newInstructions[key],
        ...instructions[key],
      };
    }
    methods.setValue('jobInstructions', newInstructions);
  };

  return (
    <Modal title="Edit attires">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {attires?.map((attire, index) => {
          methods.register(`jobInstructions.${attire.position.id}.id`);
          return (
            <TenderInstructionsInput
              index={index}
              key={attire.position.id}
              data={parseAttire(attire)}
              name={attire.position.id}
              label={attire.position.name}
              errors={
                errors.jobInstructions &&
                errors.jobInstructions[attire.position.id]
              }
              onChange={handleInstructionsInputChange}
              smallTitle
            />
          );
        })}
        <Footer disabled={loading} />
      </form>
    </Modal>
  );
};

export default EditAttireModal;
