import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import { isPast } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { MyClientJob_myClientJob as Job } from 'src/graphql/queries/__generated__/MyClientJob';
import {
  ClearBackground,
  Separator,
  WidthLimiter,
} from 'src/components/BookingStyles/BookingStyles.styled';
import { DEFAULT_TIMEZONE } from 'src/utils/constants';
import {
  BookingName,
  BookingNameContainer,
  DetailTab,
  DetailTabBar,
} from './BookingDetail.styled';

type Props = {
  booking: Job;
};

const Container = styled.div`
  .mdc-tab-bar {
    padding: 0;
  }
`;

const Paragraph = styled.p`
  color: var(--color-ink-notasdark);
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
`;

const BookingDetail: React.FC<Props> = ({ booking, children }) => {
  const isStaffAvailable = isPast(new Date(booking.endDateTime));
  const timeZone = booking.venue?.address.timezone || DEFAULT_TIMEZONE;

  const formatTimeStamp = (timeStamp: string): string => {
    return format(
      utcToZonedTime(new Date(timeStamp), timeZone),
      'MMMM d, Y h:mm aaaa',
    );
  };

  return (
    <Container>
      <ClearBackground>
        <WidthLimiter>
          <BookingNameContainer>
            <BookingName>{booking.name}</BookingName>
            <Paragraph>
              {`${formatTimeStamp(booking.startDateTime)} - ${formatTimeStamp(
                booking.endDateTime,
              )}`}
            </Paragraph>
          </BookingNameContainer>
          <DetailTabBar>
            <DetailTab exact to={`/bookings/${booking.id}`}>
              Details
            </DetailTab>
            {isStaffAvailable && (
              <DetailTab to={`/bookings/${booking.id}/staff`}>
                Staffed Tenders ({booking.staffedTenders || 0})
              </DetailTab>
            )}
          </DetailTabBar>
        </WidthLimiter>
        <Separator />
      </ClearBackground>
      <WidthLimiter>{children}</WidthLimiter>
    </Container>
  );
};

export default BookingDetail;
