import styled from 'styled-components';

export default styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  border: 2px solid
    ${({ selected }) =>
      selected ? 'var(--color-primary)' : 'var(--color-support-lines)'};
  border-radius: 6px;
  cursor: pointer;
`;
