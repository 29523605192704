import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';

import { EVENTS, track } from 'src/analytics';
import {
  DeleteTimesheetFile,
  DeleteTimesheetFileVariables,
} from './__generated__/DeleteTimesheetFile';

const DELETE_TIMESHEET_FILE = gql`
  mutation DeleteTimesheetFile($timesheetFileId: ID!) {
    deleteJobTimesheetFile(timesheetFileId: $timesheetFileId) {
      id
      timesheetFiles {
        id
        name
      }
    }
  }
`;

type MutationOptions = MutationHookOptions<
  DeleteTimesheetFile,
  DeleteTimesheetFileVariables
>;
export const useDeleteTimesheetFile = (
  jobId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<DeleteTimesheetFile, DeleteTimesheetFileVariables>(
    DELETE_TIMESHEET_FILE,
    {
      ...options,
      onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
        (data) => {
          track(EVENTS.DELETED_JOB_TIMESHEET_FILE, {
            'Job ID': jobId,
          });
          onCompleted?.(data);
        },
        [onCompleted, jobId],
      ),
    },
  );
};
