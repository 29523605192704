import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import MaterialIcon from '@material/react-material-icon';
import {
  FieldError,
  FormContext,
  NestDataObject,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { addHours, isWithinInterval, startOfWeek } from 'date-fns/esm';
import { GET_TIMEZONE } from 'src/graphql/queries/getTimezone';
import { GetTimezone } from 'src/graphql/queries/__generated__/GetTimezone';
import { ErrorMessage, InfoMessage } from 'src/components/StatusMessages';
import objectIsEmpty from 'src/utils/objectIsEmpty';
import {
  DEFAULT_CONTACT_EMAIL,
  MAX_TENDERS_ALLOWED,
} from 'src/utils/constants';
import { transformStartAndEndTimes } from 'src/utils/dates';
import StaffingCard, {
  Position,
  StaffingArrayData,
  StaffingData,
} from 'src/components/StaffingCard';
import { EVENTS, track } from 'src/analytics';
import { AddButton, Centered } from './Staffing.styled';

const USA_ENTER_DST_MAX_DATE = 'March 14';
const USA_LEAVE_DST_MAX_DATE = 'November 7';
const USA_SWITCH_DST_HOUR = 2;

interface Props {
  data: StaffingArrayData;
  onSubmit: (data: StaffingArrayData) => void;
  formButtons: JSX.Element;
  isClient?: boolean;
}

type FormError = {
  quantity?: FieldError;
};

const shiftValidationSchema = Yup.object().shape({
  position: Yup.object().required(),
  unpaidBreakMinutes: Yup.number(),
  quantity: Yup.number().max(MAX_TENDERS_ALLOWED).required(),
  date: Yup.date().required(),
  endDateTime: Yup.date().required(),
  startDateTime: Yup.date().required(),
  tipType: Yup.string().when('position', (position: Position) =>
    position?.tipTypes?.length ? Yup.string().required() : Yup.string(),
  ),
  tipAmount: Yup.number().when('tipType', (tipType: string) =>
    tipType === 'INCLUDE_TIP' ? Yup.number().required() : Yup.number(),
  ),
});

const validationSchema = Yup.object().shape({
  shifts: Yup.array().of(shiftValidationSchema),
});

const MaxQuantityExceededError = () => (
  <>
    Maximum quantity exceeded. If you require more than {MAX_TENDERS_ALLOWED}{' '}
    tenders please contact{' '}
    <a href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>{DEFAULT_CONTACT_EMAIL}</a>
  </>
);

const DaylightSavingsInfo = () => (
  <>
    Shift hours are close to daylight savings. Please double check the times
    reflect what you are looking for at the final step and the confirmation
    email.
  </>
);

const BookingFormStaffing: React.FC<Props> = ({
  data,
  onSubmit,
  formButtons,
  isClient,
}) => {
  const location = {
    lat: data.venue?.address?.lat,
    lng: data.venue?.address?.lng,
  };
  const { data: timeZoneData } = useQuery<GetTimezone>(GET_TIMEZONE, {
    fetchPolicy: 'network-only',
    variables: {
      lat: location.lat,
      lng: location.lng,
    },
  });
  const timeZone = timeZoneData?.getTimezone?.timeZone;
  const methods = useForm<StaffingArrayData>({
    defaultValues: data,
    validationSchema,
  });
  const { fields, remove, append } = useFieldArray<StaffingData>({
    control: methods.control,
    name: 'shifts',
  });
  if (fields.length === 0) {
    append({});
  }
  const handleSubmit = (staffingData: StaffingArrayData) => {
    const formattedData = staffingData.shifts?.map((staffing) => {
      const { date, startDateTime, endDateTime, ...rest } = staffing;
      return {
        ...rest,
        ...transformStartAndEndTimes({ date, endDateTime, startDateTime }),
      };
    });
    onSubmit({ shifts: formattedData });
    track(EVENTS.NEW_BOOKING_SHIFTS_SUBMITTED);
  };

  const getErrorMessage = (
    errors: NestDataObject<StaffingArrayData>,
  ): JSX.Element | null => {
    let errorMessage = null;
    if (errors.shifts) {
      const shiftErrors = errors.shifts as unknown as Array<FormError>;
      shiftErrors.forEach((error) => {
        if (error.quantity?.type === 'max') {
          errorMessage = <MaxQuantityExceededError />;
          return;
        }
      });
    }
    return errorMessage;
  };

  const watchedShifts = methods.watch('shifts');
  const isJobCloseToDaylightSavings = (shifts: StaffingData[] | undefined) => {
    return shifts?.some((shift) => {
      if (timeZone && shift.startDateTime && shift.endDateTime && shift.date) {
        const { startDateTime, endDateTime } = transformStartAndEndTimes(shift);
        if (!startDateTime || !endDateTime) {
          return false;
        }

        const jobYearsEnterDST = addHours(
          startOfWeek(
            new Date(
              `${USA_ENTER_DST_MAX_DATE}, ${shift.date.getFullYear()} 00:00:00`,
            ),
          ),
          USA_SWITCH_DST_HOUR,
        );
        const jobYearsLeaveDST = addHours(
          startOfWeek(
            new Date(
              `${USA_LEAVE_DST_MAX_DATE}, ${shift.date.getFullYear()} 00:00:00`,
            ),
          ),
          USA_SWITCH_DST_HOUR,
        );

        const shiftInterval = {
          start: startDateTime,
          end: endDateTime,
        };

        return (
          isWithinInterval(jobYearsEnterDST, shiftInterval) ||
          isWithinInterval(jobYearsLeaveDST, shiftInterval)
        );
      }
      return false;
    });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        {fields.map((staffingData, index: number) => (
          <StaffingCard
            key={staffingData.id}
            index={index}
            isClient={isClient}
            data={staffingData}
            location={location}
            onDelete={fields.length > 1 ? remove : undefined}
            validationSchema={shiftValidationSchema}
          />
        ))}
        <ErrorMessage block visible={!objectIsEmpty(methods.errors)}>
          {getErrorMessage(methods.errors)}
        </ErrorMessage>
        {isJobCloseToDaylightSavings(watchedShifts) && (
          <InfoMessage block>{<DaylightSavingsInfo />}</InfoMessage>
        )}
        <Centered>
          <AddButton
            onClick={methods.handleSubmit(async () => {
              const isValid = await methods.triggerValidation();
              if (isValid) {
                const lastValues = methods.getValues() as unknown as Record<
                  string,
                  Date
                >;
                const index = fields.length - 1;
                append({
                  startDateTime: lastValues[`shifts[${index}].startDateTime`],
                  endDateTime: lastValues[`shifts[${index}].endDateTime`],
                  date: lastValues[`shifts[${index}].date`],
                });
              }
            })}
            data-cy="addPositionButton"
          >
            <MaterialIcon icon="add" />
            Add Position
          </AddButton>
        </Centered>
        {formButtons}
      </form>
    </FormContext>
  );
};

export default BookingFormStaffing;
