import * as FullStory from '@fullstory/browser';
import { EVENTS, identify, mixpanel, track } from 'src/analytics';

const tokenKey = 'authToken';
let cachedToken = '';

export const getToken = (): string => {
  if (cachedToken === '') {
    cachedToken = localStorage.getItem(tokenKey) || '';
    identify(cachedToken);
  }

  return cachedToken;
};

export const setToken = (token: string): void => {
  localStorage.setItem(tokenKey, token);
  cachedToken = token;
  identify(cachedToken);
};

export const removeToken = (): void => {
  localStorage.removeItem(tokenKey);

  cachedToken = '';
  track(EVENTS.SIGNED_OUT);
  mixpanel?.reset();

  try {
    FullStory.anonymize();
  } catch (e) {
    console.error(e);
  }
};
