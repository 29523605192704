import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ChangeTenderStatusAsClientInput } from 'src/__generated__/globalTypes';

export const FAVORITE_TENDER_AS_CLIENT = gql`
  mutation FavoriteTenderAsClient($input: ChangeTenderStatusAsClientInput!) {
    favoriteTenderAsClient(input: $input) {
      tender {
        id
      }
    }
  }
`;

export const Factory = (tenderId: string, staffingId: string) => {
  return useMutation<{
    input: ChangeTenderStatusAsClientInput;
  }>(FAVORITE_TENDER_AS_CLIENT, {
    variables: {
      input: {
        tenderId,
        staffingId,
      },
    },
  });
};
