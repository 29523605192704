import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import differenceInHours from 'date-fns/differenceInHours';
import { Button } from '@material/react-button';
import Card from 'src/components/Card';
import { EditDescriptionModal } from 'src/components/EditBookingModals';
import { ModalContext } from 'src/components/Modal';
import { MyClientJob_myClientJob as Job } from 'src/graphql/queries/__generated__/MyClientJob';
import editBtn from 'src/images/icons/editGreen.svg';

export const DescriptionText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.inkDark};
  margin-top: 0;
`;

const ButtonWithIcon = styled(Button)`
  text-transform: none;
  > span {
    align-items: center;
    display: flex;
  }
`;

const Img = styled.img`
  margin-right: 4px;
  margin-top: 1px;
`;

interface Props {
  booking: Job;
}

const DescriptionCard: FC<Props> = ({ booking }) => {
  const modalContext = useContext(ModalContext);
  const isEventSoon =
    differenceInHours(new Date(booking.startDateTime), new Date()) < 24;
  const openModal = () => {
    modalContext?.setModalContent(
      <EditDescriptionModal defaultValues={booking} />,
    );
    modalContext?.setIsModalOpen(true);
  };
  return (
    <Card
      title="About the Booking"
      actionComponent={
        !isEventSoon && (
          <ButtonWithIcon onClick={openModal}>
            <Img src={editBtn} alt="edit description" /> Edit Description
          </ButtonWithIcon>
        )
      }
    >
      <DescriptionText>
        {booking.description || 'No description for this Booking.'}
      </DescriptionText>
    </Card>
  );
};

export default DescriptionCard;
