import gql from 'graphql-tag';
import SHIFT_FOR_JOB_DETAILS from '../fragments/ShiftForJobDetails';
import INSTRUCTIONS_FOR_JOB_DETAILS from '../fragments/InstructionsForJobDetails';

export const GET_MY_CLIENT_JOB = gql`
  query MyClientJob($id: ID!) {
    myClientJob(id: $id) {
      id
      name
      description
      startDateTime
      endDateTime
      mealProvided
      useBackDoor
      staffedTenders
      tendersAmount
      total
      totalAdjustment
      timesheetFiles {
        id
        name
      }
      locationContact {
        id
        name
        phoneNumber
      }
      venue {
        id
        name
        parking {
          value
          label
        }
        parkingInstructions
        otherInstructions
        address {
          streetName
          streetNumber
          interiorNumber
          neighborhood
          city
          state
          country
          zip
          lat
          lng
          timezone
        }
      }
      instructions {
        ...InstructionsForJobDetails
      }
      shifts {
        ...ShiftForJobDetails
      }
    }
  }
  ${INSTRUCTIONS_FOR_JOB_DETAILS}
  ${SHIFT_FOR_JOB_DETAILS}
`;
