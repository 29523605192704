import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import BookingDetail from 'src/components/BookingDetail';
import { MyClientJob } from 'src/graphql/queries/__generated__/MyClientJob';
import { GET_MY_CLIENT_JOB } from 'src/graphql/queries/myClientJob';
import BookingStaffTab from 'src/components/BookingDetail/BookingStaffTab';

const ClientBookings: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { data } = useQuery<MyClientJob>(GET_MY_CLIENT_JOB, {
    fetchPolicy: 'network-only',
    variables: { id: bookingId },
  });

  return (
    <>
      {data && data.myClientJob && (
        <BookingDetail booking={data.myClientJob}>
          <BookingStaffTab booking={data.myClientJob} />
        </BookingDetail>
      )}
    </>
  );
};

export default ClientBookings;
