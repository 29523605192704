import React from 'react';
import SignUpForm from 'src/components/SignUpForm';
import HomeTemplate from 'src/components/HomeTemplate';

const SignupView: React.FC = () => (
  <HomeTemplate signAction="signin">
    <SignUpForm />
  </HomeTemplate>
);

export default SignupView;
