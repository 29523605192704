import React, { FC } from 'react';
import styled from 'styled-components';
import {
  default as TextFieldMaterial,
  Props as TextFieldProps,
} from '@material/react-text-field';

type Props = {
  inline?: boolean;
  label?: string;
} & Pick<
  TextFieldProps,
  | 'children'
  | 'textarea'
  | 'helperText'
  | 'leadingIcon'
  | 'trailingIcon'
  | 'dense'
  | 'onTrailingIconSelect'
>;

const Container = styled.div<{ inline?: boolean }>`
  .mdc-text-field {
    width: ${({ inline }) => (inline ? 'auto' : '100%')};
    background-color: #fafbfc;
    margin-bottom: 16px;
  }

  /* remove floating label */
  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    display: none;
  }
`;

const TextField: FC<Props> = ({ inline, ...props }) => (
  <Container inline={inline}>
    <TextFieldMaterial outlined {...props} />
  </Container>
);

export default TextField;
