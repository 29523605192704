import React, { ChangeEvent, useEffect, useState } from 'react';
import * as moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@material/react-text-field';
import DatePicker from 'src/components/DatePicker';
import Label from 'src/components/Label';
import PositionsSelect from 'src/components/PositionsSelect';
import Select from 'src/components/Select';
import TextField from 'src/components/TextField/index';
import TimePicker from 'src/components/TimePicker';
import { BookingFormContent } from 'src/components/FormComponents';
import { formatMoney } from 'src/utils/money';
import { calculateTotalRate } from 'src/utils/position';
import { useGetClientProfileQuery } from 'src/graphql/queries/GetClientProfile';
import {
  Position,
  StaffingArrayData,
  StaffingProps,
  TipType,
} from './StaffingCard.type';
import {
  Column,
  ColumnWithPadding,
  DayAfterText,
  DeleteContainer,
  DeleteIcon,
  GroupInput,
  Row,
  TipTitle,
  Title,
  TotalNumber,
  TotalRow,
  TotalText,
} from './StaffingCard.styled';

const BREAK_OPTIONS = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '45 minutes',
    value: 45,
  },
  {
    label: '1 hour',
    value: 60,
  },
];
const TIP_AMOUNT_OPTIONS = [
  { label: '$2/hour', value: 200 },
  { label: '$4/hour', value: 400 },
  { label: '$6/hour', value: 600 },
  { label: '$8/hour', value: 800 },
  { label: '$10/hour', value: 1000 },
  { label: '$15/hour', value: 1500 },
];

export const TIP_TYPES = {
  ON_SITE: 'Tip on-site',
  INCLUDE_TIP: 'Include tip',
  ALLOW_JAR: 'Allow tip jar',
  NO_TIP: 'No tip',
};

const StaffingCard: React.FC<StaffingProps> = ({
  data,
  onDelete,
  index,
  location,
  isClient,
  validationSchema,
  onPositionChange,
  noMargin = false,
  isPositionDisabled = false,
  displayTitle = true,
}) => {
  const { data: profileData } = useGetClientProfileQuery({
    skip: !isClient,
  });
  const [formValues, setFormValues] = useState({
    position: data?.position || undefined,
    unpaidBreakMinutes: data?.unpaidBreakMinutes || 0,
    quantity: data?.quantity || 1,
    date: data?.date || null,
    endDateTime: data?.endDateTime || null,
    startDateTime: data?.startDateTime || null,
    tipType: data?.tipType,
    tipAmount: data?.tipAmount,
    shiftTotal: 0,
  });
  const [totalRate, setTotalRate] = useState<number>();
  const [deleted, setDeleted] = useState<boolean>(false);
  const {
    errors: formErrors,
    register,
    setValue,
  } = useFormContext<StaffingArrayData>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errors = formErrors.shifts && formErrors.shifts[index];
  const {
    position,
    quantity,
    unpaidBreakMinutes,
    startDateTime,
    endDateTime,
    tipType,
    tipAmount,
  } = formValues;

  const handleChange =
    (type: string) =>
    (newValue: Position | string | number | null | undefined | Date) => {
      let tipTypes = {};
      if (type === 'position' || type === 'tipType') {
        if (type === 'position' && onPositionChange) {
          onPositionChange(newValue as Position);
        }
        tipTypes = {
          tipType: undefined,
          tipAmount: undefined,
        };
        setValue(`shifts[${index}].tipType`, undefined);
      }
      const newFormValues = {
        ...formValues,
        ...tipTypes,
        [type]: newValue,
      };

      newFormValues.shiftTotal = calculateTotalRate(
        newFormValues.position?.rate,
        newFormValues.startDateTime,
        newFormValues.endDateTime,
        newFormValues.unpaidBreakMinutes,
        newFormValues.quantity,
        newFormValues.tipAmount,
      );

      setFormValues(newFormValues);
      return newValue;
    };
  const getTipTypes = (tipTypes: TipType[]) => {
    const regularTipTypes = tipTypes.map((type) => ({
      value: type,
      label: TIP_TYPES[type],
    }));
    if (!profileData?.profile?.allowNoTipOption) {
      return regularTipTypes;
    }
    return [
      ...regularTipTypes,
      {
        value: 'NO_TIP',
        label: TIP_TYPES.NO_TIP,
      },
    ];
  };

  useEffect(() => {
    const getTotalRate = async () => {
      try {
        await validationSchema.validate(formValues);

        setTotalRate(
          calculateTotalRate(
            formValues.position?.rate,
            formValues.startDateTime,
            formValues.endDateTime,
            formValues.unpaidBreakMinutes,
            formValues.quantity,
            formValues.tipAmount,
          ),
        );
      } catch (e) {
        setTotalRate(undefined);
      }
    };

    getTotalRate();
  }, [formValues, validationSchema]);

  let isNextDay = false;
  if (startDateTime && endDateTime) {
    isNextDay = startDateTime >= endDateTime;
  }
  register(`shifts[${index}].shiftTotal`);

  return (
    <DeleteContainer
      deleted={deleted}
      onTransitionEnd={() => deleted && onDelete && onDelete(index)}
    >
      <BookingFormContent
        noMargin={noMargin}
        padding={noMargin ? '0' : '16px 40px'}
      >
        {displayTitle && (
          <Title noMargin={noMargin} isDark={!!position?.name}>
            {position?.name || 'Select a position'}
          </Title>
        )}
        {onDelete && (
          <DeleteIcon icon="delete" onClick={() => setDeleted(true)} />
        )}
        <Row noMargin={noMargin}>
          <GroupInput flex="0 1 38%">
            <Label required>Position</Label>
            <Controller
              name={`shifts[${index}].position`}
              onChange={(positions) => handleChange('position')(positions[0])}
              as={
                <PositionsSelect
                  className={`position-select-${index}`}
                  isClient={isClient}
                  location={location}
                  placeholder="Select a position"
                  defaultValue={position?.id}
                  isValid={!errors?.position}
                  isPositionDisabled={isPositionDisabled}
                />
              }
            />
          </GroupInput>
          <GroupInput flex="0 1 13%">
            <Label required>Quantity</Label>
            <TextField>
              <Controller
                name={`shifts[${index}].quantity`}
                onChange={(event: ChangeEvent<HTMLInputElement>[]) =>
                  handleChange('quantity')(
                    parseInt(event[0].target.value, 10) || '',
                  )
                }
                defaultValue={quantity.toString()}
                as={
                  <Input
                    data-cy={`quantity-${index}`}
                    type="number"
                    min="1"
                    isValid={!errors?.quantity}
                  />
                }
              />
            </TextField>
          </GroupInput>
          <GroupInput>
            {!!position?.tipTypes?.length && (
              <>
                <Label required>Tip Option</Label>
                <Controller
                  name={`shifts[${index}].tipType`}
                  valueName="select"
                  onChange={(i) =>
                    position?.tipTypes && handleChange('tipType')(i[0])
                  }
                  as={
                    <Select
                      data-cy={`tipType-${index}`}
                      isValid={!errors?.tipType}
                      options={getTipTypes(position.tipTypes)}
                      defaultValue={tipType}
                      value={tipType}
                    />
                  }
                />
              </>
            )}
          </GroupInput>
          <GroupInput flex="">
            {tipType === 'INCLUDE_TIP' && (
              <>
                <Label required>Tip Amount</Label>
                <Controller
                  name={`shifts[${index}].tipAmount`}
                  valueName="select"
                  onChange={(i) => handleChange('tipAmount')(i[0])}
                  as={
                    <Select
                      data-cy={`tipAmount-${index}`}
                      isValid={!errors?.tipAmount}
                      options={TIP_AMOUNT_OPTIONS}
                      defaultValue={tipAmount?.toString()}
                      value={tipAmount?.toString()}
                    />
                  }
                />
              </>
            )}
          </GroupInput>
        </Row>
        <Row noMargin={noMargin}>
          <GroupInput>
            <Label required>Date</Label>
            <Controller
              name={`shifts[${index}].date`}
              valueName="dateValue"
              onChange={(value) => handleChange('date')(value[0])}
              onChangeName="onDateChange"
              defaultValue={data?.date && data.date}
              as={
                <DatePicker
                  data-cy={`date-${index}`}
                  isValid={!errors?.date}
                  defaultValue={data?.date && moment.default(data.date)}
                  id="date-picker"
                />
              }
            />
          </GroupInput>
          <GroupInput>
            <Label required>Start time</Label>
            <Controller
              name={`shifts[${index}].startDateTime`}
              onChange={(startDateTimes) =>
                handleChange('startDateTime')(
                  startDateTimes[0] && startDateTimes[0].toDate(),
                )
              }
              valueName="none"
              defaultValue={
                data?.startDateTime ? data.startDateTime : undefined
              }
              as={
                <TimePicker
                  data-cy={`startTimePicker-${index}`}
                  isValid={errors?.startDateTime?.type !== 'required'}
                  placeholder="Start time"
                  defaultValue={
                    data?.startDateTime
                      ? moment.default(data.startDateTime)
                      : undefined
                  }
                  id="start"
                />
              }
            />
          </GroupInput>
          <GroupInput>
            <Label required>End time</Label>
            <Controller
              name={`shifts[${index}].endDateTime`}
              valueName="none"
              onChange={(endDateTimes) =>
                handleChange('endDateTime')(
                  endDateTimes[0] && endDateTimes[0].toDate(),
                )
              }
              defaultValue={data?.endDateTime ? data.endDateTime : undefined}
              as={
                <TimePicker
                  data-cy={`endTimePicker-${index}`}
                  isValid={errors?.endDateTime?.type !== 'required'}
                  placeholder="End time"
                  id="end"
                  defaultValue={
                    data?.endDateTime
                      ? moment.default(data.endDateTime)
                      : undefined
                  }
                />
              }
            />
            {isNextDay && <DayAfterText>Day After</DayAfterText>}
          </GroupInput>
          <GroupInput>
            <Label required>Unpaid Break</Label>
            <Controller
              name={`shifts[${index}].unpaidBreakMinutes`}
              valueName="none"
              onChange={(i) => handleChange('unpaidBreakMinutes')(i[0])}
              defaultValue={unpaidBreakMinutes}
              as={
                <Select
                  isValid={!errors?.breakOptions}
                  options={BREAK_OPTIONS}
                  defaultValue={unpaidBreakMinutes.toString()}
                  value={unpaidBreakMinutes.toString()}
                />
              }
            />
          </GroupInput>
        </Row>
        <TotalRow>
          <Column>
            {tipType === 'ON_SITE' && <TipTitle>Suggested Tip:</TipTitle>}
            <TotalText>Total:</TotalText>
          </Column>
          <ColumnWithPadding>
            {tipType === 'ON_SITE' && (
              <TotalNumber>
                {totalRate ? formatMoney(totalRate * 0.2) : '---'}
              </TotalNumber>
            )}
            <TotalNumber>
              {totalRate ? formatMoney(totalRate) : '---'}
            </TotalNumber>
          </ColumnWithPadding>
        </TotalRow>
      </BookingFormContent>
    </DeleteContainer>
  );
};

export default StaffingCard;
