import React, { HTMLProps, ReactElement, useState } from 'react';
import { Input } from '@material/react-text-field';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import TextField from 'src/components/TextField';

interface Props {
  label?: string;
  leadingIcon?: ReactElement<HTMLProps<HTMLOrSVGElement>>;
  name: string;
  onChange?: (event: React.FormEvent<HTMLDivElement>) => void;
  value?: string;
  isValid?: boolean;
  onBlur?: (event: React.FormEvent<HTMLDivElement>) => void;
  'data-cy'?: string;
}

const PasswordTextField = styled(TextField)`
  .mdc-text-field__icon {
    cursor: pointer;
    pointer-events: all;
  }
`;

const PasswordInput: React.FC<Props> = (props) => {
  const [passwordType, setPasswordType] = useState('password');
  const passwordIcon =
    passwordType === 'password' ? 'visibility' : 'visibility_off';
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };
  const { isValid = true } = props;
  return (
    <PasswordTextField
      label={props.label || 'Password'}
      trailingIcon={
        <MaterialIcon
          data-testid="password-icon"
          icon={passwordIcon}
          tabIndex={-1}
        />
      }
      leadingIcon={props.leadingIcon}
      onTrailingIconSelect={togglePassword}
    >
      <Input
        data-testid="password-input"
        name={props.name}
        type={passwordType}
        value={props.value}
        onChange={props.onChange}
        id={props.label || 'Password'}
        isValid={isValid}
        onBlur={props.onBlur}
        data-cy={props['data-cy']}
      />
    </PasswordTextField>
  );
};

export default PasswordInput;
