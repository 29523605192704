import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ChangeTenderStatusAsClientInput } from 'src/__generated__/globalTypes';

export const MARK_TENDER_AS_LESS_PREFERRED = gql`
  mutation MarkTenderAsLessPreferredAsClient(
    $input: ChangeTenderStatusAsClientInput!
  ) {
    markTenderAsLessPreferredAsClient(input: $input) {
      status
    }
  }
`;

export const Factory = (tenderId: string, staffingId: string) => {
  return useMutation<{
    input: ChangeTenderStatusAsClientInput;
  }>(MARK_TENDER_AS_LESS_PREFERRED, {
    variables: {
      input: {
        tenderId,
        staffingId,
      },
    },
  });
};
