import gql from 'graphql-tag';
import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import { MyClientJob } from 'src/graphql/queries/__generated__/MyClientJob';
import { GET_MY_CLIENT_JOB } from 'src/graphql/queries/myClientJob';
import SHIFT_FOR_JOB_DETAILS from 'src/graphql/fragments/ShiftForJobDetails';
import INSTRUCTIONS_FOR_JOB_DETAILS from 'src/graphql/fragments/InstructionsForJobDetails';
import {
  AddShiftToClientJob,
  AddShiftToClientJobVariables,
} from './__generated__/AddShiftToClientJob';

const ADD_SHIFT_TO_CLIENT_JOB = gql`
  mutation AddShiftToClientJob($jobId: ID!, $input: AddShiftToClientJobInput!) {
    addShiftToClientJob(jobId: $jobId, input: $input) {
      id
      startDateTime
      endDateTime
      total
      instructions {
        ...InstructionsForJobDetails
      }
      shifts {
        ...ShiftForJobDetails
      }
    }
  }
  ${INSTRUCTIONS_FOR_JOB_DETAILS}
  ${SHIFT_FOR_JOB_DETAILS}
`;

type MutationOptions = MutationHookOptions<
  AddShiftToClientJob,
  AddShiftToClientJobVariables
>;
export const useAddShiftToClientJobMutation = (
  jobId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<AddShiftToClientJob, AddShiftToClientJobVariables>(
    ADD_SHIFT_TO_CLIENT_JOB,
    {
      ...options,
      onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
        (data) => {
          track(EVENTS.ADDED_SHIFT_TO_BOOKING, { 'Job ID': jobId });
          onCompleted?.(data);
        },
        [jobId, onCompleted],
      ),
      update: useCallback<NonNullable<MutationOptions['update']>>(
        (cache, { data }) => {
          const oldData = cache.readQuery<MyClientJob>({
            query: GET_MY_CLIENT_JOB,
            variables: {
              id: jobId,
            },
          });

          if (!data || !oldData?.myClientJob) {
            return;
          }

          cache.writeQuery<MyClientJob>({
            query: GET_MY_CLIENT_JOB,
            variables: { id: jobId },
            data: {
              ...oldData,
              myClientJob: {
                ...oldData.myClientJob,
                shifts: data.addShiftToClientJob.shifts,
                instructions: data.addShiftToClientJob.instructions,
                startDateTime: data.addShiftToClientJob.startDateTime,
                endDateTime: data.addShiftToClientJob.endDateTime,
                total: data.addShiftToClientJob.total,
              },
            },
          });
        },
        [jobId],
      ),
    },
  );
};

export default ADD_SHIFT_TO_CLIENT_JOB;
