import React, { useState } from 'react';
import styled from 'styled-components';
import { Title } from 'src/components/FormComponents';
import { DEFAULT_CONTACT_EMAIL } from 'src/utils/constants';
import Text from 'src/components/Text';
import { VERIFICATION_EMAIL_ENDPOINT } from 'src/routes';
import { Snackbar } from 'src/components/BookingStyles/BookingStyles.styled';
import useQueryParams from 'src/hooks/QueryParams';
import { ErrorMessage } from 'src/components/StatusMessages';

const VerificationSnackbar = styled(Snackbar)`
  top: 10px;
`;

const TextResendCopy = styled(Text)`
  line-height: 24px;
`;

const TextResend = styled(Text)`
  margin-top: 30px;
`;

const Link = styled.a`
  color: var(--color-brand-tend);
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
`;

const Footer = styled.div`
  border-top: 1px solid var(--color-support-lines);
  margin-top: 100px;
  padding-top: 24px;
  color: var(--color-ink-notasdark);
`;

type Params = {
  email: string;
};

const UnverifiedUserSection: React.FC<Params> = ({ email }) => {
  const [statusMessage, setStatusMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const hasError = useQueryParams().get('verification_error');

  if (hasError) {
    setStatusMessage(
      'The link you just used is either invalid or has already expired. Please obtain a new verification email.',
    );
  }

  const onSubmit = async () => {
    setStatusMessage(undefined);
    setErrorMessage(undefined);

    const rawResponse = await fetch(VERIFICATION_EMAIL_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (rawResponse.ok) {
      setStatusMessage('Verification email sent!');
    } else {
      setErrorMessage(
        'The server is having issues processing your request. Please try again later',
      );
    }
  };

  return (
    <>
      {statusMessage && (
        <VerificationSnackbar
          timeoutMs={5000}
          message={statusMessage}
          actionText="×"
          onClose={() => {
            setStatusMessage('');
          }}
        />
      )}
      <Title>Please verify your email address</Title>
      <TextResendCopy preset="preset5">
        An email has been sent to <b>{email}</b> with a link to verify your
        account. If you don't see it after a few minutes, please check your spam
        folder.
      </TextResendCopy>
      <TextResend preset="preset5">
        Didn't receive the email?&nbsp;
        <Link onClick={onSubmit}>Send it again</Link>
      </TextResend>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Footer>
        Please reach out to{' '}
        <Link href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>
          {DEFAULT_CONTACT_EMAIL}
        </Link>{' '}
        for any questions.
      </Footer>
    </>
  );
};

export default UnverifiedUserSection;
