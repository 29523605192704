import React from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';
import RCTimePicker from 'rc-time-picker';
import MaterialIcon from '@material/react-material-icon';

const StyledTimePicker = styled(RCTimePicker)<{ isValid?: boolean }>`
  display: block;
  > input {
    height: 56px;
    padding-left: 15px;
    border-color: ${({ isValid }) =>
      isValid
        ? 'rgba(0, 0, 0, 0.4)'
        : 'var(--color-status-destructive)!important'};
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    box-sizing: border-box;
    background: var(--color-background-app);
    &:focus-within {
      border-color: var(--color-ink-primary);
      box-shadow: ${({ isValid }) =>
        isValid
          ? 'inset 0 0 0 1px var(--color-primary)'
          : 'inset 0 0 0 1px var(--color-status-destructive)'}
      }
    }
    :hover {
      border-color: var(--color-ink-dark);
    }
  }
`;

const ClearIcon = styled(MaterialIcon)`
  position: absolute;
  left: 85%;
  top: 15px;
`;

type TimePickerProps = {
  prefixCls?: string;
  clearText?: string;
  disabled?: boolean;
  allowEmpty?: boolean;
  open?: boolean;
  defaultValue?: Moment;
  defaultOpenValue?: Moment;
  value?: Moment;
  placeholder?: string;
  className?: string;
  id?: string;
  popupClassName?: string;
  showHour?: boolean;
  showMinute?: boolean;
  showSecond?: boolean;
  format?: string;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
  use12Hours?: boolean;
  hideDisabledOptions?: boolean;
  onChange?: (newValue: Moment) => void;
  placement?: string;
  transitionName?: string;
  name?: string;
  onOpen?: (newState: { open: true }) => void;
  onClose?: (newState: { open: false }) => void;
  hourStep?: number;
  minuteStep?: number;
  secondStep?: number;
  focusOnOpen?: boolean;
  inputReadOnly?: boolean;
  inputIcon?: React.ReactNode;
  clearIcon?: React.ReactNode;
  isValid?: boolean;
  'data-cy'?: string;
};

const TimePicker: React.FC<TimePickerProps> = ({ isValid, ...props }) => {
  return (
    <div data-cy={props['data-cy']}>
      <StyledTimePicker
        isValid={isValid}
        showSecond={false}
        format="h:mm a"
        use12Hours
        inputReadOnly
        minuteStep={15}
        clearIcon={<ClearIcon icon="clear" />}
        {...props}
      />
    </div>
  );
};

export default TimePicker;
