import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';
import { MD_BREAK_POINT } from 'src/utils/constants';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
`;

export const LeftContainer = styled.div`
  box-sizing: border-box;
  width: 404px;
  padding: 78px 40px;
  background-color: #e7eeed;
  @media (max-width: ${MD_BREAK_POINT}) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  background-color: var(--color-ink-clear);
  justify-content: space-around;
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 820px;
  padding: 124px 76px 60px;
  @media (max-width: ${MD_BREAK_POINT}) {
    padding: 100px 30px 30px;
  }
`;

export const AppLogo = styled.img`
  width: 121px;
  margin-bottom: 70px;
`;

export const Slogan = styled.h2`
  margin: 0 0 12px;
  color: #01211b;
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
`;

export const Text = styled.p`
  margin: 0;
  color: var(--color-ink-notasdark);
`;

export const SignAction = styled.div`
  position: absolute;
  width: 320px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  padding: 32px;
  font-size: 16px;
`;

export const Link = styled(RRLink)`
  color: blue;
  margin-left: 4px;
  text-decoration: none;
  color: var(--color-brand-tend);
`;
