import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NetworkStatus } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';

import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import { GET_MY_PAST_TENDERS } from 'src/graphql/queries/getMyPastTenders';
import {
  GetMyPastTenders,
  GetMyPastTenders_getMyPastTenders as MyPastTender,
} from 'src/graphql/queries/__generated__/GetMyPastTenders';

import Spinner from 'src/components/Spinner';
import { EmptyState } from 'src/components/TenderListEmptyState';
import TenderCard from 'src/components/TenderCard';
import SearchBar from 'src/components/SearchBar';
import { default as SelectComponent } from 'src/components/Select';

import {
  Separator,
  SpinnerContainer,
  TenderListContainer,
  WidthLimiter,
} from 'src/components/BookingStyles/BookingStyles.styled';

const SELECT_OPTIONS = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: ClientTenderStatus.FAVORITE,
    label: 'Favorites',
  },
  {
    value: ClientTenderStatus.BLOCKED,
    label: 'Blocked',
  },
  {
    value: ClientTenderStatus.LESS_PREFERRED,
    label: 'Less Preferred',
  },
];

const FlexContainer = styled.div`
  display: flex;
`;
const Select = styled(SelectComponent)`
  width: 208px;
  padding: 24px;
  .react-select__control {
    background-color: ${({ theme }) => theme.color.backgroundSurface};
  }
`;

type SelectableTenderStatus = ClientTenderStatus | 'ALL';

const PastTenders: FC = () => {
  const [tenders, setTenders] = useState<MyPastTender[] | null | undefined>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const [selectValue, setSelectValue] = useState<SelectableTenderStatus>('ALL');
  const [status, setStatus] = useState<ClientTenderStatus>();
  const handleSelectOnChange = (value: string) => {
    setSelectValue(value as SelectableTenderStatus);
    if (value !== 'ALL') {
      setStatus(value as ClientTenderStatus);
    } else {
      setStatus(undefined);
    }
  };
  const { data, loading, refetch, networkStatus } = useQuery<GetMyPastTenders>(
    GET_MY_PAST_TENDERS,
    {
      variables: { filter: searchInput, status },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  useEffect(() => {
    setTenders(data?.getMyPastTenders);
  }, [searchInput, data, status]);

  const Content = () => {
    if (loading && networkStatus !== NetworkStatus.refetch) {
      return (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      );
    }
    if (!tenders?.length) {
      return (
        <EmptyState
          title="There are no Tenders added to show just yet."
          subtitle="Tenders that have worked with you in past Jobs will show up here. If you think there is an issue please contact support@hiretend.com"
          search={searchInput}
        />
      );
    }
    return (
      <TenderListContainer>
        {tenders.map((tender) => (
          <TenderCard
            key={tender.id}
            tender={tender}
            onClientTenderStatusChange={() => {
              refetch();
            }}
          />
        ))}
      </TenderListContainer>
    );
  };

  return (
    <WidthLimiter>
      <FlexContainer>
        <SearchBar onChange={setSearchInput} />
        <Select
          options={SELECT_OPTIONS}
          value={selectValue}
          onChange={handleSelectOnChange}
          height={48}
          isSearchable={false}
        />
      </FlexContainer>
      <Separator />
      <Content />
    </WidthLimiter>
  );
};

export default PastTenders;
