import React, { FC, useState } from 'react';
import AddressCard from 'src/components/AddressCard';
import Carousel from 'src/components/Carousel';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { MyMostRecentJobsByVenue_myMostRecentJobsByVenue as myMostRecentJobsType } from './__generated__/MyMostRecentJobsByVenue';
import Label from '../Label';

type PastJobLocationProps = {
  onClick: (data: myMostRecentJobsType) => void;
};

const GET_MY_LAST_JOBS_BY_VENUE = gql`
  query MyMostRecentJobsByVenue {
    myMostRecentJobsByVenue {
      id
      mealProvided
      useBackDoor
      venue {
        id
        name
        otherInstructions
        parkingInstructions
        parking {
          value
          label
        }
        type
        address {
          lat
          lng
          streetNumber
          streetName
          city
          state
          country
          zip
        }
      }
      locationContact {
        id
        name
        phoneNumber
      }
    }
  }
`;

const PastJobLocations: FC<PastJobLocationProps> = ({ onClick }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const handleClick = (
    pastJobLocation: myMostRecentJobsType,
    index: number,
  ) => {
    setSelectedIndex(index);
    onClick(pastJobLocation);
  };
  let pastJobs = [];
  const {
    loading,
    error,
    data: queryData,
  } = useQuery(GET_MY_LAST_JOBS_BY_VENUE, {
    fetchPolicy: 'network-only',
  });
  if (!pastJobs.length && !loading && !error && queryData) {
    pastJobs = queryData.myMostRecentJobsByVenue;
  }
  return (
    <>
      {!!pastJobs.length && <Label>Recent Locations</Label>}
      <Carousel>
        {pastJobs.map(
          (pastJobLocation: myMostRecentJobsType, index: number) => (
            <AddressCard
              key={`${pastJobLocation.id}-${index}`}
              venue={pastJobLocation.venue}
              onClick={() => handleClick(pastJobLocation, index)}
              selected={index === selectedIndex}
            />
          ),
        )}
      </Carousel>
    </>
  );
};

export default PastJobLocations;
