import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ErrorMessage } from 'src/components/StatusMessages';
import { BookingFormContent } from 'src/components/FormComponents';
import TenderInstructionsInput, {
  ErrorsType as TenderInstructionsErrorsType,
  FormData as TenderInstructionsFormData,
  validationSchema as tenderInstructionsValidationSchema,
} from 'src/components/TenderInstructionsInput';
import {
  StaffingArrayData,
  Position as TenderPosition,
} from 'src/components/StaffingCard';
import objectIsEmpty from 'src/utils/objectIsEmpty';
import { EVENTS, track } from 'src/analytics';

type TenderInstructionsMap = { [key: string]: TenderInstructionsFormData };

type FormErrors = {
  jobInstructions?: {
    [key: string]: TenderInstructionsErrorsType;
  };
};

export type FormData = {
  jobInstructions?: TenderInstructionsMap;
};

type InputData = StaffingArrayData & FormData;

interface Props {
  data: InputData;
  formButtons: JSX.Element;
  onSubmit: (data: FormData) => void;
}

const getTenderPositions = (data: InputData): Array<TenderPosition> => {
  const set: Set<TenderPosition> = new Set();
  data.shifts?.forEach((shift) => {
    shift.position && set.add(shift.position);
  });
  return Array.from(set);
};

const BookingFormInstructions: FC<Props> = ({
  data,
  formButtons,
  onSubmit,
}) => {
  const values = !objectIsEmpty(data) ? data : {};

  const tenderPositions = getTenderPositions(data);

  let tenderInstructionsValidations = Yup.object();
  tenderPositions.forEach((tenderPosition) => {
    tenderInstructionsValidations = tenderInstructionsValidations.concat(
      Yup.object().shape({
        [tenderPosition.id]: tenderInstructionsValidationSchema,
      }),
    );
  });
  const validationSchema = Yup.object().shape({
    jobInstructions: tenderInstructionsValidations,
  });

  const {
    errors,
    handleSubmit: RHFHandleSubmit,
    setValue,
    getValues,
    register,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: values,
    validationSchema,
  });

  const handleInstructionsInputChange = (
    instructions: TenderInstructionsMap,
  ) => {
    const previousInstructions = getValues().jobInstructions;
    const newInstructions = { ...previousInstructions, ...instructions };
    setValue('jobInstructions', newInstructions);
  };

  useEffect(() => {
    register({ name: 'jobInstructions' });
  });

  const formErrors = errors as unknown as FormErrors;
  const handleSubmit = (formData: FormData) => {
    onSubmit(formData);
    track(EVENTS.NEW_BOOKING_POSITION_INSTRUCTIONS_SUBMITTED);
  };

  return (
    <form onSubmit={RHFHandleSubmit(handleSubmit)}>
      <BookingFormContent>
        {tenderPositions.map((position, index) => (
          <TenderInstructionsInput
            index={index}
            key={position.id}
            data={values.jobInstructions && values.jobInstructions[position.id]}
            name={position.id}
            label={position.name}
            errors={
              formErrors?.jobInstructions &&
              formErrors?.jobInstructions[position.id]
            }
            onChange={handleInstructionsInputChange}
          />
        ))}
      </BookingFormContent>
      <ErrorMessage block visible={!objectIsEmpty(errors)} />
      {formButtons}
    </form>
  );
};

export default BookingFormInstructions;
