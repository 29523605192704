import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import { MyClientJob } from '../queries/__generated__/MyClientJob';
import { GET_MY_CLIENT_JOB } from '../queries/myClientJob';
import {
  EditJobLocation,
  EditJobLocationVariables,
} from './__generated__/EditJobLocation';

const EDIT_JOB_LOCATION = gql`
  mutation EditJobLocation($id: ID!, $input: EditJobLocationInput!) {
    editJobLocation(jobId: $id, input: $input) {
      id
      mealProvided
      useBackDoor
      locationContact {
        id
        name
        phoneNumber
      }
      venue {
        id
        name
        parkingInstructions
        parking {
          label
          value
        }
        otherInstructions
        type
        address {
          streetName
          neighborhood
          streetNumber
          interiorNumber
          neighborhood
          city
          state
          zip
          country
          lat
          lng
          timezone
        }
      }
    }
  }
`;

type MutationOptions = MutationHookOptions<
  EditJobLocation,
  EditJobLocationVariables
>;

export const useEditJobLocationMutation = (
  jobId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<EditJobLocation, EditJobLocationVariables>(
    EDIT_JOB_LOCATION,
    {
      ...options,
      onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
        (data) => {
          track(EVENTS.UPDATED_BOOKING_LOCATION_DETAILS, { 'Job ID': jobId });
          onCompleted?.(data);
        },
        [jobId, onCompleted],
      ),
      update: useCallback<NonNullable<MutationOptions['update']>>(
        (cache, mutationResult) => {
          const oldQueryData = cache.readQuery<MyClientJob>({
            query: GET_MY_CLIENT_JOB,
            variables: {
              id: jobId,
            },
          });
          if (oldQueryData?.myClientJob && mutationResult.data) {
            const { editJobLocation: newJobData } = mutationResult.data;
            const { myClientJob: oldJobData } = oldQueryData;
            cache.writeQuery({
              query: GET_MY_CLIENT_JOB,
              variables: { id: jobId },
              data: {
                myClientJob: {
                  ...oldJobData,
                  ...newJobData,
                },
              },
            });
          }
        },
        [jobId],
      ),
    },
  );
};

export default EDIT_JOB_LOCATION;
