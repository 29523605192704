import React, { FC, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Input } from '@material/react-text-field';
import { NativeRadioControl, Radio } from '@material/react-radio';
import * as Yup from 'yup';
import styled from 'styled-components';
import { RadioGroup, Separator, Text } from 'src/components/FormComponents';
import Checkbox from 'src/components/Checkbox';
import { ErrorMessage } from 'src/components/StatusMessages';
import SelectableCard from 'src/components/SelectableCard';
import Label from 'src/components/Label';
import TextField from 'src/components/TextField';
import { useQuery } from '@apollo/react-hooks';
import { GET_MOST_RECENT_CUSTOM_ATTIRES } from 'src/graphql/queries/mostRecentCustomAttiresForClient';
import { GetMostRecentCustomAttiresForClient } from 'src/graphql/queries/__generated__/GetMostRecentCustomAttiresForClient';

export type AttireType =
  | 'All Black'
  | 'Black Bistro'
  | 'White Chef Coat'
  | 'custom'
  | undefined;

export type FormData = {
  attire?: AttireType;
  customAttire?: string;
  blackNonSlipShoes?: boolean | null;
  blackApron?: boolean | null;
  barKit?: boolean | null;
  knifeKit?: boolean | null;
  maskRequired?: boolean | null;
};

export type ErrorsType = {
  attire?: FieldError;
  customAttire?: FieldError;
};

interface Props {
  index: number;
  name: string;
  label: string;
  data?: FormData;
  errors?: ErrorsType;
  onChange?: (instructions: { [key: string]: FormData }) => void;
  displayTitle?: boolean;
  smallTitle?: boolean;
}

const initialValues: FormData = {
  blackNonSlipShoes: false,
  blackApron: false,
  barKit: false,
  knifeKit: false,
  maskRequired: false,
};

export const validationSchema = Yup.object().shape({
  attire: Yup.string().required('* Please select an attire option.'),
  customAttire: Yup.string().when(['attire'], {
    is: 'custom',
    then: Yup.string().required(),
  }),
});

const AttireCards = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px 0 20px;
`;

const AttireSelectionTip = styled.p`
  ${({ theme }) => theme.text.preset6P};
  color: ${({ theme }) => theme.color.inkNotasdark};
  margin-top: 0;
`;

const TextWithoutMargin = styled(Text)`
  margin: 0;
`;

// TODO: replace searching in substrings for matching slug string (once available)
const isCook = (label: string) => label.toLowerCase().includes('cook');
const isBartender = (label: string) => label.toLowerCase().includes('bar');

const TenderInstructions: FC<Props> = ({
  name,
  label,
  data,
  errors,
  onChange,
  index,
  displayTitle = true,
  smallTitle = false,
}) => {
  const { data: queryData } = useQuery<GetMostRecentCustomAttiresForClient>(
    GET_MOST_RECENT_CUSTOM_ATTIRES,
    {
      variables: {
        positionId: name,
      },
      fetchPolicy: 'no-cache',
    },
  );
  const pastAttires = queryData?.getMostRecentCustomAttiresForClient?.attires;
  const values = data || initialValues;
  const [attire, setAttire] = useState<AttireType>(values.attire);
  const [customAttire, setCustomAttire] = useState(values.customAttire);
  const [blackNonSlipShoes, setBlackNonSlipShoes] = useState<boolean>(
    !!values.blackNonSlipShoes,
  );
  const [blackApron, setBlackApron] = useState<boolean>(!!values.blackApron);
  const [knifeKit, setKnifeKit] = useState<boolean>(!!values.knifeKit);
  const [barKit, setBarKit] = useState<boolean>(!!values.barKit);
  const [maskRequired, setMaskRequired] = useState<boolean>(
    !!values.maskRequired,
  );

  useEffect(() => {
    if (onChange) {
      onChange({
        [name]: {
          attire,
          customAttire,
          blackNonSlipShoes,
          blackApron,
          knifeKit,
          barKit,
          maskRequired,
        },
      });
    }
  }, [
    attire,
    barKit,
    blackApron,
    blackNonSlipShoes,
    customAttire,
    knifeKit,
    maskRequired,
    name,
    onChange,
  ]);
  const blackBistroLabel = isCook(label) ? 'All Black' : 'Black Bistro';
  const whiteAttireLabel = 'White Chef Coat';

  return (
    <>
      {displayTitle && (
        <Text preset={smallTitle ? 'preset4' : 'preset3'}>{label}</Text>
      )}
      <Label required bold={!smallTitle}>
        Attire
      </Label>
      <AttireSelectionTip>
        Select the required attire the Tender needs to bring for the event. Do
        not include attire that would be given from your side.
      </AttireSelectionTip>
      <RadioGroup>
        {isCook(label) && (
          <Radio label={whiteAttireLabel}>
            <NativeRadioControl
              name={`${name}.attire`}
              value={whiteAttireLabel}
              id={`${name}.attire.white`}
              onChange={() => {
                setCustomAttire(undefined);
                return setAttire(whiteAttireLabel);
              }}
              checked={attire === whiteAttireLabel}
            />
          </Radio>
        )}
        <Radio label={blackBistroLabel}>
          <NativeRadioControl
            data-cy={`black-${index}`}
            name={`${name}.attire`}
            value={blackBistroLabel}
            id={`${name}.attire.black`}
            onChange={() => {
              setCustomAttire(undefined);
              return setAttire(blackBistroLabel);
            }}
            checked={attire === blackBistroLabel}
          />
        </Radio>
        <Radio label="Custom">
          <NativeRadioControl
            data-cy={`custom-${index}`}
            name={`${name}.attire`}
            value="custom"
            id={`${name}.attire.custom`}
            onChange={() => setAttire('custom')}
            checked={attire === 'custom'}
          />
        </Radio>
        {errors?.attire && <ErrorMessage>{errors.attire.message}</ErrorMessage>}
      </RadioGroup>
      {attire === 'custom' && (
        <>
          {pastAttires && pastAttires.length > 0 && (
            <>
              <Text preset="preset6" bold>
                Recent Custom Attires
              </Text>
              <AttireCards>
                {pastAttires.map((pastAttire: string) => (
                  <SelectableCard
                    selected={customAttire === pastAttire}
                    onClick={() => {
                      setCustomAttire(pastAttire);
                    }}
                  >
                    <TextWithoutMargin preset="preset6P">
                      {pastAttire}
                    </TextWithoutMargin>
                  </SelectableCard>
                ))}
              </AttireCards>
            </>
          )}
          <TextField label="Enter custom attire">
            <Input
              data-cy={`custom-input-${index}`}
              isValid={!errors?.customAttire?.message}
              value={customAttire}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setCustomAttire(e.currentTarget.value)
              }
            />
          </TextField>
        </>
      )}
      <Label bold={!smallTitle}>Accessories</Label>
      <Checkbox
        data-cy={`black-shoes-${index}`}
        label="Black non-slip shoes"
        name={`${name}.blackNonSlipShoes`}
        nativeControlId={`${name}.blackNonSlipShoes`}
        onChange={(e) => setBlackNonSlipShoes(e.currentTarget.checked)}
        checked={blackNonSlipShoes}
      />
      <Checkbox
        data-cy={`mask-required-${index}`}
        label="Face Mask"
        name={`${name}.maskRequired`}
        nativeControlId={`${name}.maskRequired`}
        onChange={(e) => setMaskRequired(e.currentTarget.checked)}
        checked={maskRequired}
      />
      <Checkbox
        data-cy={`black-apron-${index}`}
        label="Black apron"
        name={`${name}.blackApron`}
        nativeControlId={`${name}.blackApron`}
        onChange={(e) => setBlackApron(e.currentTarget.checked)}
        checked={blackApron}
      />
      {isCook(label) && (
        <Checkbox
          label="Knife kit"
          name={`${name}.knifeKit`}
          nativeControlId={`${name}.knifeKit`}
          onChange={(e) => setKnifeKit(e.currentTarget.checked)}
          checked={knifeKit}
        />
      )}
      {isBartender(label) && (
        <Checkbox
          data-cy={`bar-kit-${index}`}
          label="Bar kit"
          name={`${name}.barKit`}
          nativeControlId={`${name}.barKit`}
          onChange={(e) => setBarKit(e.currentTarget.checked)}
          checked={barKit}
        />
      )}
      <Separator />
    </>
  );
};

export default TenderInstructions;
