import styled from 'styled-components';
import { NavLink, Link as RRLink } from 'react-router-dom';
import { Container as GridContainer } from 'react-grid-system';

import TextComponent from 'src/components/Text';
import { MD_BREAK_POINT } from 'src/utils/constants';

export const Grid = styled(GridContainer)`
  margin-left: -15px !important;
  margin-right: -15px !important;
  margin-bottom: 92px;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.color.inkDark};
  background-color: ${({ theme }) => theme.color.inkClear};
  margin-top: 44px;
  padding: 50px;
  border-radius: 6px;

  @media (max-width: ${MD_BREAK_POINT}) {
    padding: 20px;
  }
`;

export const BookingNameContainer = styled.div`
  padding-top: 40px;
  text-align: center;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const BookingName = styled.h2`
  display: inline-block;
  margin: 0;
  font-size: 26px;
  font-weight: normal;
`;

export const Disclaimer = styled.div`
  padding: 20px 0;
  margin: 24px 0 8px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.statusPositiveLight};
  border-radius: 4px;

  a {
    color: var(--color-ink-dark);
    text-decoration: none;
    font-weight: 600;
  }
`;
export const DisclaimerTooSoon = styled(Disclaimer)`
  background-color: ${({ theme }) => theme.color.statusWarningLight};
`;

export const DetailTabBar = styled.div`
  margin-top: 16px;
  height: 37px;
`;

export const BookingsTabBar = styled.div`
  height: 42px;
  padding-top: 42px;
`;

export const DetailTab = styled(NavLink)`
  font-size: 18px;
  font-weight: normal;
  color: ${({ theme }) => theme.color.inkNotasdark};
  text-decoration: none;
  position: relative;
  margin-right: 24px;
  padding: 10px 0;

  &.active {
    color: ${({ theme }) => theme.color.inkDark};

    &:after {
      border-radius: 4px;
      width: 44px;
      height: 4px;
      background-color: ${({ theme }) => theme.color.primary};

      content: ' ';
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }
`;

export const BookingsTab = styled(DetailTab)`
  font-size: 22px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 32px auto;
`;

export const Link = styled(RRLink)`
  margin: 0 2px;
  color: ${({ theme }) => theme.color.brandTend};
  font-size: 16px;
  text-decoration: none;
`;

export const Text = styled(TextComponent)`
  color: ${({ theme }) => theme.color.inkNotasdark};
  margin-bottom: 16px;
  text-align: center;
`;
