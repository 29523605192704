import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@material/react-button';
import MaterialIcon from '@material/react-material-icon';
import differenceInHours from 'date-fns/differenceInHours';

import { formatAddress } from '@hiretend/google-places';

import Card from 'src/components/Card';
import DetailsSection from 'src/components/DetailsSection';
import { EditLocationModal } from 'src/components/EditBookingModals';
import Map from 'src/components/Map';
import { ModalContext } from 'src/components/Modal';
import Text from 'src/components/Text';
import {
  MyClientJob_myClientJob_venue_address as Address,
  MyClientJob_myClientJob as Job,
} from 'src/graphql/queries/__generated__/MyClientJob';
import editBtn from 'src/images/icons/editGreen.svg';

const CircleIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--color-primary-light);
  color: var(--color-brand-tend);
  text-align: center;
  > i {
    font-size: 14px;
    margin-top: 4px;
  }
`;
const CircleIconWithTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;
const MapContainer = styled.div`
  position: relative;
  height: 300px;
  margin-top: 16px;
  margin-bottom: 24px;
`;
const ButtonWithIcon = styled(Button)`
  text-transform: none;
  > span {
    align-items: center;
    display: flex;
  }
`;
const Img = styled.img`
  margin-right: 4px;
  margin-top: 1px;
`;
const UL = styled.ul`
  padding-left: 16px;
`;

type Props = {
  booking: Job;
};

const getCoordinatesFromAddress = (
  address: Omit<Address, '__typename'> | undefined,
) => {
  const lat = address?.lat || 0;
  const lng = address?.lng || 0;
  return { lat, lng };
};

const CircleIconWithText: React.FC<{ icon: string; text: string }> = ({
  icon,
  text,
}) => (
  <CircleIconWithTextContainer>
    <CircleIconContainer>
      <MaterialIcon icon={icon} />
    </CircleIconContainer>
    <Text preset="preset5" bold>
      {text}
    </Text>
  </CircleIconWithTextContainer>
);

const LocationCard: React.FC<Props> = ({ booking }) => {
  const modalContext = useContext(ModalContext);
  const { startDateTime } = booking;
  const isEventSoon =
    differenceInHours(new Date(startDateTime), new Date()) < 24;
  const openModal = () => {
    modalContext?.setModalContent(
      <EditLocationModal defaultValues={booking} />,
    );
    modalContext?.setIsModalOpen(true);
  };
  return (
    <Card
      title="Location Details"
      actionComponent={
        !isEventSoon && (
          <ButtonWithIcon onClick={openModal}>
            <Img src={editBtn} alt="edit location" /> Edit Location
          </ButtonWithIcon>
        )
      }
    >
      <Text preset="preset5P" bold>
        {booking.venue?.name}
      </Text>
      <Text preset="preset5P">
        {booking.venue?.address && formatAddress(booking.venue.address)}
      </Text>
      <MapContainer>
        <Map
          marker={
            booking.venue?.address &&
            getCoordinatesFromAddress(booking.venue.address)
          }
        />
      </MapContainer>
      <FlexContainer>
        {booking.mealProvided && (
          <CircleIconWithText icon="done" text="Staff meal provided" />
        )}
        {booking.useBackDoor && (
          <CircleIconWithText icon="done" text="Use back door" />
        )}
      </FlexContainer>
      {booking.venue?.otherInstructions && (
        <DetailsSection title="Other Location Details">
          <Text preset="preset5P">{booking.venue.otherInstructions}</Text>
        </DetailsSection>
      )}
      <DetailsSection title="Parking">
        <UL>
          <li>
            <Text preset="preset5P">{booking.venue?.parking.label}</Text>
          </li>
          {booking.venue?.parkingInstructions && (
            <li>
              <Text preset="preset5P">{booking.venue.parkingInstructions}</Text>
            </li>
          )}
        </UL>
      </DetailsSection>
      <DetailsSection title="Location Contact">
        <Text preset="preset5P">
          {booking.locationContact?.name},{' '}
          {booking.locationContact?.phoneNumber}
        </Text>
      </DetailsSection>
    </Card>
  );
};

export default LocationCard;
