import { differenceInMinutes } from 'date-fns';

const getBreakHours = (breakTimeMinutes?: number | null): number =>
  (breakTimeMinutes && breakTimeMinutes / 60) || 0;

const hourDiff = (startTime?: Date | null, endTime?: Date | null) => {
  let hours = 1;
  if (startTime && endTime) {
    hours = differenceInMinutes(endTime, startTime) / 60;
    if (hours <= 0) {
      hours += 24;
    }
  }
  return hours;
};

export const calculateTotalRate = (
  rate?: number | null,
  startTime?: Date | null,
  endTime?: Date | null,
  breakTimeMinutes?: number | null,
  quantity = 1,
  tipAmountValue?: number | null,
): number => {
  if (!rate) {
    return 0;
  }
  const totalHours =
    quantity * (hourDiff(startTime, endTime) - getBreakHours(breakTimeMinutes));
  const totalTip = totalHours * (tipAmountValue || 0);
  const total = totalHours * rate + totalTip;
  if (total < 0) {
    return 0;
  }
  return total;
};

export const calculateTip = (
  startTime?: Date | null,
  endTime?: Date | null,
  breakTimeMinutes?: number,
  quantity = 1,
  tipAmountValue?: number,
): number => {
  const totalHours =
    quantity * (hourDiff(startTime, endTime) - getBreakHours(breakTimeMinutes));
  const totalTip = totalHours * (tipAmountValue || 0);
  if (totalTip < 0) {
    return 0;
  }
  return totalTip;
};
