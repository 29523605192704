import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { ModalContext } from 'src/components/Modal';
import Text from 'src/components/Text';

const OverlayContainer = styled.div<{
  lightboxBackground?: string | null;
}>`
  width: 100%;
  height: 100%;

  background: ${({ lightboxBackground }) => {
    return lightboxBackground ? lightboxBackground : 'transparent';
  }};
`;

const Title = styled(Text)`
  font-weight: 700;
`;

const Container = styled.div<{ top?: string; left?: string }>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  width: ${({ top }) => !top && '100%'};
  height: ${({ top }) => !top && '100%'};
  background: rgba(69, 79, 91, 0.6);
  border-radius: 4px;
`;

const WhiteModal = styled.div`
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  opacity: 1;
  align-self: center;
  overflow: auto;
  overflow-y: scroll;
  top: 0;
  left: 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.small}) {
    padding: 0;
  }
`;

const ScrollableContent = styled.div<{ small?: boolean }>`
  width: ${({ small }) => (small ? 'max-content' : '920px')};
  margin: 80px auto 92px;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--color-background-surface);
  ${({ small }) => small && 'transform: translateY(50%)'};
  @media (max-width: ${({ theme }) => theme.breakpoint.small}) {
    width: calc(100% - 40px);
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  right: 32px;
  top: 24px;
  text-align: center;
  background-color: var(--color-ink-dark);
  color: var(--color-support-lines-darker);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
`;

export const Overlay: React.FC = () => {
  const modalContext = useContext(ModalContext);
  const OverlayNode = document.getElementById('overlay');
  const BodyNode = document.body;
  OverlayNode?.classList.toggle('active', modalContext?.isModalOpen);
  BodyNode?.classList.toggle('noscroll', modalContext?.isModalOpen);
  const handleEscDown = (ev: KeyboardEvent) => {
    if (ev.keyCode === 27) {
      modalContext?.setIsModalOpen(false);
    }
  };
  if (modalContext?.closeOnEscKey) {
    document.addEventListener('keyup', handleEscDown, false);
  }
  useEffect(() => {
    document.removeEventListener('keyup', handleEscDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContext?.isModalOpen]);
  return (
    OverlayNode &&
    ReactDOM.createPortal(
      <OverlayContainer
        lightboxBackground={modalContext?.lightboxBackground}
        onClick={() => {
          modalContext?.setIsModalOpen(!modalContext?.closeOnOutsideClick);
        }}
      >
        {modalContext?.modalContent}
      </OverlayContainer>,
      OverlayNode,
    )
  );
};

type Props = {
  top?: string;
  left?: string;
};

type ModalProps = {
  top?: string;
  left?: string;
  title?: string;
  small?: boolean;
};

export const ModalContainer: React.FC<Props> = ({ top, left, children }) => {
  return (
    <Container
      top={top}
      left={left}
      onClick={(ev) => {
        ev.stopPropagation(); //prevent close dialog if click on the content
      }}
    >
      {children}
    </Container>
  );
};

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  small,
  ...rest
}) => {
  const modalContext = useContext(ModalContext);
  return (
    <ModalContainer {...rest}>
      <WhiteModal>
        <ScrollableContent small={small}>
          {title && <Title preset="preset3">{title}</Title>}
          {children}
        </ScrollableContent>
      </WhiteModal>
      <CloseContainer
        onClick={() => {
          modalContext?.setIsModalOpen(false);
        }}
      >
        ×
      </CloseContainer>
    </ModalContainer>
  );
};
