import React, { FC, useState } from 'react';
import styled from 'styled-components';

import Carousel from 'src/components/Carousel';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import { useGetMyMostRecentDescriptionsAsClientQuery } from 'src/graphql/queries/GetMyMostRecentDescriptions';
import { GetMyMostRecentDescriptionsAsClient_myMostRecentDescriptions as Description } from 'src/graphql/queries/__generated__/GetMyMostRecentDescriptionsAsClient';

const SelectableCard = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  border: 2px solid
    ${({ selected }) =>
      selected ? 'var(--color-primary)' : 'var(--color-support-lines)'};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  max-height: 184px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

const Container = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  onClick: (description: string) => void;
};

const PastJobDescriptions: FC<Props> = ({ onClick }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  );
  const handleClick = (description: string, index: number) => {
    setSelectedIndex(index);
    onClick(description);
  };
  let pastDescription: Description[] = [];
  const {
    loading,
    error,
    data: queryData,
  } = useGetMyMostRecentDescriptionsAsClientQuery();
  if (!pastDescription.length && !loading && !error && queryData) {
    pastDescription = queryData.myMostRecentDescriptions;
  }
  return (
    <Container>
      {!!pastDescription.length && <Label>Recent Descriptions</Label>}
      <Carousel>
        {pastDescription.map(({ description }: Description, index: number) => (
          <SelectableCard
            key={description}
            selected={index === selectedIndex}
            onClick={() => handleClick(description, index)}
          >
            <Text preset="preset5P">{description}</Text>
          </SelectableCard>
        ))}
      </Carousel>
    </Container>
  );
};

export default PastJobDescriptions;
