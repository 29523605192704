import StaffingCard, { TIP_TYPES } from './StaffingCard';
import {
  Position as PositionType,
  StaffingArrayData as StaffingArrayDataType,
  StaffingData as StaffingDataType,
} from './StaffingCard.type';

export default StaffingCard;
export { TIP_TYPES };
export type Position = PositionType;
export type StaffingArrayData = StaffingArrayDataType;
export type StaffingData = StaffingDataType;
