import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import Card from 'src/components/Card';
import Text from 'src/components/Text';
import { MyClientJob_myClientJob as Job } from 'src/graphql/queries/__generated__/MyClientJob';
import { formatMoney } from 'src/utils/money';
import { calculateTip, calculateTotalRate } from 'src/utils/position';
import information from 'src/images/icons/information.svg';

const DividerLight = styled.div`
  height: 1px;
  background-color: var(--color-support-lines);
  margin: 0 auto 16px;
`;
const PaymentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PaymentText = styled(Text)`
  margin-bottom: 16px;
`;
const ImageContainer = styled.span`
  margin-left: 4px;
`;

type Props = {
  booking: Job;
};

const PaymentCard: React.FC<Props> = ({ booking }) => {
  let paymentSubtotal = 0;
  let paymentTotalTips = 0;
  const { total, totalAdjustment } = booking;

  booking.shifts?.forEach((shift) => {
    const unpaidBreakMinutes = shift.unpaidBreakMinutes || undefined;
    const quantity = shift.quantity || undefined;
    const tipAmount = shift.tipAmount || undefined;
    const startDateTime = new Date(shift.startDateTime);
    const endDateTime = new Date(shift.endDateTime);

    paymentSubtotal += calculateTotalRate(
      shift.rate,
      startDateTime,
      endDateTime,
      unpaidBreakMinutes,
      quantity,
      0,
    );

    paymentTotalTips += calculateTip(
      startDateTime,
      endDateTime,
      unpaidBreakMinutes,
      quantity,
      tipAmount,
    );
  });

  return (
    <Card title="Payment">
      <PaymentContainer>
        <PaymentText preset="preset5" bold>
          Subtotal
        </PaymentText>
        <PaymentText preset="preset5">
          {formatMoney(paymentSubtotal)}
        </PaymentText>
      </PaymentContainer>
      <PaymentContainer>
        <PaymentText preset="preset5" bold>
          Total tips included
        </PaymentText>
        <PaymentText preset="preset5">
          {formatMoney(paymentTotalTips)}
        </PaymentText>
      </PaymentContainer>
      {!!totalAdjustment && (
        <PaymentContainer>
          <PaymentContainer>
            <PaymentText preset="preset5" bold>
              Post-Job adjustments
            </PaymentText>
            <ImageContainer
              data-for="info-tooltip"
              data-tip="This is an adjustment made to<br />
               the original estimated cost. If you have<br />
               any questions contact your Account<br />
               Manager or business@hiretend.com"
            >
              <img alt="more info" src={information} />
            </ImageContainer>
          </PaymentContainer>
          <PaymentText preset="preset5">
            {formatMoney(totalAdjustment)}
          </PaymentText>
        </PaymentContainer>
      )}
      <DividerLight />
      <PaymentContainer>
        <Text preset="preset3" bold>
          Total
        </Text>
        <Text preset="preset3" bold>
          {formatMoney((total ?? 0) + (totalAdjustment ?? 0))}
        </Text>
      </PaymentContainer>
      <ReactTooltip
        className="tend-tooltip"
        id="info-tooltip"
        place="bottom"
        backgroundColor="#333333"
        textColor="white"
        multiline
      />
    </Card>
  );
};

export default PaymentCard;
