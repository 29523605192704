import React, { useState } from 'react';
import MaterialIcon from '@material/react-material-icon';
import { Input } from '@material/react-text-field';
import { useDebounce } from 'use-debounce';

import TextField from 'src/components/TextField';
import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 0;
  height: 48px;
  .mdc-text-field {
    margin: 0;
    background-color: ${({ theme }) => theme.color.backgroundSurface};
    width: 300px;
    @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
      width: 100%;
    }
    .mdc-floating-label {
      font-size: 16px;
      top: 14px;
    }
    .mdc-text-field__input {
      padding: 12px 44px;
    }
  }
`;

type Props = {
  onChange(value: string): void;
};

const SearchBar: React.FC<Props> = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState<string>();
  const debouncedInput = useDebounce(searchValue, 250)[0];
  onChange(debouncedInput || '');

  return (
    <Container>
      <TextField
        dense
        label="Search"
        leadingIcon={<MaterialIcon icon="search" />}
        trailingIcon={
          searchValue ? <MaterialIcon icon="cancel" role="button" /> : undefined
        }
        onTrailingIconSelect={() => setSearchValue('')}
      >
        <Input
          value={searchValue}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setSearchValue(e.currentTarget.value);
          }}
        />
      </TextField>
    </Container>
  );
};

export default SearchBar;
