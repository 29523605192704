import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import {
  ChangeClientPassword,
  ChangeClientPasswordVariables,
} from './__generated__/ChangeClientPassword';

const CHANGE_CLIENT_PASSWORD_MUTATION = gql`
  mutation ChangeClientPassword($input: ClientPasswordChangeInput!) {
    changeClientPassword(input: $input) {
      id
      email
    }
  }
`;

export const useChangeClientPasswordMutation = (
  options?: MutationHookOptions<
    ChangeClientPassword,
    ChangeClientPasswordVariables
  >,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<ChangeClientPassword, ChangeClientPasswordVariables>(
    CHANGE_CLIENT_PASSWORD_MUTATION,
    {
      ...options,
      onCompleted: useCallback(
        (data) => {
          track(EVENTS.RESET_PASSWORD_REQUEST_SENT);
          onCompleted?.(data);
        },
        [onCompleted],
      ),
    },
  );
};

export default CHANGE_CLIENT_PASSWORD_MUTATION;
