import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import InfiniteScroll from 'react-infinite-scroller';

import { MyClientJobsFilters } from 'src/__generated__/globalTypes';
import { GET_MY_CLIENT_JOBS } from 'src/graphql/queries/myClientJobs';
import { MyClientJobs } from 'src/graphql/queries/__generated__/MyClientJobs';

import BookingCard from 'src/components/BookingCard';
import EmptyCard from 'src/components/EmptyCard';
import SearchBar from 'src/components/SearchBar';
import Spinner from 'src/components/Spinner';

import {
  ClearBackground,
  ListContainer,
  Separator,
  Snackbar,
  SpinnerContainer,
  StatusMessage,
  WidthLimiter,
} from 'src/components/BookingStyles/BookingStyles.styled';
import {
  BookingsTab,
  BookingsTabBar,
  FlexContainer,
  Link,
  Text,
} from 'src/components/BookingDetail/BookingDetail.styled';

const RESULTS_PER_PAGE = 18;

const Loading = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
);

type EmptyStateParams = {
  search?: string;
  upcoming: boolean;
};

const EmptyState: FC<EmptyStateParams> = ({ search, upcoming }) => {
  if (!search) {
    return (
      <>
        <Text preset="preset3" color="var(--color-ink-notasdark)">
          There are no Bookings to show here
        </Text>
        <Text preset="preset5P" color="var(--color-ink-notasdark)">
          All {upcoming ? 'upcoming' : 'past'} Bookings will be displayed here.{' '}
          <Link to="/client">Book now</Link>
        </Text>
        <FlexContainer>
          <EmptyCard />
          <EmptyCard />
          <EmptyCard />
        </FlexContainer>
      </>
    );
  }
  return (
    <StatusMessage>
      No bookings named <b>{search}</b> found. Please try a different search.
    </StatusMessage>
  );
};

type Props = {
  showUpcoming: boolean;
};

const ClientBookings: FC<Props> = ({ showUpcoming }) => {
  const location = useLocation();
  const history = useHistory();
  const showNotification = location.state?.bookingCreated;

  const [searchInput, setSearchInput] = useState<string>();
  const [filters, setFilters] = useState<MyClientJobsFilters>({
    upcoming: showUpcoming,
  });
  const { data, fetchMore, loading } = useQuery<MyClientJobs>(
    GET_MY_CLIENT_JOBS,
    {
      fetchPolicy: 'network-only',
      variables: {
        first: RESULTS_PER_PAGE,
        filters,
      },
    },
  );

  const handleSnackClose = () => {
    history.replace(location.pathname, {});
  };

  const handleFetchMore = () =>
    fetchMore({
      variables: {
        after: data?.myClientJobs.pageInfo.endCursor,
        first: RESULTS_PER_PAGE,
        filters,
      },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        fetchMoreResult
          ? {
              myClientJobs: {
                __typename: previousResult.myClientJobs.__typename,
                edges: [
                  ...previousResult.myClientJobs.edges,
                  ...fetchMoreResult.myClientJobs.edges,
                ],
                pageInfo: fetchMoreResult.myClientJobs.pageInfo,
              },
            }
          : previousResult,
    });

  useEffect(() => {
    setFilters({
      name: searchInput,
      upcoming: showUpcoming,
      past: !showUpcoming,
    });
  }, [searchInput, showUpcoming]);
  return (
    <>
      {showNotification && (
        <Snackbar
          message="Your job was created successfully."
          actionText="×"
          onClose={handleSnackClose}
        />
      )}
      <ClearBackground>
        <WidthLimiter>
          <BookingsTabBar>
            <BookingsTab exact to="/bookings">
              Upcoming
            </BookingsTab>
            <BookingsTab to="/bookings/past">Past</BookingsTab>
          </BookingsTabBar>
        </WidthLimiter>
        <Separator />
      </ClearBackground>
      <WidthLimiter>
        <SearchBar onChange={setSearchInput} />
        <Separator />
      </WidthLimiter>
      {loading ? (
        <Loading />
      ) : (
        <ListContainer>
          {data?.myClientJobs.edges.length ? (
            <InfiniteScroll
              loadMore={handleFetchMore}
              hasMore={data.myClientJobs.pageInfo.remainingEdges > 0}
              loader={<Loading key={data.myClientJobs.pageInfo.endCursor} />}
            >
              {data?.myClientJobs.edges.map((edge) => (
                <BookingCard key={edge.node.id} {...edge.node} />
              ))}
            </InfiniteScroll>
          ) : (
            <EmptyState search={searchInput} upcoming={showUpcoming} />
          )}
        </ListContainer>
      )}
    </>
  );
};

export default ClientBookings;
