import styled from 'styled-components';
import { Button } from '@material/react-button';
import { LG_BREAK_POINT, SM_BREAK_POINT } from 'src/utils/constants';
import { ClientTenderStatus } from 'src/__generated__/globalTypes';

export const MENU_ITEM_WIDTH = 148;

export const CardContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;

  @media (min-width: ${SM_BREAK_POINT}) {
    width: 50%;
  }

  @media (min-width: ${LG_BREAK_POINT}) {
    width: 25%;
  }
`;

export const Container = styled(CardContainer)`
  height: auto;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: var(--color-background-surface);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
`;

export const GrowingContainer = styled.div`
  flex-grow: 1;
`;

export const TenderName = styled.p`
  flex-grow: 0;
  font-weight: 500;
  font-size: 18px;
  margin: 12px 0 0 0;
`;

export const TenderPosition = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: var(--color-ink-notasdark);
  margin: 4px 0 0 0;
  padding: 0 40px;
  flex-grow: 1;
`;

export const DataContainer = styled.div`
  border-bottom: 1px solid var(--color-primary-light);
  margin: 10px 20px 0 20px;
  padding-bottom: 10px;

  &:after {
    content: ' ';
    clear: both;
    display: block;
  }
`;

export const DataLabel = styled.div`
  float: left;
  font-size: 14px;
  color: var(--color-ink-notasdark);
`;

export const DataValue = styled.div`
  float: right;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  padding-top: 16px;
`;

export const TenderAvatarContainer = styled.div`
  position: relative;
  height: 64px;
  width: 64px;
  margin: 0 auto;
`;

export const TenderAvatar = styled.img<{ status?: string | null }>`
  border: 1px solid var(--color-primary-light);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  opacity: ${({ status }) => {
    return status === null || status === ClientTenderStatus.FAVORITE ? 1 : 0.5;
  }};
`;

export const TenderStatusBadge = styled.div<{ status?: string }>`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ status }) => {
    if (status === ClientTenderStatus.FAVORITE) {
      return ({ theme }) => theme.color.primary;
    }
    if (status === ClientTenderStatus.BLOCKED) {
      return ({ theme }) => theme.color.statusDestructive;
    }
    if (status === ClientTenderStatus.LESS_PREFERRED) {
      return ({ theme }) => theme.color.inkDark;
    }
  }};
  bottom: 0;
  right: 0;
`;

export const StatusIcon = styled.img`
  color: ${({ theme }) => theme.color.inkClear};
  padding-top: 1px;
`;

export const MenuItem = styled.div`
  padding: 6px 0 6px 16px;
  height: 22px;
  width: ${MENU_ITEM_WIDTH - 16}px;
  user-select: none;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundApp};
  }
`;

const BaseButton = styled(Button)`
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  padding: 8px 24px;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GreenBackgroundButton = styled(BaseButton)`
  &.mdc-button {
    background-color: ${({ theme }) => theme.color.primaryLight};
    border-color: ${({ theme }) => theme.color.primaryLight};
    :hover {
      background-color: ${({ theme }) => theme.color.statusPositiveLight};
      border-color: ${({ theme }) => theme.color.statusPositiveLight};
      color: ${({ theme }) => theme.color.brand50};
    }
    :active {
      background-color: ${({ theme }) => theme.color.statusPositiveLight};
      border-color: ${({ theme }) => theme.color.statusPositiveLight};
      color: ${({ theme }) => theme.color.inkDark};
    }
  }
`;

export const GreyTextButton = styled(BaseButton)`
  :only-child {
    margin-top: 44px;
  }
  &.mdc-button:not(:disabled) {
    color: ${({ theme }) => theme.color.inkNotasdark};
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundApp};
  }
  &:active {
    background-color: ${({ theme }) => theme.color.backgroundApp};
    color: ${({ theme }) => theme.color.inkDark};
  }
`;

export const BlackTextButton = styled(BaseButton)`
  &.mdc-button {
    color: ${({ theme }) => theme.color.inkDark};
    &:hover {
      background-color: ${({ theme }) => theme.color.backgroundApp};
      color: ${({ theme }) => theme.color.inkNotasdark};
    }
    &:active {
      background-color: ${({ theme }) => theme.color.backgroundApp};
      color: ${({ theme }) => theme.color.inkDark};
    }
  }
`;

export const RedTextButton = styled(BaseButton)`
  &.mdc-button {
    color: ${({ theme }) => theme.color.statusDestructive};
    :hover {
      background-color: ${({ theme }) => theme.color.statusDestructiveLight};
    }
    :active {
      background-color: ${({ theme }) => theme.color.statusDestructiveLight};
      color: ${({ theme }) => theme.color.inkDark};
    }
  }
`;
