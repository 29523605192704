import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { Button } from '@material/react-button';
import { Address } from '@hiretend/google-places';
import SelectableCard from 'src/components/SelectableCard';
import { MD_BREAK_POINT } from 'src/utils/constants';

const Container = styled(SelectableCard)`
  width: 100%;

  button {
    margin: 20px 0 0;
  }

  @media (max-width: ${MD_BREAK_POINT}) {
    button {
      display: none;
    }
  }
`;

const Paragraph = styled.p`
  color: var(--color-ink-notasdark);
  margin: 0;

  /* Hide text after three lines for long addresses */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const BoldText = styled.span<{ bold?: boolean }>`
  color: var(--color-ink-dark);
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
`;

type Props = {
  venue?: {
    name?: string | null;
    address?: Address;
  } | null;
  onClick: () => void;
  selected: boolean;
};

const AddressCard: React.FC<Props> = ({ venue, onClick, selected }) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick();
  };
  const addressStreetText = `${venue?.address?.streetName} ${venue?.address?.streetNumber}`;
  const addressCityText = `${venue?.address?.city}, ${venue?.address?.state} ${venue?.address?.country} ${venue?.address?.zip}`;
  return (
    <Container onClick={handleClick} selected={selected}>
      <div>
        <Paragraph>
          <BoldText bold>
            {venue?.name ? venue.name : addressStreetText}
          </BoldText>
        </Paragraph>
        <Paragraph>
          {venue?.name
            ? `${addressStreetText}, ${addressCityText}`
            : addressCityText}
        </Paragraph>
      </div>
      {!selected && (
        <Button onClick={handleClick} outlined>
          Use this address
        </Button>
      )}
    </Container>
  );
};

export default AddressCard;
