import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';

import Card from 'src/components/Card';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { ModalContext } from 'src/components/Modal';
import Text from 'src/components/Text';
import { useDeleteTimesheetFile } from 'src/graphql/mutations/DeleteTimesheetFile';
import { useUploadTimesheetFile } from 'src/graphql/mutations/UploadTimesheetFile';
import crossIcon from 'src/images/icons/cross.svg';
import warningIcon from 'src/images/icons/warning.svg';

// The !important is necessary to supress some styles from
// the FileUploader (react-drag-drop-files) component
const FileUploaderContainer = styled.div`
  label {
    border: none !important;
  }
`;

const FileAreaContainer = styled.div<{ disabled: boolean }>`
  align-items: center;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.supportLines : theme.color.primaryLight};
  border: 1px dashed ${({ theme }) => theme.color.brandTend};
  border-radius: 8px;
  color: ${({ theme }) => theme.color.inkDark};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 68px;
  justify-content: center;

  label:first-child {
    border: none;
  }
`;

const BoldInlineText = styled.span`
  font-weight: bold;
`;

const BrowseButton = styled.div`
  border: 2px solid black;
  border-radius: 4px;
  margin-left: 8px;
  padding: 6px 4px 4px 4px;
`;

const CrossIcon = styled.img`
  cursor: pointer;
`;

const WarningIcon = styled.img`
  margin-right: 12px;
`;

const TimesheetListContainer = styled.div`
  margin-top: 34px;

  div:last-child {
    margin-bottom: 0;
  }
`;

const TimesheetNameContainer = styled.div`
  ${({ theme }) => theme.text.preset6};
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundApp};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 12px;
`;

const UploadingStateContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundApp};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 16px;

  p:first-child {
    font-weight: 700;
  }
`;

const UploadingFile: React.FC = () => {
  return (
    <UploadingStateContainer>
      <Text preset="preset6">Uploading ...</Text>
      <Text preset="preset6P">Please wait while your files are up.</Text>
    </UploadingStateContainer>
  );
};

const UploadErrorContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.statusDestructiveLight};
  border-radius: 8px;
  display: flex;
  padding: 14px 12px;

  p {
    color: ${({ theme }) => theme.color.statusDestructive};
  }
`;

const UploadErrorText = styled(Text)`
  color: ${({ theme }) => theme.color.statusDestructive};
`;

const UploadError: React.FC<{ error: string; fileName: string }> = ({
  error,
  fileName,
}) => {
  return (
    <div>
      <UploadErrorContainer>
        <WarningIcon alt="Error uploading file" src={warningIcon} />
        <Text preset="preset6">{fileName}</Text>
      </UploadErrorContainer>
      <UploadErrorText preset="preset7P">Error: {error}</UploadErrorText>
    </div>
  );
};

interface TimesheetFile {
  id: string;
  name: string;
}

interface Props {
  bookingId: string;
  bookingTimesheetFiles: TimesheetFile[];
  disabled: boolean;
}

const getFileContents = (file: File) =>
  new Promise<{ fileContents: string; contentType: string }>(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const [, fileContentsWithoutDataURL] = (reader.result as string).split(
          ',',
        );
        resolve({
          fileContents: fileContentsWithoutDataURL,
          contentType: file.type,
        });
      };
      reader.onerror = (error) => reject(error);
    },
  );

const TimesheetsCard: React.FC<Props> = ({
  bookingId,
  bookingTimesheetFiles,
  disabled,
}) => {
  const [uploadTimesheetFile, { loading: uploadingFile, error: uploadError }] =
    useUploadTimesheetFile(bookingId, {
      onError: () => {
        // do nothing
      },
    });
  const [lastSelectedFileName, setLastSelectedFileName] = useState<
    string | undefined
  >();

  const [deleteTimesheetFile] = useDeleteTimesheetFile(bookingId);

  const modalContext = useContext(ModalContext);
  const showDeleteConfirmationModal = (timesheetFile: TimesheetFile) => {
    modalContext?.setIsModalOpen(true);
    modalContext?.setModalContent(
      <ConfirmationDialog
        title="Remove File?"
        handleConfirm={() => {
          deleteTimesheetFile({
            variables: {
              timesheetFileId: timesheetFile.id,
            },
          });
        }}
        primaryButtonText="Remove File"
        dangerButton
      >
        <Text preset="preset5P">
          Are you sure you want to remove the{' '}
          <BoldInlineText>{timesheetFile.name}</BoldInlineText> file from this
          booking
        </Text>
      </ConfirmationDialog>,
    );
  };

  return (
    <Card title="Upload Timesheet">
      <FileUploaderContainer>
        <FileUploader
          name="file"
          disabled={disabled}
          handleChange={async (file: File) => {
            setLastSelectedFileName(file.name);
            const { fileContents, contentType } = await getFileContents(file);
            uploadTimesheetFile({
              variables: {
                jobId: bookingId,
                input: {
                  name: file.name,
                  fileContents,
                  fileContentType: contentType,
                },
              },
            });
          }}
          hoverTitle=" "
        >
          <FileAreaContainer disabled={disabled}>
            <Text preset="preset6">Drop files here or</Text>
            <BrowseButton>
              <Text bold preset="preset6">
                Browse
              </Text>
            </BrowseButton>
          </FileAreaContainer>
        </FileUploader>
      </FileUploaderContainer>
      {bookingTimesheetFiles.length || uploadingFile || uploadError ? (
        <TimesheetListContainer>
          {bookingTimesheetFiles.map((timesheetFile) => (
            <TimesheetNameContainer key={timesheetFile.id}>
              {timesheetFile.name}
              <CrossIcon
                src={crossIcon}
                alt="Delete file"
                onClick={() => showDeleteConfirmationModal(timesheetFile)}
              />
            </TimesheetNameContainer>
          ))}
          {uploadingFile ? <UploadingFile /> : null}
          {uploadError ? (
            <UploadError
              error={uploadError.message}
              fileName={`${lastSelectedFileName}`}
            />
          ) : null}
        </TimesheetListContainer>
      ) : null}
    </Card>
  );
};

export default TimesheetsCard;
