import { createGlobalStyle } from 'styled-components';
import '@material/react-button/dist/button.min.css';
import '@material/react-card/dist/card.min.css';
import '@material/react-checkbox/dist/checkbox.min.css';
import '@material/react-list/dist/list.min.css';
import '@material/react-radio/dist/radio.min.css';
import '@material/react-snackbar/dist/snackbar.min.css';
import '@material/react-tab-bar/dist/tab-bar.min.css';
import '@material/react-tab-indicator/dist/tab-indicator.min.css';
import '@material/react-tab-scroller/dist/tab-scroller.min.css';
import '@material/react-tab/dist/tab.min.css';
import '@material/react-text-field/dist/text-field.min.css';
// The following line can't be min.css as it's causing an issue when building the project
import '@material/react-layout-grid/dist/layout-grid.css';
import 'rc-time-picker/assets/index.css';
import 'react-dates/lib/css/_datepicker.css';
import 'swiper/swiper-bundle.min.css';
import { MD_BREAK_POINT } from 'src/utils/constants';

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-brand-tend: #045445;
    --color-primary: #045445;
    --color-primary-active: #2C6C53;
    --color-primary-light: #EAF4EF;
    --color-secondary: #394E6F;
    --color-ink-dark: #333333;
    --color-ink-clear: #FFFFFF;
    --color-ink-notasdark: #6D727C;
    --color-ink-link: #353AC5;
    --color-ink-primary: #149262;
    --color-background-app: #F7F9FB;
    --color-background-surface: #FFFFFF;
    --color-status-destructive: #EB5757;
    --color-status-destructive-light: #fbdddd;
    --color-status-positive: #149262;
    --color-status-positive-light: #E7EEED;
    --color-status-warning: #FF9C00;
    --color-status-warning-light: #FFE3BB;
    --color-status-info: #18A0FB;
    --color-support-lines: #EEE;
    --color-support-lines-darker: #CACBCC;
    --mdc-theme-primary: var(--color-primary);
    --mdc-theme-secondary: var(--color-primary);
    --mdc-theme-on-primary: var(--color-ink-clear);
    --mdc-theme-on-secondary: var(--color-ink-notasdark);
    --mdc-text-field: var(--color-background-app);
    --mdc-text-field--textarea: var(--color-primary-active);
    --mdc-text-field--focused: var(--color-primary);
    --mdc-theme-error: var(--color-status-destructive);
  }

  /* https://github.com/jamesmfriedman/rmwc/issues/276 */
  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--color-primary);
  }

  /* Tabs style */
  .mdc-tab__content {
    text-transform: none;
    font-weight: 400;
    font-family: system, -apple-system, BlinkMacSystemFont, 'SFNSText-Regula', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
    letter-spacing: initial;
    color: var(--color-ink-notasdark);
  }
  .mdc-tab--active .mdc-tab__content {
    color: var(--color-ink-dark);
  }
  .mdc-tab__ripple:after,
  .mdc-tab__ripple:before {
    background: none;
  }
  .mdc-button__label {
    font-size: 16px;
    letter-spacing: normal;
    font-family: system, -apple-system, BlinkMacSystemFont, 'SFNSText-Regula', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
    line-height: 0;
  }
  .mdc-button {
    box-shadow: none;
    :hover {
      box-shadow: none;
    }
  }

  .rc-time-picker-panel {
    .rc-time-picker-panel-input-wrap {
      display: none;
    }
    .rc-time-picker-panel-select li:hover {
      background-color: var(--color-support-lines);
    }
    .rc-time-picker-panel-select-option-selected {
      background: var(--color-primary) !important;
      color: var(--color-ink-clear);
    }
    .rc-time-picker-panel-inner {
      top: 56px;
    }
  }

  html, body, #root, #overlay {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: system, -apple-system, BlinkMacSystemFont, 'SFNSText-Regula', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
    font-variant: tabular-nums ;
    line-height: 1.5;
    font-feature-settings: "tnum";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    p, h1, h2, h3, h4, h5, a, input, li, span, div {
     &::selection {
        color: var(--color-ink-clear);
     }
    }
    background-color: var(--color-background-app);

    &.noscroll {
      overflow: hidden;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  ::selection{
    background: var(--color-primary); /* WebKit/Blink Browsers */
  }

  #overlay{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;

    &.active{
      display: block;
      pointer-events: scroll;
    }
  }
  .pac-container {
    z-index: 100000000;
  }
  .tend-tooltip {
    opacity: 1 !important;
    border-radius: 4px !important;
    .multi-line {
      text-align: left !important;
    }
  }

  /* SwiperJS customization */
  .swiper-button-next, .swiper-button-prev {
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    border-radius: 4px;
    width: 28px;
    height: 32px;

    &:after {
      font-size: 16px;
      font-weight: bold;
    }

    @media (max-width: ${MD_BREAK_POINT}) {
      display: none;
    }
  }
  .swiper-button-prev { left: 0; }
  .swiper-button-next { right: 0; }
  .swiper-slide {
    height: auto !important;
    @media (min-width: ${MD_BREAK_POINT}) {
      display: flex;
    }
  }
`;
