import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { tokenIsValid } from 'src/auth';
import { authContext } from 'src/contexts/AuthContext';

const PublicRoute: React.FC<RouteProps> = (props) => {
  const auth = useContext(authContext);
  if (!tokenIsValid(auth.authToken)) {
    return <Route {...props} />;
  }
  return <Redirect to="/" />;
};

export default PublicRoute;
