import React, { FC } from 'react';
import styled from 'styled-components';
import {
  default as CheckboxMaterial,
  CheckboxProps,
} from '@material/react-checkbox';

type Props = {
  label?: string;
  inline?: boolean;
} & Pick<
  CheckboxProps,
  | 'checked'
  | 'className'
  | 'disabled'
  | 'indeterminate'
  | 'name'
  | 'nativeControlId'
  | 'onChange'
  | 'children'
>;

const Container = styled.div<{ inline?: boolean }>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  align-items: center;
  padding-right: 10px;
`;

const Label = styled.label`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Checkbox: FC<Props> = ({ inline, label, ...props }) => (
  <Container inline={inline}>
    <CheckboxMaterial {...props} />
    <Label htmlFor={props.nativeControlId}>{label}</Label>
  </Container>
);

export default Checkbox;
