import React, { useContext, useEffect, useState } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { authContext } from 'src/contexts/AuthContext';
import useQueryParams from 'src/hooks/QueryParams';
import { userVerification } from 'src/restClient';

const UserVerificationRoute: React.FC<RouteProps> = () => {
  const auth = useContext(authContext);

  const queryParams = useQueryParams();
  const temporaryToken = queryParams.get('token');

  const [isFailedVerification, setIsFailedVerification] = useState<boolean>();
  const [verifiedToken, setVerifiedToken] = useState<string>();

  useEffect(() => {
    const verifyAndAuthenticateUser = async () => {
      const verificationResponse =
        temporaryToken && (await userVerification(temporaryToken));

      if (verificationResponse && verificationResponse.ok) {
        const content = await verificationResponse.json();
        setVerifiedToken(content.authToken);
      } else {
        setIsFailedVerification(true);
      }
    };

    verifyAndAuthenticateUser();
  }, [temporaryToken]);

  if (isFailedVerification) {
    return <Redirect to="/signup?verification_error=true" />;
  } else if (verifiedToken) {
    auth.setAuthToken(verifiedToken);
    return <Redirect to="/" />;
  }

  return null;
};

export default UserVerificationRoute;
