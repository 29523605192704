import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import INSTRUCTIONS_FOR_JOB_DETAILS from '../fragments/InstructionsForJobDetails';
import {
  EditJobAttire,
  EditJobAttireVariables,
} from './__generated__/EditJobAttire';

const EDIT_JOB_ATTIRE = gql`
  mutation EditJobAttire($jobId: ID!, $input: [JobInstructionUpdateInput!]!) {
    updateAttiresInClientJob(jobId: $jobId, input: $input) {
      id
      instructions {
        ...InstructionsForJobDetails
      }
    }
  }
  ${INSTRUCTIONS_FOR_JOB_DETAILS}
`;

type MutationOptions = MutationHookOptions<
  EditJobAttire,
  EditJobAttireVariables
>;
export const useEditJobAttireMutation = (
  jobId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<EditJobAttire, EditJobAttireVariables>(EDIT_JOB_ATTIRE, {
    ...options,
    onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
      (data) => {
        track(EVENTS.UPDATED_SHIFT_POSITION_INSTRUCTIONS, {
          'Job ID': jobId,
        });
        onCompleted?.(data);
      },
      [jobId, onCompleted],
    ),
  });
};

export default EDIT_JOB_ATTIRE;
