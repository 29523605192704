import React, { useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import AuthContextProvider from 'src/contexts/AuthContext';
import Booking from 'src/views/Booking';
import BookingConfirmation from 'src/views/BookingConfirmation';
import {
  ClientBookings,
  ClientBookingsDetail,
  ClientBookingsStaff,
} from 'src/views/ClientBookings';
import { PastTenders } from 'src/views/PastTenders';
import PasswordReset from 'src/views/PasswordReset';
import PrivateRoute from 'src/components/PrivateRoute';
import PublicRoute from 'src/components/PublicRoute';
import SignIn from 'src/views/SignIn';
import SignUp from 'src/views/SignUp';
import UserVerificationRoute from 'src/components/UserVerificationRoute';
import { ModalContextProvider, Overlay } from 'src/components/Modal';
import { EVENTS, initializeMixpanel, track } from './analytics';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});
initializeMixpanel();

const App: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pathname: location.pathname });
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      FullStory.init({ orgId: 'S8Y6A' });
    }
  }, []);
  return (
    <AuthContextProvider>
      <ModalContextProvider>
        <Switch>
          <PublicRoute exact path="/signin">
            <SignIn />
          </PublicRoute>
          <PublicRoute exact path="/signup">
            <SignUp />
          </PublicRoute>
          <PublicRoute
            exact
            path={[
              '/guest',
              '/guest/job',
              '/guest/staff',
              '/guest/instructions',
              '/guest/complete',
            ]}
          >
            <Booking />
          </PublicRoute>
          <PublicRoute exact path="/guest/confirmation">
            <BookingConfirmation />
          </PublicRoute>
          <UserVerificationRoute exact path="/user_verification" />
          <PublicRoute exact path="/password_reset">
            <PasswordReset />
          </PublicRoute>
          <PrivateRoute exact path={['/']}>
            <Redirect to={'/bookings'} />
          </PrivateRoute>
          <PrivateRoute exact path="/bookings">
            <ClientBookings showUpcoming={true} />
          </PrivateRoute>
          <PrivateRoute exact path="/bookings/past">
            <ClientBookings showUpcoming={false} />
          </PrivateRoute>
          <PrivateRoute exact path={['/past-tenders']}>
            <PastTenders />
          </PrivateRoute>
          <PrivateRoute exact path={['/bookings/:bookingId/']}>
            <ClientBookingsDetail />
          </PrivateRoute>
          <PrivateRoute exact path={['/bookings/:bookingId/staff']}>
            <ClientBookingsStaff />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={[
              '/client',
              '/client/job',
              '/client/staff',
              '/client/instructions',
              '/client/complete',
            ]}
          >
            <Booking isClient />
          </PrivateRoute>
          <PublicRoute>
            <Redirect to="/signin" />
          </PublicRoute>
        </Switch>
        <Overlay />
      </ModalContextProvider>
    </AuthContextProvider>
  );
};

export default App;
