import React, { FC } from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import Text from 'src/components/Text';

interface Props {
  block?: boolean;
  visible?: boolean;
}

const BlockInfoContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--color-status-info);
  border-radius: 4px;
  padding: 12px 40px;

  .icon {
    color: white;
    margin-right: 8px;
  }
`;

const InfoText = styled(Text)`
  font-weight: 600;
  color: var(--color-ink-clear);
`;

const InfoMessage: FC<Props> = ({ children }) => {
  return (
    <BlockInfoContainer>
      <MaterialIcon icon="info" className="icon" />
      <InfoText preset="preset6">{children}</InfoText>
    </BlockInfoContainer>
  );
};
export default InfoMessage;
