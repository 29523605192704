import gql from 'graphql-tag';

export const GET_PARKING_GROUPS = gql`
  query ParkingGroups {
    parkingGroups {
      name
      options {
        label
        value
      }
    }
  }
`;
