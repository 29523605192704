import React from 'react';
import styled from 'styled-components';

export type Preset =
  | 'preset1'
  | 'preset2'
  | 'preset3'
  | 'preset4'
  | 'preset5'
  | 'preset5P'
  | 'preset6'
  | 'preset6P'
  | 'preset7'
  | 'preset7P';

const TextComponent = styled.p<{
  preset: Preset;
  bold?: boolean;
  color?: string;
}>`
  ${({ preset, theme }) => theme.text[preset]};
  color: ${({ color, theme }) => color || theme.color.inkDark};
  font-weight: ${({ bold }) => (bold ? '500' : '400')};
  margin: 0;
`;

type Props = {
  preset: Preset;
  bold?: boolean;
  color?: string;
  className?: string;
};

const Text: React.FC<Props> = ({ children, ...props }) => (
  <TextComponent {...props}>{children}</TextComponent>
);

export default Text;
