import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '@material/react-button';
import { BookingFormContent } from 'src/components/FormComponents';
import { DEFAULT_CONTACT_EMAIL, MD_BREAK_POINT } from 'src/utils/constants';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 24px;
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin: auto;
  @media (min-width: ${MD_BREAK_POINT}) {
    padding: 48px 0;
    .signup-button {
      margin-top: 16px;
    }
  }
  a {
    font-weight: 600;
    color: var(--color-ink-primary);
    text-decoration: none;
  }
`;

const BookingConfirmation = () => {
  const history = useHistory();
  return (
    <Container>
      <BookingFormContent>
        <h1>Your job has been successfully booked!</h1>
        <p>We will contact you via email for specifics about the job.</p>
        <p>
          Meanwhile, if you have any questions or if you need to make any change
          to the job details, feel free to contact{' '}
          <a href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>
            {DEFAULT_CONTACT_EMAIL}
          </a>
        </p>
      </BookingFormContent>
      <Button
        className="signup-button"
        raised
        onClick={() => history.push('/signup')}
      >
        Go Back To Sign Up
      </Button>
    </Container>
  );
};

export default BookingConfirmation;
