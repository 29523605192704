import styled from 'styled-components';
import { MD_BREAK_POINT } from 'src/utils/constants';
import TextComponent from 'src/components/Text';

const Header = styled.span`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px 24px 0;
`;

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 32px;
  color: var(--color-ink-dark);
`;

const RadioGroup = styled.div`
  margin: 12px 0;
`;

const Separator = styled.hr`
  border: none;
  border-top: 1px solid var(--color-support-lines);
  margin: 32px 0;
`;

const BookingFormContent = styled.div<{ padding?: string; noMargin?: boolean }>`
  background: white;
  padding: ${({ noMargin }) => (noMargin ? '0' : '16px')};
  margin-bottom: 12px;
  border: ${({ noMargin }) => (noMargin ? '' : '1px solid #eee')};
  text-align: left;
  position: relative;
  h1 {
    margin-top: 0;
  }
  @media (min-width: ${MD_BREAK_POINT}) {
    padding: ${({ padding }) => padding || '60px 40px'};
  }
`;

const Text = styled(TextComponent)`
  margin-top: 16px;
`;

export { BookingFormContent, Header, RadioGroup, Separator, Text, Title };
