import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GetMyMostRecentDescriptionsAsClient } from './__generated__/GetMyMostRecentDescriptionsAsClient';

const GET_MY_MOST_RECENT_DESCRIPTIONS = gql`
  query GetMyMostRecentDescriptionsAsClient {
    myMostRecentDescriptions {
      description
    }
  }
`;

export const useGetMyMostRecentDescriptionsAsClientQuery = (
  options?: QueryHookOptions<GetMyMostRecentDescriptionsAsClient>,
) =>
  useQuery<GetMyMostRecentDescriptionsAsClient>(
    GET_MY_MOST_RECENT_DESCRIPTIONS,
    options,
  );

export default GET_MY_MOST_RECENT_DESCRIPTIONS;
