import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@material/react-button';
import differenceInHours from 'date-fns/differenceInHours';

import Card from 'src/components/Card';
import Text from 'src/components/Text';
import { MyClientJob_myClientJob as Job } from 'src/graphql/queries/__generated__/MyClientJob';
import { ModalContext } from 'src/components/Modal';
import { EditAttireModal } from 'src/components/EditBookingModals';
import editBtn from 'src/images/icons/editGreen.svg';

export const UL = styled.ul`
  margin: 16px 0;
  padding-left: 16px;
`;
const ButtonWithIcon = styled(Button)`
  text-transform: none;
  > span {
    align-items: center;
    display: flex;
  }
`;
const Img = styled.img`
  margin-right: 4px;
  margin-top: 1px;
`;

type Props = {
  booking: Job;
};

const AttireCard: React.FC<Props> = ({ booking }) => {
  const modalContext = useContext(ModalContext);
  const { startDateTime } = booking;
  const isEventSoon =
    differenceInHours(new Date(startDateTime), new Date()) < 24;
  const openModal = () => {
    modalContext?.setModalContent(
      <EditAttireModal attires={booking.instructions} jobId={booking.id} />,
    );
    modalContext?.setIsModalOpen(true);
  };
  return (
    <Card
      title="Attire & Accessories"
      actionComponent={
        !isEventSoon && (
          <ButtonWithIcon onClick={openModal}>
            <Img src={editBtn} alt="edit attires" /> Edit Attire
          </ButtonWithIcon>
        )
      }
    >
      {booking.instructions?.map((positionInstructions) => {
        return (
          <div key={positionInstructions.position.id}>
            <Text preset="preset5" bold>
              {positionInstructions.position.name}
            </Text>
            <UL>
              {positionInstructions.attire && (
                <li>
                  <Text preset="preset5P">{positionInstructions.attire}</Text>
                </li>
              )}
              {positionInstructions.blackApron && (
                <li>
                  <Text preset="preset5P">Black Apron</Text>
                </li>
              )}
              {positionInstructions.blackNonSlipShoes && (
                <li>
                  <Text preset="preset5P">Black Non-Slip Shoes</Text>
                </li>
              )}
              {positionInstructions.barKit && (
                <li>
                  <Text preset="preset5P">Bar Kit</Text>
                </li>
              )}
              {positionInstructions.knifeKit && (
                <li>
                  <Text preset="preset5P">Knife Kit</Text>
                </li>
              )}
              {positionInstructions.maskRequired && (
                <li>
                  <Text preset="preset5P">Face Mask</Text>
                </li>
              )}
            </UL>
          </div>
        );
      })}
    </Card>
  );
};

export default AttireCard;
