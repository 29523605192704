import styled from 'styled-components';
import List from '@material/react-list';
import { SM_BREAK_POINT } from 'src/utils/constants';

export const MapContainer = styled.div`
  position: relative;
  height: 300px;
  margin-bottom: 20px;
`;

export const Amount = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: var(--color-primary);
`;

export const Total = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  p {
    margin: 0;
  }
  @media (min-width: ${SM_BREAK_POINT}) {
    display: flex;
  }
`;

export const Staffings = styled(List)`
  padding: 0;
  li {
    padding: 0;
    height: auto !important;
    align-items: flex-start;
    @media (max-width: ${SM_BREAK_POINT}) {
      display: block;
      .mdc-list-item__meta {
        margin-top: 8px;
      }
    }
  }
  .mdc-list-item__primary-text {
    font-weight: 600;
    margin: 0;
    height: auto;
    &:after {
      display: none;
    }
  }
  .mdc-list-item__secondary-text {
    p {
      color: var(--color-ink-notasdark);
      margin: 4px 0;
    }
    &:before {
      display: none;
    }
  }
`;
