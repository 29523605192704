import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import styled from 'styled-components';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from '@material/react-button';

import Steps, { Step } from 'src/components/Steps';
import BookingFormStaffing from 'src/views/BookingFormStaffing';
import { StaffingArrayData as StaffingData } from 'src/components/StaffingCard';
import BookingFormJob, { FormData as JobData } from 'src/views/BookingFormJob';
import BookingFormInstructions, {
  FormData as InstructionsData,
} from 'src/views/BookingFormInstructions';
import { FormData as TenderInstructionsFormData } from 'src/components/TenderInstructionsInput';
import BookingFormSummary, {
  FormData as SummaryData,
} from 'src/views/BookingFormSummary';

import objectIsEmpty from 'src/utils/objectIsEmpty';
import { DEFAULT_TIMEZONE, MD_BREAK_POINT } from 'src/utils/constants';
import { useCreateJobAsGuestMutation } from 'src/graphql/mutations/CreateJobAsGuest';
import { useCreateJobAsClientMutation } from 'src/graphql/mutations/CreateJobAsClient';

type SanitizedInstructions = Omit<TenderInstructionsFormData, 'attire'>[] & {
  attire?: string;
};

const Container = styled.div`
  width: 100%;
  padding: 16px 0 24px;
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin: auto;
  @media (min-width: ${MD_BREAK_POINT}) {
    padding: 48px 0;
  }
`;

const StepsContainer = styled.div`
  margin: auto auto 56px;
  width: 85%;
`;

const FormButtonsContainer = styled.div`
  display: flex;
  padding-top: 16px;

  /* TODO: make a big button variant in the component itself */

  .mdc-button {
    margin-right: 8px;
    padding: 8px 24px;
  }
`;

const Title = styled.h1`
  text-transform: capitalize;
  font-family: 'Rasa', serif;
  font-weight: 600;
  font-size: 36px;
  color: var(--color-ink-dark);
  margin: 0 0 16px;
  padding: 0 16px;
  @media (min-width: ${MD_BREAK_POINT}) {
    margin: 0 0 56px;
    padding: 0;
  }
`;

type FullFormData = JobData & StaffingData & InstructionsData & SummaryData;

interface FormButtonsProps {
  firstStep: boolean;
  lastStep: boolean;
  isLoading: boolean;
  onPrevious: () => void;
}

const sanitizeShiftsAndJobInstructions = (
  data: StaffingData & SummaryData & InstructionsData,
) => {
  const timeZone: string = data.timeZone || DEFAULT_TIMEZONE;
  delete data.timeZone;
  const jobInstructions: SanitizedInstructions = [];
  const positionIds: (string | undefined)[] = [undefined];
  const shifts = data.shifts?.map((shift) => {
    const copy = { ...shift };

    let startDateTime;
    let endDateTime;

    if (copy.startDateTime && copy.endDateTime && timeZone) {
      startDateTime = zonedTimeToUtc(copy.startDateTime, timeZone);
      endDateTime = zonedTimeToUtc(copy.endDateTime, timeZone);
    }

    const positionId = copy.position?.id;
    if (!positionIds.includes(positionId)) {
      positionIds.push(positionId);
      sanitizeJobInstructions(data, jobInstructions, positionId);
    }

    delete copy.position;
    delete copy.date;

    return {
      ...copy,
      positionId,
      startDateTime,
      endDateTime,
    };
  });
  return { shifts, jobInstructions };
};

const sanitizeJobInstructions = (
  data: InstructionsData,
  instructions: SanitizedInstructions,
  key?: string,
) => {
  if (key && data.jobInstructions && key in data.jobInstructions) {
    const instruction = data.jobInstructions[key];
    const attire = instruction.customAttire || instruction.attire;
    const sanitizedInstruction = {
      positionId: key,
      ...data.jobInstructions[key],
      attire,
    };
    delete sanitizedInstruction.customAttire;
    instructions.push(sanitizedInstruction);
  }
};

const sanitizeAddress = (data: JobData) => {
  const address = data.venue?.address;
  if (!address) {
    return address;
  }
  if (!address.neighborhood === null) {
    delete address.neighborhood;
  }
  if (!address?.streetNumber === null) {
    delete address.streetNumber;
  }
  if (!address?.streetName === null) {
    delete address.streetName;
  }
  if (!address?.zip) {
    delete address.zip;
  }
  return address;
};

const sanitizeData = (data: FullFormData) => {
  const { shifts, jobInstructions } = sanitizeShiftsAndJobInstructions(data);
  const address = sanitizeAddress(data);
  if (data.locationContact) {
    data.locationContact.phoneNumber = parsePhoneNumberFromString(
      data.locationContact?.phoneNumber || '',
      'US',
    )?.number.toString();
  }
  if (data.guest) {
    data.guest.phoneNumber = parsePhoneNumberFromString(
      data.guest?.phoneNumber || '',
      'US',
    )?.number.toString();
  }
  return {
    shifts,
    jobInstructions,
    job: data.job,
    venue: { ...data.venue, address },
    guest: data.guest,
    locationContact: data.locationContact,
  };
};

const FormButtons: React.FC<FormButtonsProps> = ({
  firstStep,
  lastStep,
  onPrevious,
  isLoading,
}) => (
  <FormButtonsContainer>
    {!firstStep && (
      <Button
        onClick={(event) => {
          event.preventDefault();
          onPrevious();
        }}
        data-cy="previousStep"
      >
        Previous Step
      </Button>
    )}
    <Button type="submit" raised data-cy="nextStep" disabled={isLoading}>
      {lastStep ? 'Submit Booking' : 'Next Step'}
    </Button>
  </FormButtonsContainer>
);

type Props = {
  isClient?: boolean;
};

const BookingView: React.FC<Props> = ({ isClient }) => {
  const route = isClient ? 'client' : 'guest';
  const CONFIRMATION_PATH = isClient ? '/bookings' : '/guest/confirmation';

  const BOOKING_PATHS = [
    `/${route}`,
    `/${route}/job`,
    `/${route}/staff`,
    `/${route}/instructions`,
    `/${route}/complete`,
  ];
  const [defaultStep, jobStep, staffingStep, instructionsStep, summaryStep] =
    BOOKING_PATHS;
  const selectForm = {
    [defaultStep]: BookingFormJob,
    [jobStep]: BookingFormJob,
    [staffingStep]: BookingFormStaffing,
    [instructionsStep]: BookingFormInstructions,
    [summaryStep]: BookingFormSummary,
  };
  const shouldRedirect = (
    path: string,
    data: FullFormData,
    history: History,
  ) => {
    if (path !== defaultStep && path !== jobStep && objectIsEmpty(data)) {
      history.replace(jobStep);
    }
  };
  const path = useLocation().pathname;
  const history = useHistory();
  const step = BOOKING_PATHS.indexOf(path) || 1;
  const [data, setData] = useState<FullFormData>({});
  shouldRedirect(path, data, history);
  const [createJobAsGuest] = useCreateJobAsGuestMutation();
  const [createJobAsClient] = useCreateJobAsClientMutation();
  const FormToRender = selectForm[path];
  const firstStep = step === 1;
  const lastStep = step === BOOKING_PATHS.length - 1;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const goToBookingStep = (nextStep: number) => {
    if (nextStep >= 0 && nextStep < BOOKING_PATHS.length) {
      history.push(BOOKING_PATHS[nextStep]);
    }
  };

  const goToPreviousStep = () => !firstStep && goToBookingStep(step - 1);

  const handleSubmit = async (newData: FullFormData) => {
    window.scrollTo(0, 0);
    const mergedData = {
      ...data,
      ...newData,
    };
    setData(mergedData);
    if (step === BOOKING_PATHS.length - 1) {
      setIsLoading(true);
      //disable submit button
      const submitData = sanitizeData(mergedData);
      const createBookingMutation = isClient
        ? createJobAsClient
        : createJobAsGuest;

      const { errors } = await createBookingMutation({
        variables: { input: submitData },
      });

      setIsLoading(false);
      if (errors) {
        // TODO: show an error message, maybe prompting to try again
      } else {
        history.push(CONFIRMATION_PATH, {
          bookingCreated: true,
        });
      }
    } else {
      goToBookingStep(step + 1);
    }
  };

  return (
    <Container>
      <Title>Book a new job</Title>
      <StepsContainer>
        <Steps current={step} onChange={goToBookingStep}>
          <Step title="Job" />
          <Step title="Staff" />
          <Step title="Instructions" />
          <Step title="Complete" />
        </Steps>
      </StepsContainer>
      <FormToRender
        data={data}
        onSubmit={handleSubmit}
        isClient={isClient}
        formButtons={
          <FormButtons
            firstStep={firstStep}
            lastStep={lastStep}
            onPrevious={goToPreviousStep}
            isLoading={isLoading}
          />
        }
      />
    </Container>
  );
};

export default BookingView;
