import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import {
  CreateJobAsClient,
  /**
   * We are skipping the "Variables" type here because the typing on the UI
   * Form is ultra messed up, in short, we are mixing the `Job` type with the
   * Mutation input type, the form should instead use its own strict type, which
   * requires a full refactor of the booking form (or adding a hundred `if`
   * statements for every possible nullable field)
   */
  // CreateJobAsClientVariables,
} from './__generated__/CreateJobAsClient';

const CREATE_JOB_AS_CLIENT = gql`
  mutation CreateJobAsClient($input: CreateJobAsClientInput!) {
    createJobAsClient(input: $input) {
      id
    }
  }
`;

export const useCreateJobAsClientMutation = (
  options?: MutationHookOptions<CreateJobAsClient>,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<CreateJobAsClient>(CREATE_JOB_AS_CLIENT, {
    ...options,
    onCompleted: useCallback(
      (data) => {
        track(EVENTS.CREATED_BOOKING, { 'User type': 'client' });
        onCompleted?.(data);
      },
      [onCompleted],
    ),
  });
};

export default CREATE_JOB_AS_CLIENT;
