import React from 'react';
import styled from 'styled-components';
import Text from 'src/components/Text';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 24px 16px;
`;

const Title = styled(Text)`
  margin: 24px 0;
`;

const Container = styled.div<{ gridStart?: string; gridEnd?: string }>`
  background-color: var(--color-background-surface);
  width: 100%;
  height: max-content;
  margin-top: 16px;
  border-radius: 4px;
`;

const CardBody = styled.div`
  padding: 0 16px 24px;
`;

type Props = {
  title: string;
  actionComponent?: React.ReactElement | false;
};

const Card: React.FC<Props> = ({ title, actionComponent, children }) => {
  return (
    <Container>
      <TitleContainer>
        <Title preset="preset3" bold>
          {title}
        </Title>
        {actionComponent}
      </TitleContainer>
      <CardBody>{children}</CardBody>
    </Container>
  );
};

export default Card;
