import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Authorization } from 'src/auth';
import useAuthHandler from 'src/hooks/AuthHandler';
import { getToken } from 'src/storage';

interface AuthContextType {
  authToken: string;
  decodedAuthToken: Authorization | undefined;
  setAuthToken: (token: string) => void;
  removeAuthToken: () => void;
}

export const authContext = React.createContext<AuthContextType>({
  authToken: '',
  decodedAuthToken: undefined,
  setAuthToken: () => {
    // do nothing
  },
  removeAuthToken: () => {
    // do nothing
  },
});

const { Provider } = authContext;

const AuthContextProvider: React.FC = ({ children }) => {
  const { authToken, decodedAuthToken, removeAuthToken, setAuthToken } =
    useAuthHandler(getToken());

  useEffect(() => {
    if (decodedAuthToken?.sub && decodedAuthToken?.email) {
      Sentry.setUser({
        id: decodedAuthToken.sub,
        email: decodedAuthToken.email,
      });
    }
  }, [decodedAuthToken]);

  return (
    <Provider
      value={{ authToken, decodedAuthToken, setAuthToken, removeAuthToken }}
    >
      {children}
    </Provider>
  );
};

export default AuthContextProvider;
