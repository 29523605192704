import gql from 'graphql-tag';

const INSTRUCTIONS_FOR_JOB_DETAILS = gql`
  fragment InstructionsForJobDetails on JobInstruction {
    id
    attire
    blackApron
    blackNonSlipShoes
    barKit
    knifeKit
    maskRequired
    position {
      id
      name
      rate
    }
  }
`;

export default INSTRUCTIONS_FOR_JOB_DETAILS;
