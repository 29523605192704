import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import { MD_BREAK_POINT } from 'src/utils/constants';

const DEFAULT_SLIDES_AMOUNT_DESKTOP = 3;
const DEFAULT_SLIDES_AMOUNT_MOBILE = 6;

interface Props {
  children: JSX.Element[];
}

const Container = styled.div`
  position: relative;
  user-select: none;
`;

const SwiperWrapper = styled.div<{ size: number }>`
  @media (min-width: ${MD_BREAK_POINT}) {
    width: ${({ size }) =>
      size > DEFAULT_SLIDES_AMOUNT_DESKTOP ? 'calc(100% - 96px)' : '100%'};
    margin: auto;
  }
`;

const Control = styled.div<{ hide: boolean }>`
  ${({ hide }) => hide && 'display: none'}
`;

SwiperCore.use([Navigation]);

const Carousel: FC<Props> = ({ children }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const hideControls = children.length <= DEFAULT_SLIDES_AMOUNT_DESKTOP;
  return (
    <Container>
      <SwiperWrapper size={children.length}>
        <Swiper
          allowTouchMove={false}
          direction="vertical"
          spaceBetween={8}
          slidesPerView={DEFAULT_SLIDES_AMOUNT_MOBILE}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpoints={{
            [parseInt(MD_BREAK_POINT.slice(0, -2), 10)]: {
              direction: 'horizontal',
              spaceBetween: 20,
              slidesPerView: DEFAULT_SLIDES_AMOUNT_DESKTOP,
              allowTouchMove: false,
            },
          }}
        >
          {children.map((child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
      <Control
        hide={hideControls}
        className="swiper-button-prev"
        ref={navigationPrevRef}
      />
      <Control
        hide={hideControls}
        className="swiper-button-next"
        ref={navigationNextRef}
      />
    </Container>
  );
};

export default Carousel;
