import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const MapContainer = styled.div`
  position: relative;
  height: 282px;
  background: var(--color-background-app);
  margin-bottom: 40px;
`;
