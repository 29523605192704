import React, { FC } from 'react';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ListItem, ListItemMeta, ListItemText } from '@material/react-list';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Address, formatAddress } from '@hiretend/google-places';
import { GET_TIMEZONE } from 'src/graphql/queries/getTimezone';
import { GetTimezone } from 'src/graphql/queries/__generated__/GetTimezone';
import objectIsEmpty from 'src/utils/objectIsEmpty';
import { calculateTotalRate } from 'src/utils/position';
import { formatMoney } from 'src/utils/money';
import { FormData as JobData } from 'src/views/BookingFormJob';
import { FormData as InstructionsData } from 'src/views/BookingFormInstructions';
import DetailsSection from 'src/components/DetailsSection';
import { ErrorMessage } from 'src/components/StatusMessages';
import {
  BookingFormContent,
  Separator,
  Title,
} from 'src/components/FormComponents';
import Label from 'src/components/Label';
import Map from 'src/components/Map';
import TextField from 'src/components/TextField';
import { FormData as TenderInstructions } from 'src/components/TenderInstructionsInput';
import {
  StaffingData as Staffing,
  StaffingArrayData as StaffingData,
  TIP_TYPES,
} from 'src/components/StaffingCard';
import { DEFAULT_COORDINATES } from 'src/utils/constants';
import {
  Amount,
  MapContainer,
  Staffings,
  Total,
} from './BookingFormSummary.styled';

export type FormData = {
  guest?: {
    name?: string;
    email?: string;
    phoneNumber?: string;
  };
  timeZone?: string;
};

type InputData = JobData & StaffingData & InstructionsData & FormData;

type FormErrors = {
  guest?: {
    name?: FieldError;
    email?: FieldError;
    phoneNumber?: FieldError;
  };
};

type ShiftWithInstructions = {
  instructions: TenderInstructions | undefined;
} & Staffing;

interface Props {
  data: InputData;
  formButtons: JSX.Element;
  onSubmit: (data: FormData) => void;
  isClient?: boolean;
}

const formatInstructions = (instructions: TenderInstructions | undefined) => {
  if (!instructions) {
    return '';
  }
  const attire =
    instructions.attire !== 'custom'
      ? instructions.attire
      : instructions.customAttire;
  return (
    <>
      <p>- {attire}</p>
      <p>{instructions.blackNonSlipShoes && '- Black non-slip shoes'}</p>
      <p>{instructions.blackApron && '- Black apron'}</p>
      <p>{instructions.knifeKit && '- Knife kit'}</p>
      <p>{instructions.barKit && '- Bar kit'}</p>
      <p>{instructions.maskRequired && '- Face mask required'}</p>
    </>
  );
};

const formatStaffingType = (staffing: Staffing) => {
  let formatted = `${staffing.quantity} ${staffing.position?.name}${
    staffing.quantity !== 1 ? 's' : ''
  } (${staffing.position?.rate && formatMoney(staffing.position?.rate)}/hr)`;
  if (staffing.tipType) {
    formatted += ` + ${TIP_TYPES[staffing.tipType]}`;
  }
  if (staffing.tipAmount) {
    formatted += ` (${formatMoney(staffing.tipAmount)}/hr)`;
  }
  return formatted;
};

const formatStaffingDetails = (
  shift: ShiftWithInstructions,
  timeZone: string,
) => {
  const startTimeUtc = zonedTimeToUtc(shift.startDateTime || 0, timeZone);
  const endTimeUtc = zonedTimeToUtc(shift.endDateTime || 0, timeZone);
  const startTime = utcToZonedTime(startTimeUtc, timeZone);
  const endTime = utcToZonedTime(endTimeUtc, timeZone);

  const date = `${startTime?.toDateString()} from ${format(
    startTime,
    'h:mm a',
  )} to ${format(endTime, 'h:mm a')}
  `;
  return (
    <div>
      <p>{date}</p>
      {formatInstructions(shift.instructions)}
    </div>
  );
};

const getAddressCoordinates = (address: Address | undefined) => {
  const lat = address?.lat || DEFAULT_COORDINATES.lat;
  const lng = address?.lng || DEFAULT_COORDINATES.lng;
  return { lat, lng };
};

const validationSchema = Yup.object().shape({
  guest: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string()
      .test('phoneNumber', 'Not a valid phone number', (value) => {
        return parsePhoneNumberFromString(value, 'US')?.isValid() || false;
      })
      .required(),
  }),
});

const BookingFormSummary: FC<Props> = ({
  data,
  formButtons,
  onSubmit,
  isClient,
}) => {
  const { lat, lng } = getAddressCoordinates(data.venue?.address);
  const { data: timeZoneData } = useQuery<GetTimezone>(GET_TIMEZONE, {
    fetchPolicy: 'network-only',
    variables: {
      lat,
      lng,
    },
  });
  const timeZone = timeZoneData?.getTimezone?.timeZone;
  const values = !objectIsEmpty(data) ? data : {};
  const { control, errors, handleSubmit, register, setValue } =
    useForm<FormData>({
      mode: 'onBlur',
      defaultValues: values,
      validationSchema: !isClient ? validationSchema : null,
    });
  register('timeZone');
  timeZone && setValue('timeZone', timeZone);
  const staffingTotals = data.shifts?.map((shift) =>
    calculateTotalRate(
      shift.position?.rate,
      shift.startDateTime,
      shift.endDateTime,
      shift.unpaidBreakMinutes,
      shift.quantity,
      shift.tipAmount,
    ),
  );
  const grandTotal = staffingTotals?.reduce(
    (total, current) => total + current,
    0,
  );

  const shiftsWithInstructions = data.shifts?.map((shift) => {
    let instructions;
    if (data.jobInstructions && shift.position?.id) {
      instructions = data.jobInstructions[shift.position.id];
    }
    return {
      ...shift,
      instructions,
    };
  });
  const formErrors = errors as unknown as FormErrors;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BookingFormContent>
        <h1>{data.job?.name}</h1>
        <p>{data.job?.description}</p>
        <Separator />
        <Title>Location</Title>
        <p>
          <b>{data.venue?.name}</b>
        </p>
        <p>{data.venue?.address && formatAddress(data.venue.address)}</p>
        <MapContainer>
          <Map
            marker={
              data.venue?.address && getAddressCoordinates(data.venue?.address)
            }
          />
        </MapContainer>
        <DetailsSection title="Location Details">
          <p>
            {data.job?.mealProvided ? '- Staff' : '- No staff'} meal provided
          </p>
          <p>{data.job?.useBackDoor && '- Use back door'}</p>
          <p>
            {data.venue?.otherInstructions &&
              `- ${data.venue.otherInstructions}`}
          </p>
        </DetailsSection>
        <DetailsSection title="Contact">
          <p>Name: {data.locationContact?.name}</p>
          <p>Phone: {data.locationContact?.phoneNumber}</p>
        </DetailsSection>
        <DetailsSection title={data.venue?.parkingName}>
          <p>{data.venue?.parkingInstructions}</p>
        </DetailsSection>
        <Separator />
        <Title>Staffing Details</Title>
        <Staffings twoLine nonInteractive>
          {shiftsWithInstructions?.map((shift, index) => (
            <ListItem key={`${shift.position?.name}${index}`}>
              <ListItemText
                primaryText={formatStaffingType(shift)}
                secondaryText={formatStaffingDetails(shift, timeZone || '')}
              />
              <ListItemMeta
                meta={
                  <Amount>
                    {staffingTotals && formatMoney(staffingTotals[index])}
                  </Amount>
                }
              />
            </ListItem>
          ))}
        </Staffings>
        <Separator />
        <Title>Payment</Title>
        <Total>
          <Label>Final Amount To be Paid:</Label>
          <Amount>{grandTotal && formatMoney(grandTotal)}</Amount>
        </Total>
        {!isClient && (
          <>
            <Separator />
            <Title>Client Details</Title>
            <Label required>Name</Label>
            <TextField label="Enter your name">
              <Controller
                control={control}
                name="guest.name"
                as={
                  <Input
                    data-cy="guest-name"
                    isValid={!formErrors?.guest?.name}
                  />
                }
              />
            </TextField>
            <Label required>Email</Label>
            <TextField label="Enter your email">
              <Controller
                control={control}
                name="guest.email"
                as={
                  <Input
                    data-cy="guest-email"
                    isValid={!formErrors?.guest?.email}
                  />
                }
              />
            </TextField>
            <Label required>Phone Number</Label>
            <TextField label="Enter your phone number">
              <Controller
                control={control}
                name="guest.phoneNumber"
                as={
                  <Input
                    data-cy="guest-phone"
                    isValid={!formErrors?.guest?.phoneNumber}
                  />
                }
              />
            </TextField>
          </>
        )}
      </BookingFormContent>
      <ErrorMessage block visible={!objectIsEmpty(formErrors)} />
      {formButtons}
    </form>
  );
};

export default BookingFormSummary;
