import React, { createContext, useState } from 'react';

export type ModalContextParams = {
  closeOnEscKey: boolean;
  setCloseOnEscKey: React.Dispatch<React.SetStateAction<boolean>>;
  closeOnOutsideClick: boolean;
  setCloseOnOutsideClick: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lightboxBackground: string | undefined;
  setLightboxBackground: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  modalContent: JSX.Element | null;
  setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
};

export const ModalContext = createContext<ModalContextParams | null>(null);

export const ModalContextProvider: React.FC = ({ children }) => {
  const [closeOnOutsideClick, setCloseOnOutsideClick] = useState(true);
  const [closeOnEscKey, setCloseOnEscKey] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lightboxBackground, setLightboxBackground] = useState<
    string | undefined
  >('transparent');
  const [modalContent, setModalContent] = useState<JSX.Element | null>(<></>);

  const modalContext = {
    isModalOpen,
    setIsModalOpen,
    lightboxBackground,
    setLightboxBackground,
    modalContent,
    setModalContent,
    closeOnOutsideClick,
    setCloseOnOutsideClick,
    closeOnEscKey,
    setCloseOnEscKey,
  };

  return (
    <ModalContext.Provider value={modalContext}>
      {children}
    </ModalContext.Provider>
  );
};
