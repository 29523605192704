import React, { FC } from 'react';
import { GoogleApiWrapper, Map, MapProps, Marker } from 'google-maps-react';

type Props = {
  marker?: {
    lat: number;
    lng: number;
  };
} & MapProps;

const MapContainer: FC<Props> = ({ google, marker, loaded }) => {
  return loaded ? (
    <Map google={google} initialCenter={marker} center={marker} zoom={16}>
      {marker && <Marker position={marker} />}
    </Map>
  ) : (
    <p>Loading map...</p>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
})(MapContainer);
