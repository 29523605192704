import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@material/react-button';

import { Modal, ModalContext } from 'src/components/Modal';

const ChildrenContainer = styled.div`
  margin-top: 24px;
  padding-bottom: 16px;
  ${({ theme }) => theme.text.preset3}
`;

const DialogFooter = styled.div`
  padding-top: 16px;
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 12px;
  text-transform: none;
  padding-left: 16px;
  padding-right: 16px;
  &.mdc-button:not(:disabled) {
    color: ${({ theme }) => theme.color.inkDark};
    border-color: ${({ theme }) => theme.color.inkDark};
  }
`;

const ConfirmButton = styled(Button)<{ isRed?: boolean }>`
  text-transform: none;
  padding-left: 16px;
  padding-right: 16px;
  &.mdc-button:not(:disabled) {
    color: ${({ theme, isRed }) =>
      isRed ? theme.color.statusDestructive : theme.color.inkClear};
  }
`;

type Props = {
  title: string;
  handleConfirm: () => void;
  primaryButtonText: string;
  dangerButton?: boolean;
  disabled?: boolean;
};

export const ConfirmationDialog: React.FC<Props> = ({
  title,
  children,
  handleConfirm,
  primaryButtonText,
  dangerButton,
  disabled,
}) => {
  const modalContext = useContext(ModalContext);

  if (!modalContext) {
    return null;
  }
  const handleSubmit = async () => {
    modalContext.setIsModalOpen(false);
    await handleConfirm();
  };
  return (
    <Modal small title={title}>
      <ChildrenContainer>{children}</ChildrenContainer>
      <DialogFooter>
        <CancelButton
          onClick={() => {
            modalContext.setIsModalOpen(false);
          }}
        >
          Cancel
        </CancelButton>
        <ConfirmButton
          disabled={disabled}
          onClick={handleSubmit}
          isRed={dangerButton}
          raised={!dangerButton}
        >
          {primaryButtonText}
        </ConfirmButton>
      </DialogFooter>
    </Modal>
  );
};
