import React from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import { differenceInHours, isFuture } from 'date-fns';

import { MyClientJob_myClientJob as Booking } from 'src/graphql/queries/__generated__/MyClientJob';

import {
  AttireCard,
  DescriptionCard,
  LocationCard,
  PaymentCard,
  ShiftsCard,
  TimesheetsCard,
} from 'src/components/BookingDetailCards';
import Text from 'src/components/Text';
import { DEFAULT_CONTACT_EMAIL } from 'src/utils/constants';
import { Disclaimer, DisclaimerTooSoon, Grid } from './BookingDetail.styled';

type Props = {
  booking: Booking;
};

const BookingDetailTab: React.FC<Props> = ({ booking }) => {
  const { startDateTime, endDateTime } = booking;
  const isEventSoon =
    differenceInHours(new Date(startDateTime), new Date()) < 24;
  const isEventInFuture = isFuture(new Date(endDateTime));
  const DisclaimerEventSoon: React.FC = () => (
    <DisclaimerTooSoon>
      <Text preset="preset6P">
        The Booking is in less than 24hrs. You cannot edit any Booking details
        anymore. Please reach out to{' '}
        <a href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>{DEFAULT_CONTACT_EMAIL}</a>{' '}
        if you have any questions.
      </Text>
    </DisclaimerTooSoon>
  );
  const NormalDisclaimer: React.FC = () => (
    <Disclaimer>
      <Text preset="preset6P">
        You can edit Booking details only up to 24hrs before it starts. Please
        reach out to{' '}
        <a href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>{DEFAULT_CONTACT_EMAIL}</a>{' '}
        if you have any questions.
      </Text>
    </Disclaimer>
  );
  const RightCards: React.FC = () => (
    <Col>
      <Row gutterWidth={16}>
        <Col>
          <DescriptionCard booking={booking} />
        </Col>
      </Row>
      {process.env.REACT_APP_ENVIRONMENT !== 'production' ? (
        <Row gutterWidth={16}>
          <Col>
            <TimesheetsCard
              bookingId={booking.id}
              bookingTimesheetFiles={booking.timesheetFiles}
              disabled={isEventInFuture}
            />
          </Col>
        </Row>
      ) : null}
      <Row gutterWidth={16}>
        <Col>
          <PaymentCard booking={booking} />
        </Col>
      </Row>
    </Col>
  );

  return (
    <Grid fluid>
      {isEventInFuture && (
        <Row align="center">
          <Col md={10} offset={{ md: 1 }}>
            {isEventSoon ? <DisclaimerEventSoon /> : <NormalDisclaimer />}
          </Col>
        </Row>
      )}
      <Row gutterWidth={16}>
        <Visible sm xs>
          <RightCards />
        </Visible>
        <Col md={8}>
          <LocationCard booking={booking} />
          <ShiftsCard booking={booking} />
          <AttireCard booking={booking} />
        </Col>
        <Visible md lg xl>
          <RightCards />
        </Visible>
      </Row>
    </Grid>
  );
};

export default BookingDetailTab;
