import React, { FC, useEffect, useState } from 'react';
import { Input } from '@material/react-text-field';
import Icon from '@material/react-material-icon';
import { Address, formatAddress, getAddress } from '@hiretend/google-places';
import { GoogleApiWrapper, Map, MapProps, Marker } from 'google-maps-react';
import TextField from 'src/components/TextField';
import { Container, MapContainer } from './AddressLookup.styled';

type Props = {
  onChange: (address: Address) => void;
  initialAddress?: Address;
  isValid?: boolean;
} & MapProps;

const searchInputId = 'google-places-autocomplete-input';

const AddressLookup: FC<Props> = ({
  initialAddress,
  onChange,
  google,
  loaded,
  isValid = true,
}) => {
  const [address, setAddress] = useState<Address>(
    initialAddress || { city: '', state: '', country: '' },
  );
  const [searchText, setSearchText] = useState<string>(
    (initialAddress && formatAddress(initialAddress)) || '',
  );
  useEffect(() => {
    if (initialAddress) {
      setAddress(initialAddress);
      setSearchText(formatAddress(initialAddress));
    }
  }, [initialAddress]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  let autocomplete: google.maps.places.Autocomplete;

  useEffect(() => {
    const inputElement = document.getElementById(
      searchInputId,
    ) as HTMLInputElement;

    if (inputElement) {
      // This assignment would trigger a warning because autocomplete is declared
      // in the render function. However we need it in that context to be able to
      // use the google object. If we assign it outside of the effect hook then it
      // creates several copies of it, even when using a ref hook.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      autocomplete = new google.maps.places.Autocomplete(inputElement);
      autocomplete.addListener('place_changed', handlePlaceChange);
    }
  }, []);

  const handlePlaceChange = () => {
    const place = autocomplete.getPlace();
    place.formatted_address && setSearchText(place.formatted_address);
    const newAddress = getAddress(place);
    setAddress(newAddress);
    onChange(newAddress);
  };

  let marker: google.maps.LatLngLiteral | undefined;
  if (address?.lat && address?.lng) {
    marker = {
      lat: address.lat,
      lng: address.lng,
    };
  }

  return (
    <Container>
      <TextField
        label="Enter address"
        onTrailingIconSelect={() => {
          setSearchText('');
        }}
        trailingIcon={<Icon icon="close" />}
      >
        <Input
          data-cy="googleAddress"
          isValid={isValid}
          id={searchInputId}
          value={searchText}
          onChange={handleChange}
        />
      </TextField>
      <MapContainer>
        {loaded ? (
          <Map google={google} zoom={16} initialCenter={marker} center={marker}>
            {marker && <Marker position={marker} />}
          </Map>
        ) : (
          <p>Loading map...</p>
        )}
      </MapContainer>
    </Container>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
})(AddressLookup);
