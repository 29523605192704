import { EVENTS, track } from './analytics';
import {
  SIGNIN_ENDPOINT,
  SIGNUP_ENDPOINT,
  USER_VERIFICATION_ENDPOINT,
} from './routes';

const request = (
  path: string,
  method: 'POST' | 'GET',
  body?: Record<string, unknown>,
  token?: string,
) => {
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(path, {
    method,
    headers,
    body: body && JSON.stringify(body),
  });
};

export const signUp = async (body: Record<string, unknown>) => {
  const result = await request(SIGNUP_ENDPOINT, 'POST', body);

  result.ok && track(EVENTS.SIGNED_UP);

  return result;
};
export const signIn = async (email: string, password: string) => {
  const result = await request(SIGNIN_ENDPOINT, 'POST', { email, password });

  result.ok && track(EVENTS.SIGNED_IN);

  return result;
};

export const userVerification = async (token: string) => {
  const result = await request(
    USER_VERIFICATION_ENDPOINT,
    'GET',
    undefined,
    token,
  );

  result.ok && track(EVENTS.EMAIL_VERIFIED);

  return result;
};
