import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import {
  CreateJobAsGuest,
  /**
   * We are skipping the "Variables" type here because the typing on the UI
   * Form is ultra messed up, in short, we are mixing the `Job` type with the
   * Mutation input type, the form should instead use its own strict type, which
   * requires a full refactor of the booking form (or adding a hundred `if`
   * statements for every possible nullable field)
   */
  // CreateJobAsGuestVariables,
} from './__generated__/CreateJobAsGuest';

const CREATE_JOB_AS_GUEST = gql`
  mutation CreateJobAsGuest($input: CreateJobAsGuestInput!) {
    createJobAsGuest(input: $input) {
      id
    }
  }
`;

export const useCreateJobAsGuestMutation = (
  options?: MutationHookOptions<CreateJobAsGuest>,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<CreateJobAsGuest>(CREATE_JOB_AS_GUEST, {
    ...options,
    onCompleted: useCallback(
      (data) => {
        track(EVENTS.CREATED_BOOKING, { 'User type': 'guest' });
        onCompleted?.(data);
      },
      [onCompleted],
    ),
  });
};

export default CREATE_JOB_AS_GUEST;
