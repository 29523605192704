import gql from 'graphql-tag';

const SHIFT_FOR_JOB_DETAILS = gql`
  fragment ShiftForJobDetails on Shift {
    id
    isDeletable
    startDateTime
    endDateTime
    unpaidBreakMinutes
    quantity
    rate
    tipType
    tipAmount
    position {
      id
      name
      rate
      tipTypes
    }
  }
`;

export default SHIFT_FOR_JOB_DETAILS;
