import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import BookingDetail from 'src/components/BookingDetail';
import { MyClientJob } from 'src/graphql/queries/__generated__/MyClientJob';
import { GET_MY_CLIENT_JOB } from 'src/graphql/queries/myClientJob';
import BookingDetailTab from 'src/components/BookingDetail/BookingDetailTab';
import { EVENTS, track } from 'src/analytics';

const ClientBookings: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { data } = useQuery<MyClientJob>(GET_MY_CLIENT_JOB, {
    fetchPolicy: 'network-only',
    variables: { id: bookingId },
  });

  useEffect(() => {
    data?.myClientJob?.id &&
      track(EVENTS.OPENED_BOOKING_DETAILS, { 'Job ID': data.myClientJob.id });
  }, [data?.myClientJob?.id]);

  return data && data.myClientJob ? (
    <BookingDetail booking={data.myClientJob}>
      <BookingDetailTab booking={data.myClientJob} />
    </BookingDetail>
  ) : null;
};

export default ClientBookings;
