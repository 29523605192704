import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { formatAddress } from '@hiretend/google-places';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { LG_BREAK_POINT, SM_BREAK_POINT } from 'src/utils/constants';
import { MyClientJobs_myClientJobs_edges_node as Job } from 'src/graphql/queries/__generated__/MyClientJobs';
import { DEFAULT_TIMEZONE } from 'src/utils/constants';
import { formatMoney } from 'src/utils/money';

const Gutter = styled.div`
  display: inline-block;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;

  @media (min-width: ${SM_BREAK_POINT}) {
    width: 50%;
  }

  @media (min-width: ${LG_BREAK_POINT}) {
    width: 33.333333%;
  }
`;

const Container = styled(Link)`
  width: 100%;
  display: block;
  position: relative;
  padding: 42px 16px 28px;
  color: var(--color-ink-dark);
  text-decoration: none;
  user-select: none;
  background-color: var(--color-background-surface);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  transition: box-shadow 200ms ease-in-out;
  &:hover {
    box-shadow: 0 0 0 1px var(--color-primary);
  }
  &:active {
    background-color: var(--color-primary-light);
  }
`;

const BookingName = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  height: 44px;
  margin: 0 0 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }
`;

const VenueAddress = styled.div`
  font-size: 14px;
  line-height: 22px;
  height: 44px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--color-ink-notasdark);
`;

const BookingCardInfo = styled.div`
  display: flex;
  height: 36px;
  border-bottom: 1px solid var(--color-support-lines);
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &:last-child div:last-child {
    color: var(--color-primary);
  }
`;

const InfoTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-ink-notasdark);
`;

const InfoValue = styled.div<{ bold?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${({ bold }) => (bold ? 'bold' : '')};
`;

const BookingCard: React.FC<Job> = ({
  id,
  name,
  venue,
  startDateTime,
  tendersAmount,
  total,
}) => {
  const bookingDate = utcToZonedTime(
    new Date(startDateTime),
    venue?.address.timezone || DEFAULT_TIMEZONE,
  );

  return (
    <Gutter>
      <Container to={`/bookings/${id}`}>
        <BookingName>
          <abbr title={name}>{name}</abbr>
        </BookingName>
        <VenueAddress>
          {venue?.address && formatAddress(venue.address)}
        </VenueAddress>
        <BookingCardInfo>
          <InfoTitle>Date</InfoTitle>
          <InfoValue>{format(bookingDate, 'MM/dd/yyyy')}</InfoValue>
        </BookingCardInfo>
        <BookingCardInfo>
          <InfoTitle>Start Time</InfoTitle>
          <InfoValue>{format(bookingDate, 'h:mm aaaa')}</InfoValue>
        </BookingCardInfo>
        <BookingCardInfo>
          <InfoTitle>Tenders</InfoTitle>
          <InfoValue>{tendersAmount}</InfoValue>
        </BookingCardInfo>
        <BookingCardInfo>
          <InfoTitle>Total</InfoTitle>
          <InfoValue bold>
            {total !== null ? formatMoney(total) : 'Undetermined'}
          </InfoValue>
        </BookingCardInfo>
      </Container>
    </Gutter>
  );
};

export default BookingCard;
