import gql from 'graphql-tag';

export const GET_SHIFTS_FOR_MY_JOB = gql`
  query GetShiftsForMyJob($jobId: ID!, $filter: String) {
    getShiftsForMyJob(jobId: $jobId, filter: $filter) {
      id
      startDateTime
      endDateTime
      position {
        name
      }
      staffings {
        id
        tender {
          id
          firstName
          lastName
          avatarURL
          ... on TenderForClient {
            status
          }
        }
      }
    }
  }
`;
