import React, { useContext } from 'react';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { FormContext, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Modal, ModalContext } from 'src/components/Modal';
import StaffingCard from 'src/components/StaffingCard/StaffingCard';
import { Position, StaffingData } from 'src/components/StaffingCard';
import { MyClientJob_myClientJob_shifts as Shift } from 'src/graphql/queries/__generated__/MyClientJob';
import { MAX_TENDERS_ALLOWED } from 'src/utils/constants';
import { useUpdateShiftInClientJobMutation } from 'src/graphql/mutations/UpdateShiftInClientJob';
import { TipType } from 'src/__generated__/globalTypes';
import { transformStartAndEndTimes } from 'src/utils/dates';
import Footer from './Footer';

const SHIFT_INDEX_IN_SCHEMA = 0;

interface Props {
  location: { lat?: number; lng?: number };
  timezone: string;
  shift: Shift;
}

interface ShiftFormValues extends Omit<StaffingData, 'attire'> {
  quantity: NonNullable<StaffingData['quantity']>;
  startDateTime: NonNullable<StaffingData['startDateTime']>;
  endDateTime: NonNullable<StaffingData['endDateTime']>;
  tipType: NonNullable<StaffingData['tipType']>;
  tipAmount: NonNullable<StaffingData['tipAmount']>;
  unpaidBreakMinutes: NonNullable<StaffingData['unpaidBreakMinutes']>;
}

interface FormValues {
  shifts: ShiftFormValues[];
}

const shiftValidationSchema = Yup.object({
  position: Yup.object().required(),
  unpaidBreakMinutes: Yup.number(),
  quantity: Yup.number().max(MAX_TENDERS_ALLOWED).required(),
  date: Yup.date().required(),
  endDateTime: Yup.date().required(),
  startDateTime: Yup.date().required(),
  tipType: Yup.string().when('position', (position: Position) =>
    position?.tipTypes?.length ? Yup.string().required() : Yup.string(),
  ),
  tipAmount: Yup.number().when('tipType', (tipType: string) =>
    tipType === 'INCLUDE_TIP' ? Yup.number().required() : Yup.number(),
  ),
});

const validationSchema = Yup.object({
  shifts: Yup.array().of(shiftValidationSchema),
});

const EditShiftModal: React.FC<Props> = ({ location, shift, timezone }) => {
  const modalContext = useContext(ModalContext);
  const [updateShiftInClientJob, { loading }] =
    useUpdateShiftInClientJobMutation(shift.id);
  const shiftToEdit = {
    unpaidBreakMinutes: shift.unpaidBreakMinutes || 0,
    tipType: shift.tipType || undefined,
    tipAmount: shift.tipAmount || undefined,
    quantity: shift.quantity,
    date: new Date(shift.startDateTime),
    startDateTime: new Date(shift.startDateTime),
    endDateTime: new Date(shift.endDateTime),
    position: shift.position,
  };
  const methods = useForm<FormValues>({
    validationSchema,
    defaultValues: {
      shifts: [shiftToEdit],
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    const [shiftInput] = formValues.shifts;
    const { startDateTime, endDateTime } = transformStartAndEndTimes({
      date: shiftInput.date,
      startDateTime: shiftInput.startDateTime,
      endDateTime: shiftInput.endDateTime,
    });

    await updateShiftInClientJob({
      variables: {
        shiftId: shift.id,
        input: {
          quantity: shiftInput.quantity,
          startDateTime: startDateTime
            ? zonedTimeToUtc(startDateTime, timezone)
            : startDateTime,
          endDateTime: endDateTime
            ? zonedTimeToUtc(endDateTime, timezone)
            : endDateTime,
          tipType: shiftInput.tipType && TipType[shiftInput.tipType],
          tipAmount: shiftInput.tipAmount || 0,
          unpaidBreakMinutes: shiftInput.unpaidBreakMinutes,
        },
      },
    });

    modalContext?.setIsModalOpen(false);
  };
  return (
    <Modal title="Edit Shift">
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <StaffingCard
            data={shiftToEdit}
            index={SHIFT_INDEX_IN_SCHEMA}
            isClient
            location={location}
            validationSchema={shiftValidationSchema}
            noMargin
            isPositionDisabled
            displayTitle={false}
          />
          <Footer disabled={loading} />
        </form>
      </FormContext>
    </Modal>
  );
};

export default EditShiftModal;
