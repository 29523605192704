import React, { FC } from 'react';
import logo from 'src/images/tend-logo.svg';
import {
  AppLogo,
  Container,
  ContentWrapper,
  LeftContainer,
  Link,
  RightContainer,
  SignAction,
  Slogan,
  Text,
} from './HomeTemplate.styled';

interface Props {
  signAction?: 'signin' | 'signup';
}

const SignUp: FC = () => (
  <SignAction>
    New to Tend?
    <Link data-cy="sign-up-link" to="/signup">
      Create an account
    </Link>
  </SignAction>
);

const SignIn: FC = () => (
  <SignAction>
    Do you have an account?
    <Link data-cy="sign-in-link" to="/signin">
      Sign in
    </Link>
  </SignAction>
);

const HomeTemplate: FC<Props> = ({ children, signAction }) => {
  return (
    <Container>
      <LeftContainer>
        <AppLogo src={logo} alt="tend-logo" />
        <Slogan>The premier marketplace for hospitality staffing</Slogan>
        <Text>Available at an affordable price</Text>
      </LeftContainer>
      <RightContainer>
        {signAction && (signAction === 'signin' ? <SignIn /> : <SignUp />)}
        <ContentWrapper>{children}</ContentWrapper>
      </RightContainer>
    </Container>
  );
};

export default HomeTemplate;
