import styled from 'styled-components';
import { Snackbar as MaterialSnackbar } from '@material/react-snackbar';
import { MD_BREAK_POINT } from 'src/utils/constants';

export const ClearBackground = styled.div`
  background-color: var(--color-background-surface);
`;

export const WidthLimiter = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  margin: auto;
  p:first-child {
    margin-bottom: 4px;
  }
`;

export const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid var(--color-support-lines);
  margin: 0;
`;

export const ListContainer = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  margin: auto;
  padding: 24px 8px 32px;
`;

export const SpinnerContainer = styled.div`
  margin: 32px 0;
`;

export const Snackbar = styled(MaterialSnackbar)`
  top: 80px;
  bottom: unset;
  .mdc-snackbar__actions {
    max-width: 36px;
    .mdc-snackbar__action {
      min-width: 36px;
      color: var(--color-ink-clear);
      font-size: 22px;
      :after,
      :before {
        background-color: var(--color-ink-clear);
      }
    }
  }
  @media (max-width: ${MD_BREAK_POINT}) {
    top: unset;
    bottom: 0;
  }
`;

export const StatusMessage = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  margin: auto;
  padding: 20px 16px;
`;

export const TenderListContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  // negative margin because children can't have individual margins
  margin: 32px -10px 0;
`;
