const mergeDateWithTime = (
  date?: Date | null,
  time?: Date | null,
): Date | null | undefined => {
  if (date && time) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
    );
  }
  return time || date;
};

type DateObject = {
  date?: Date | null;
  startDateTime?: Date | null;
  endDateTime?: Date | null;
};

export const transformStartAndEndTimes = ({
  date,
  startDateTime,
  endDateTime,
}: DateObject): DateObject => {
  startDateTime = mergeDateWithTime(date, startDateTime);
  endDateTime = mergeDateWithTime(date, endDateTime);
  if (startDateTime && endDateTime && startDateTime >= endDateTime) {
    endDateTime?.setDate(endDateTime?.getDate() + 1);
  }
  return { date, startDateTime, endDateTime };
};
