import React from 'react';
import HomeTemplate from 'src/components/HomeTemplate';
import { Title } from 'src/components/FormComponents';
import useQueryParams from 'src/hooks/QueryParams';
import PasswordResetEmailForm from 'src/components/PasswordResetEmailForm';
import PasswordResetForm from 'src/components/PasswordResetForm';

const PasswordResetView: React.FC = () => {
  const queryParams = useQueryParams();
  const temporaryToken = queryParams.get('token');

  return (
    <HomeTemplate signAction="signin">
      <Title>Reset your password</Title>
      {temporaryToken ? (
        <PasswordResetForm token={temporaryToken} />
      ) : (
        <PasswordResetEmailForm />
      )}
    </HomeTemplate>
  );
};

export default PasswordResetView;
