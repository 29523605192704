import React, { useContext } from 'react';
import { Button } from '@material/react-button';
import styled from 'styled-components';

import { ModalContext } from 'src/components/Modal';

const FooterContainer = styled.div`
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 12px;
  text-transform: none;
  &.mdc-button:not(:disabled) {
    color: ${({ theme }) => theme.color.inkDark};
    border-color: ${({ theme }) => theme.color.inkDark};
  }
`;

const ConfirmButton = styled(Button)`
  text-transform: none;
  box-shadow: none;
`;

type Props = {
  disabled?: boolean;
};

const Footer: React.FC<Props> = ({ disabled }) => {
  const modalContext = useContext(ModalContext);
  return (
    <FooterContainer>
      <CancelButton
        outlined
        type="button"
        onClick={(e) => {
          e.preventDefault();
          modalContext?.setIsModalOpen(false);
        }}
        disabled={disabled}
      >
        Cancel
      </CancelButton>
      <ConfirmButton raised type="submit" disabled={disabled}>
        Save
      </ConfirmButton>
    </FooterContainer>
  );
};

export default Footer;
