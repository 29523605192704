import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialIcon from '@material/react-material-icon';
import { format } from 'date-fns';
import ReactTooltip from 'react-tooltip';

import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import { Factory as FavoriteTenderAsClientMutationFactory } from 'src/graphql/mutations/FavoriteTenderAsClient';
import { Factory as BlockTenderAsClientMutationFactory } from 'src/graphql/mutations/BlockTenderAsClient';
import { Factory as MarkTenderAsLessPreferredMutationFactory } from 'src/graphql/mutations/MarkTenderAsLessPreferredAsClient';
import { Factory as ResetTenderStatusAsClientMutationFactory } from 'src/graphql/mutations/ResetTenderStatusAsClient';
import {
  GetShiftsForMyJob_getShiftsForMyJob as ShiftForClient,
  GetShiftsForMyJob_getShiftsForMyJob_staffings_tender as TenderForClient,
} from 'src/graphql/queries/__generated__/GetShiftsForMyJob';
import { GetMyPastTenders_getMyPastTenders as TenderWithLastShift } from 'src/graphql/queries/__generated__/GetMyPastTenders';
import { ModalContext } from 'src/components/Modal';
import TenderAvatar from './TenderAvatar';
import {
  openAddToFavoritesModal,
  openBlockTenderModal,
  openDontLikeWorkModal,
  openRemoveRestrictionModal,
  openUnblockTenderModal,
  openUnfavoriteModal,
} from './getConfirmationModals';

import {
  BlackTextButton,
  ButtonContainer,
  CardContent,
  Container,
  DataContainer,
  DataLabel,
  DataValue,
  GreenBackgroundButton,
  GreyTextButton,
  GrowingContainer,
  RedTextButton,
  TenderName,
  TenderPosition,
} from './TenderCard.styled';

type Props = {
  tender: TenderForClient | TenderWithLastShift;
  shift?: ShiftForClient;
  staffingId?: string;
  onClientTenderStatusChange?: (newStatus: ClientTenderStatus | null) => void;
};

type FooterProps = {
  tender: TenderForClient | TenderWithLastShift;
  staffingId?: string;
  status: ClientTenderStatus | null;
  onStatusChange: (newStatus: ClientTenderStatus | null) => void;
};

const StyledTooltip: React.FC<{ id: string }> = ({ id }) => {
  return (
    <ReactTooltip
      className="tend-tooltip"
      id={id}
      place="bottom"
      backgroundColor="#333333"
      textColor="white"
      multiline
    />
  );
};

const Footer: React.FC<FooterProps> = ({
  tender,
  status,
  staffingId = '',
  onStatusChange,
}) => {
  const history = useHistory();
  const modalContext = useContext(ModalContext);
  const [radioStatus, setRadioStatus] = useState<ClientTenderStatus>();
  if (!modalContext) {
    return null;
  }
  const displayActionButton = !!staffingId;

  const handleRadioChange = (newRadioStatus?: ClientTenderStatus) => {
    setRadioStatus(newRadioStatus);
    modalContext.setIsModalOpen(false);
    newRadioStatus &&
      openDontLikeWorkModal(
        updateTenderClient,
        modalContext,
        tenderName,
        handleRadioChange,
        newRadioStatus,
      );
  };

  const updateTenderClient = async (
    newStatus: ClientTenderStatus | null,
    snackMessage: string,
  ) => {
    switch (newStatus) {
      case ClientTenderStatus.BLOCKED:
        await blockTender();
        break;
      case ClientTenderStatus.FAVORITE:
        await favoriteTender();
        break;
      case ClientTenderStatus.LESS_PREFERRED:
        await markTenderAsLessPreferred();
        break;
      default:
        await resetTenderStatus();
    }
    history.push(history.location.pathname, { snackMessage });
    onStatusChange(newStatus);
  };
  const [favoriteTender] = FavoriteTenderAsClientMutationFactory(
    tender.id,
    staffingId,
  );
  const [blockTender] = BlockTenderAsClientMutationFactory(
    tender.id,
    staffingId,
  );
  const [markTenderAsLessPreferred] = MarkTenderAsLessPreferredMutationFactory(
    tender.id,
    staffingId,
  );
  const [resetTenderStatus] = ResetTenderStatusAsClientMutationFactory(
    tender.id,
  );
  const jobId =
    'latestShift' in tender ? tender.latestShift?.jobId : staffingId;
  const SeeLastJobButton = () => (
    <GreyTextButton
      onClick={() => {
        history.push(`bookings/${jobId}/staff`);
      }}
    >
      See Last Job
    </GreyTextButton>
  );
  const tenderName = `${tender.firstName} ${tender.lastName}`;
  switch (status) {
    case ClientTenderStatus.FAVORITE:
      return (
        <ButtonContainer>
          <RedTextButton
            onClick={() => {
              openUnfavoriteModal(updateTenderClient, modalContext, tenderName);
            }}
          >
            Remove from Favorites
          </RedTextButton>
          {displayActionButton ? (
            <>
              <GreyTextButton
                onClick={() => {
                  openDontLikeWorkModal(
                    updateTenderClient,
                    modalContext,
                    tenderName,
                    handleRadioChange,
                    radioStatus,
                  );
                }}
                data-for={tender.id}
                data-tip="Reduce preference on<br />
                   Tenders so you don't see<br />
                   them working with you<br />
                   as often"
              >
                I did not like the work
              </GreyTextButton>
              <StyledTooltip id={tender.id} />
            </>
          ) : (
            <SeeLastJobButton />
          )}
        </ButtonContainer>
      );
    case ClientTenderStatus.BLOCKED:
      return (
        <ButtonContainer>
          <BlackTextButton
            onClick={() => {
              openUnblockTenderModal(
                updateTenderClient,
                modalContext,
                tenderName,
              );
            }}
          >
            Unblock Tender
          </BlackTextButton>
          {!displayActionButton && <SeeLastJobButton />}
        </ButtonContainer>
      );
    case ClientTenderStatus.LESS_PREFERRED:
      return (
        <ButtonContainer>
          <BlackTextButton
            onClick={() => {
              openRemoveRestrictionModal(
                updateTenderClient,
                modalContext,
                tenderName,
              );
            }}
          >
            Remove Restriction
          </BlackTextButton>
          {displayActionButton ? (
            <>
              <GreyTextButton
                onClick={() => {
                  openBlockTenderModal(
                    updateTenderClient,
                    modalContext,
                    tenderName,
                  );
                }}
                data-for={tender.id}
                data-tip="Blocking Tenders prevents<br />
                 them from applying and<br />
                 working your Jobs"
              >
                Block Tender
              </GreyTextButton>
              <StyledTooltip id={tender.id} />
            </>
          ) : (
            <SeeLastJobButton />
          )}
        </ButtonContainer>
      );
    default:
      return (
        <ButtonContainer>
          {displayActionButton ? (
            <>
              <GreenBackgroundButton
                outlined
                icon={<MaterialIcon icon="favorite_border" />}
                onClick={() => {
                  openAddToFavoritesModal(
                    updateTenderClient,
                    modalContext,
                    tenderName,
                  );
                }}
                data-for={tender.id}
                data-tip="Favorite Tenders have more<br />
                 probabilities to be staffed in<br />
                 your future Bookings"
              >
                Add to Favorites
              </GreenBackgroundButton>
              <GreyTextButton
                onClick={() => {
                  openDontLikeWorkModal(
                    updateTenderClient,
                    modalContext,
                    tenderName,
                    handleRadioChange,
                    radioStatus,
                  );
                }}
                data-for={tender.id}
                data-tip="Reduce preference on<br />
                 Tenders so you don't see<br />
                 them working with you<br />
                 as often"
              >
                I did not like the work
              </GreyTextButton>
              <StyledTooltip id={tender.id} />
            </>
          ) : (
            <SeeLastJobButton />
          )}
        </ButtonContainer>
      );
  }
};

const TenderCard: React.FC<Props> = ({
  tender,
  shift,
  staffingId,
  onClientTenderStatusChange,
}) => {
  const [status, setStatus] = useState<ClientTenderStatus | null>(
    tender.status,
  );

  const formatDateTime = (date: string): string => {
    return format(new Date(date), 'LLL Lo h:mmaaaa').replace(/\./g, '');
  };

  const formatDate = (date: string): string => {
    return format(new Date(date), 'MMM d, yyyy').replace(/\./g, '');
  };

  return (
    <Container>
      <CardContent>
        <TenderAvatar
          avatarURL={tender.avatarURL}
          status={status}
          firstName={tender.firstName}
          lastName={tender.lastName}
        />

        <GrowingContainer>
          <TenderName>{`${tender.firstName} ${tender.lastName}`}</TenderName>

          {shift && (
            <>
              <TenderPosition>{shift.position.name}</TenderPosition>
              <DataContainer>
                <DataLabel>Start Date</DataLabel>
                <DataValue>{formatDateTime(shift.startDateTime)}</DataValue>
              </DataContainer>
              <DataContainer>
                <DataLabel>End Date</DataLabel>
                <DataValue>{formatDateTime(shift.endDateTime)}</DataValue>
              </DataContainer>
            </>
          )}
        </GrowingContainer>

        <GrowingContainer>
          {'positions' in tender && (
            <>
              <TenderPosition>
                {tender.positions?.map((position) => position.name).join(', ')}
              </TenderPosition>
              <DataContainer>
                <DataLabel>Jobs with you</DataLabel>
                <DataValue>{tender.completedJobs}</DataValue>
              </DataContainer>
              <DataContainer>
                <DataLabel>Last Job Date</DataLabel>
                <DataValue>
                  {tender.latestShift &&
                    formatDate(tender.latestShift?.endDateTime)}
                </DataValue>
              </DataContainer>
            </>
          )}
        </GrowingContainer>

        <Footer
          tender={tender}
          staffingId={staffingId}
          status={status}
          onStatusChange={(newStatus) => {
            onClientTenderStatusChange?.(newStatus);
            setStatus(newStatus);
          }}
        />
      </CardContent>
    </Container>
  );
};

export default TenderCard;
