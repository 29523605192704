import React, { useState } from 'react';
import * as moment from 'moment';
import 'react-dates/initialize';
import styled from 'styled-components';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';

const StyledWrapper = styled.div<{ focused: boolean; isValid?: boolean }>`
  width: 100%;
  line-height: 24px;
  .SingleDatePicker {
    display: block;
  }
  .CalendarDay__selected {
    background: ${({ theme }) => theme.color.primary};
    :hover {
      background-color: ${({ theme }) => theme.color.supportLines};
    }
  }

  .DateInput {
    top: 2px;
    background: transparent;
    @media only screen and (max-width: ${({ theme }) =>
        theme.layout.maxWidth}) {
      width: 118px;
    }
  }

  .DateInput_input__focused {
    border-bottom: none;
  }

  .DateInput_input {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    box-sizing: border-box;
    background: transparent;
    @media only screen and (max-width: ${({ theme }) =>
        theme.layout.maxWidth}) {
      padding: 11px 0px 9px;
    }
  }

  .SingleDatePickerInput {
    height: 56px;
    background: ${({ theme }) => theme.color.backgroundApp};

    button {
      @media only screen and (max-width: ${({ theme }) =>
          theme.layout.maxWidth}) {
        padding: 1px 7px 2px;
        margin: 0;
      }
    }
  }

  .SingleDatePickerInput__showClearDate {
    @media only screen and (max-width: ${({ theme }) =>
        theme.layout.maxWidth}) {
      padding: 0;
    }
  }

  .SingleDatePickerInput__withBorder {
    border-color: ${({ focused, isValid, theme }) =>
      isValid
        ? focused
          ? theme.color.primary
          : 'rgba(0, 0, 0, 0.4)'
        : theme.color.statusDestructive};
    box-shadow: ${({ focused, isValid, theme }) =>
      focused
        ? isValid
          ? `inset 0 0 0 1px ${theme.color.primary}`
          : `inset 0 0 0 1px ${theme.color.statusDestructive}`
        : 'none'};
    border-radius: 4px;
    box-sizing: border-box;
    :hover {
      border-color: ${({ focused, isValid }) =>
        !focused && isValid && 'rgba(0, 0, 0, 0.87)'};
    }
  }
  .SingleDatePickerInput {
    display: block;
  }
  .SingleDatePickerInput_clearDate {
    font-size: 6px;
    :hover {
      background: none;
    }
  }
`;

interface Props
  extends Omit<
    SingleDatePickerShape,
    'focused' | 'onFocusChange' | 'date' | 'onDateChange'
  > {
  defaultValue?: moment.Moment | null;
  isValid?: boolean;
  dateValue?: string;
  name?: string;
  onBlur?: () => void;
  onDateChange?: (date: Date | null) => void;
  'data-cy'?: string;
}

const DatePicker: React.FC<Props> = ({
  isValid = true,
  defaultValue,
  onDateChange,
  name,
  onBlur,
  dateValue,
  ...props
}) => {
  const [date, setDate] = useState<moment.Moment | null>();
  const [dateFocused, setDateFocused] = useState<boolean | null>(false);
  const handleOnChange = (newDate: moment.Moment | null) => {
    setDate(newDate);
    if (onDateChange) {
      const newDateAsDate = newDate ? newDate.toDate() : null;
      onDateChange(newDateAsDate);
    }
  };
  const isFocused = dateFocused || false;
  const defaultDate = defaultValue || null;
  const value = date === undefined ? defaultDate : date;
  return (
    <StyledWrapper
      focused={isFocused}
      isValid={isValid}
      data-cy={props['data-cy']}
    >
      <SingleDatePicker
        focused={isFocused}
        onFocusChange={({ focused }) => setDateFocused(focused)}
        readOnly
        showClearDate
        showDefaultInputIcon
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        date={value}
        onDateChange={handleOnChange}
        {...props}
      />
    </StyledWrapper>
  );
};

export default DatePicker;
