import { DefaultTheme, css } from 'styled-components';
import {
  LAYOUT_MAX_WIDTH,
  LG_BREAK_POINT,
  MD_BREAK_POINT,
  SM_BREAK_POINT,
  XL_BREAK_POINT,
} from 'src/utils/constants';

const theme: DefaultTheme = {
  color: {
    brandTend: '#045445',
    brand50: '#548A80',
    primary: '#045445',
    primaryActive: '#2C6C53',
    primaryLight: '#EAF4EF',
    secondary: '#394E6F',
    inkDark: '#333333',
    inkClear: '#FFFFFF',
    inkNotasdark: '#6D727C',
    inkLink: '#353AC5',
    inkPrimary: '#149262',
    backgroundApp: '#F7F9FB',
    backgroundSurface: '#FFFFFF',
    statusDestructive: '#EB5757',
    statusDestructiveLight: '#fbdddd',
    statusPositive: '#149262',
    statusPositiveLight: '#E7EEED',
    statusWarning: '#FF9C00',
    statusWarningLight: '#FFE3BB',
    statusInfo: '#18A0FB',
    supportLines: '#EEE',
    supportLinesDarker: '#CACBCC',
  },
  text: {
    preset1: css`
      font-size: 40px;
      line-height: 48px;
    `,
    preset2: css`
      font-size: 30px;
      line-height: 36px;
    `,
    preset3: css`
      font-size: 22px;
      line-height: 26px;
    `,
    preset4: css`
      font-size: 18px;
      line-height: 24px;
    `,
    preset5: css`
      font-size: 16px;
      line-height: 20px;
    `,
    preset5P: css`
      font-size: 16px;
      line-height: 24px;
    `,
    preset6: css`
      font-size: 14px;
      line-height: 16px;
    `,
    preset6P: css`
      font-size: 14px;
      line-height: 22px;
    `,
    preset7: css`
      font-size: 12px;
      line-height: 16px;
    `,
    preset7P: css`
      font-size: 12px;
      line-height: 18px;
    `,
    semiBold: 500,
  },
  breakpoint: {
    small: SM_BREAK_POINT,
    medium: MD_BREAK_POINT,
    large: LG_BREAK_POINT,
    xlarge: XL_BREAK_POINT,
  },
  layout: {
    maxWidth: LAYOUT_MAX_WIDTH,
  },
};

export default theme;
