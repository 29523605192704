import React, { FC, HTMLAttributes, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Select from 'src/components/Select';
import { formatMoney } from 'src/utils/money';
import { GetPositionRatesForGuest_getPositionRatesForGuest as GuestRatedPosition } from './__generated__/GetPositionRatesForGuest';
import { GetPositionRatesForClient_getPositionRatesForClient as ClientRatedPosition } from './__generated__/GetPositionRatesForClient';

type RatedPosition = GuestRatedPosition & ClientRatedPosition;

interface Props {
  onChange?: (position: { name: string; id: string; rate: number }) => void;
  defaultValue?: string;
  isValid?: boolean;
  isClient?: boolean;
  placeholder?: string;
  location: {
    lat?: number;
    lng?: number;
  };
  isPositionDisabled?: boolean;
}

const GET_CLIENT_POSITIONS = gql`
  query GetPositionRatesForClient($input: CoordsInput!) {
    getPositionRatesForClient(input: $input) {
      name
      id
      rate
      tipTypes
    }
  }
`;

const GET_GUEST_POSITIONS = gql`
  query GetPositionRatesForGuest($input: CoordsInput!) {
    getPositionRatesForGuest(input: $input) {
      name
      id
      rate
      tipTypes
    }
  }
`;

const PositionsSelect: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
  onChange,
  defaultValue,
  location,
  isClient,
  isPositionDisabled = false,
  ...props
}) => {
  const [selected, setSelected] = useState<null | string>('');
  const value = selected || defaultValue || '';
  const query = isClient ? GET_CLIENT_POSITIONS : GET_GUEST_POSITIONS;
  const resultQuery = isClient
    ? 'getPositionRatesForClient'
    : 'getPositionRatesForGuest';
  const { lat, lng } = location;
  const { loading, error, data } = useQuery(query, {
    variables: { input: { lat, lng } },
    fetchPolicy: 'network-only',
  });
  const positionsMap: { [key: string]: RatedPosition } = {};
  const handleChange = (selectedValue: string) => {
    setSelected(selectedValue);
    if (onChange) {
      onChange(positionsMap[selectedValue]);
    }
  };
  if (error) {
    return null;
  }
  let options = [];
  if (!loading) {
    options = data[resultQuery].map((position: RatedPosition) => {
      positionsMap[position.id] = position;
      return {
        value: position.id,
        label: `${position.name} - ${formatMoney(position.rate)}`,
      };
    });
  }
  return (
    <Select
      data-cy="positionSelect"
      options={options}
      onChange={handleChange}
      isDisabled={isPositionDisabled}
      {...props}
      value={value}
    />
  );
};

export default PositionsSelect;
