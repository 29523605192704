import React from 'react';
import {
  StatusMessage,
  WidthLimiter,
} from 'src/components/BookingStyles/BookingStyles.styled';
import styled from 'styled-components';

import { MD_BREAK_POINT } from 'src/utils/constants';

const Container = styled.div`
  @media (max-width: ${MD_BREAK_POINT}) {
    height: max-content;
    margin-top: 32px;
  }
`;

const Content = styled.div`
  text-align: center;
  height: 200px;
  margin: auto auto;
  color: var(--color-ink-notasdark);
  @media (max-width: ${MD_BREAK_POINT}) {
    padding: 32px;
  }
`;

const Title = styled.div`
  margin-top: 44px;
  font-size: 22px;
  font-weight: 500;
  color: var(--color-ink-notasdark);
`;

const Subtitle = styled(Title)`
  margin-top: 16px;
  font-size: 16px;
`;

const TenderList = styled.div`
  margin-top: 32px;
  display: inline-block;
  text-align: left;
`;

const EmptyTenderCard = styled.div`
  width: 270px;
  height: 300px;
  display: inline-block;
  text-align: center;
  margin: 10px;
  background-color: var(--color-background-surface);
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);

  svg {
    width: 230px;
    margin-top: 30px;
  }
`;

type Props = {
  title: string;
  subtitle: string;
};

const EmptyTenderCards: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <TenderList>
          {[1, 2, 3].map((key) => {
            return (
              <EmptyTenderCard key={key}>
                <svg
                  width="259"
                  height="184"
                  viewBox="0 0 259 184"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="129.5"
                    cy="32"
                    r="31.5"
                    fill="#EDEDED"
                    stroke="#E7EEED"
                  />
                  <rect
                    x="71.5"
                    y="76"
                    width="116"
                    height="8"
                    rx="4"
                    fill="#EEEEEE"
                  />
                  <rect y="125" width="68" height="8" rx="4" fill="#EEEEEE" />
                  <rect
                    x="140"
                    y="124"
                    width="119"
                    height="8"
                    rx="4"
                    fill="#EEEEEE"
                  />
                  <rect y="162" width="68" height="8" rx="4" fill="#EEEEEE" />
                  <rect
                    x="140"
                    y="161"
                    width="119"
                    height="8"
                    rx="4"
                    fill="#EEEEEE"
                  />
                  <rect y="183" width="259" height="1" fill="#EEEEEE" />
                </svg>
              </EmptyTenderCard>
            );
          })}
        </TenderList>
      </Content>
    </Container>
  );
};

const EmptyState: React.FC<{
  search: string | undefined;
  title: string;
  subtitle: string;
}> = ({ search, title, subtitle }) => {
  if (!search) {
    return (
      <WidthLimiter>
        <EmptyTenderCards title={title} subtitle={subtitle} />
      </WidthLimiter>
    );
  } else if (search) {
    return (
      <StatusMessage>
        No Tenders named <b>{search}</b> found. Please try a different search.
      </StatusMessage>
    );
  }
  return <StatusMessage>No Tender found.</StatusMessage>;
};

export default EmptyState;
