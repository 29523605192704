import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Controller,
  ErrorMessage as FormErrorMessage,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { authContext } from 'src/contexts/AuthContext';
import Button from 'src/components/Button';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import PasswordInput from 'src/components/PasswordInput';
import TextField from 'src/components/TextField';
import UnverifiedUserSection from 'src/components/UnverifiedUserSection';
import { Title } from 'src/components/FormComponents';
import { signIn } from 'src/restClient';

const Link = styled(NavLink)`
  color: var(--color-ink-notasdark);
  margin-bottom: 8px;
  font-size: 14px;
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

const LineDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0 24px;
  background-color: var(--color-support-lines);
`;

type FormValues = {
  email: string;
  password: string;
};

const SigninSchema = Yup.object().shape({
  password: Yup.string().required(' '),
  email: Yup.string().email('Invalid email').required(' '),
});

const SignInForm: React.FC = () => {
  const [submitError, setSubmitError] = useState(false);
  const [email, setEmail] = useState<string>();
  const [hasAuthenticationError, setHasAuthenticationError] = useState(false);
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    mode: 'onBlur',
    validationSchema: SigninSchema,
  });
  const auth = useContext(authContext);
  const onSubmit = async (values: FormValues) => {
    setSubmitError(false);
    setHasAuthenticationError(false);
    const response = await signIn(values.email, values.password);
    if (response.ok) {
      const content = await response.json();
      auth.setAuthToken(content.authToken);
    } else if (response.status === 403) {
      setEmail(values.email);
      setHasAuthenticationError(true);
    } else {
      setSubmitError(true);
    }
  };

  return hasAuthenticationError && email ? (
    <UnverifiedUserSection email={email} />
  ) : (
    <>
      <Title>Sign In</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label required>Email</Label>
        <TextField>
          <Controller
            as={
              <Input
                data-cy="email-input"
                data-testid="email-input"
                type="email"
                isValid={!errors.email}
              />
            }
            control={control}
            name="email"
          />
        </TextField>
        <FormErrorMessage errors={errors} name="email" as={<ErrorMessage />} />
        <Row>
          <Label required>Password</Label>
          <Link to="/password_reset">Forgot password?</Link>
        </Row>
        <Controller
          as={
            <PasswordInput
              data-cy="password-input"
              label=" "
              name="password"
              isValid={!errors.password}
            />
          }
          control={control}
          name="password"
        />
        {submitError && <ErrorMessage>Wrong email or password</ErrorMessage>}
        <ButtonWrapper>
          <Button
            data-cy="submit-button"
            data-testid="submit-button"
            type="submit"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
        </ButtonWrapper>
      </form>
      <LineDivider />
    </>
  );
};

export default SignInForm;
