import React, { FC, useEffect, useState } from 'react';
import {
  Controller,
  FieldError,
  ErrorMessage as FormErrorMessage,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import { NativeRadioControl } from '@material/react-radio';
import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Address } from '@hiretend/google-places';

import useParking from 'src/hooks/Parking';
import AddressLookup from 'src/components/AddressLookup';
import Checkbox from 'src/components/Checkbox';
import { ErrorMessage } from 'src/components/StatusMessages';
import PastJobDescription from 'src/components/PastDescriptions';
import PastJobLocations from 'src/components/PastLocations';
import { MyMostRecentJobsByVenue_myMostRecentJobsByVenue as myMostRecentJobsType } from 'src/components/PastLocations/__generated__/MyMostRecentJobsByVenue';
import Label from 'src/components/Label';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import TextField from 'src/components/TextField';
import {
  BookingFormContent,
  RadioGroup,
  Separator,
  Title,
} from 'src/components/FormComponents';
import objectIsEmpty from 'src/utils/objectIsEmpty';
import { VenueType } from 'src/__generated__/globalTypes';
import { EVENTS, track } from 'src/analytics';

export type FormData = {
  job?: {
    name?: string;
    description?: string;
    mealProvided?: boolean;
    useBackDoor?: boolean;
  };
  venue?: {
    id?: string | null;
    type?: VenueType | undefined;
    name?: string;
    address?: Address;
    otherInstructions?: string;
    parkingType?: string;
    parkingName?: string;
    parkingSubType?: string;
    parkingInstructions?: string;
  };
  locationContact?: {
    name?: string;
    phoneNumber?: string;
  };
};

type FormErrors = {
  job?: {
    name?: FieldError;
    parking?: FieldError;
  };
  venue?: {
    name?: FieldError;
    address?: FieldError;
    parkingType?: FieldError;
    parkingSubType?: FieldError;
  };
  locationContact?: {
    name?: FieldError;
    phoneNumber?: FieldError;
  };
};

interface Props {
  data: FormData;
  formButtons: JSX.Element;
  onSubmit: (data: FormData) => void;
  isClient?: boolean;
}

const TextFieldVisible = styled(TextField)<{ display: string }>`
  display: ${({ display }) => display};
`;

const validationSchema = Yup.object().shape({
  job: Yup.object().shape({
    name: Yup.string().required(),
  }),
  venue: Yup.object().shape({
    name: Yup.string().when('type', {
      is: 'COMMERCIAL',
      then: Yup.string().required(),
    }),
    address: Yup.object().required(),
    parkingType: Yup.string().required('* Choose a parking option.'),
  }),
  locationContact: Yup.object().shape({
    name: Yup.string().required(),
    phoneNumber: Yup.string()
      .test('phoneNumber', 'Not a valid phone number', (value) => {
        if (!value) {
          return true;
        }
        return parsePhoneNumberFromString(value, 'US')?.isValid() || false;
      })
      .notRequired(),
  }),
});

const initialValues: FormData = {
  job: {
    mealProvided: false,
    useBackDoor: false,
  },
  venue: {
    type: VenueType.RESIDENTIAL,
  },
};

const BookingFormJob: FC<Props> = ({
  data,
  formButtons,
  onSubmit,
  isClient,
}) => {
  const values = !objectIsEmpty(data) ? data : initialValues;
  const { parking, parkingType, updateParking, parkingOptions } = useParking(
    values.venue?.parkingType,
  );
  const [venueType, setVenueType] = useState<VenueType | undefined>(
    values.venue?.type,
  );
  const [initialAddress, setInitialAddress] = useState(values.venue?.address);

  const {
    control,
    errors,
    handleSubmit: RHFHandleSubmit,
    setValue,
    register,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: values,
    validationSchema,
  });
  register('venue.id');
  register('venue.parkingType');

  useEffect(() => {
    setValue('venue.parkingType', parkingType);
  }, [parkingType, setValue]);

  const setVenueId = (value?: string): void => {
    setValue('venue.id', value);
    RHFHandleSubmit(onSubmit);
  };
  const handlePastLocationClick = (selectedData: myMostRecentJobsType) => {
    const { __typename, ...address } = selectedData.venue?.address || {};
    setVenueType(selectedData.venue?.type as VenueType | undefined);
    updateParking(selectedData.venue?.parking.value);
    setInitialAddress(selectedData.venue?.address);
    setValue('venue.type', selectedData.venue?.type);
    setValue('locationContact.name', selectedData.locationContact?.name);
    setValue(
      'locationContact.phoneNumber',
      selectedData.locationContact?.phoneNumber || undefined,
    );
    setValue('job.mealProvided', selectedData?.mealProvided);
    setValue('job.useBackDoor', selectedData?.useBackDoor);
    setValue('venue.address', address);
    setValue('venue.name', selectedData.venue?.name || undefined);
    setValue(
      'venue.otherInstructions',
      selectedData.venue?.otherInstructions || undefined,
    );
    setValue(
      'venue.parkingInstructions',
      selectedData.venue?.parkingInstructions || undefined,
    );
    setVenueId(selectedData.venue?.id);
  };
  const handlePastDescriptionClick = (newDescription: string) => {
    setValue('job.description', newDescription);
  };
  // Types for nested errors not supported yet in react-hook-form hence the cast
  const formErrors: FormErrors = errors as unknown as FormErrors;

  const handleSubmit = (formData: FormData) => {
    onSubmit(formData);
    track(EVENTS.NEW_BOOKING_NAME_AND_LOCATION_INFO_SUBMITTED);
  };

  useEffect(() => {
    track(EVENTS.STARTED_BOOKING_FORM);
  }, []);

  return (
    <form onSubmit={RHFHandleSubmit(handleSubmit)}>
      <BookingFormContent>
        <Title>Job</Title>
        <Label required>Job Name</Label>
        <TextField label="e.g. Mary's Birthday">
          <Controller
            as={<Input data-cy="jobName" isValid={!formErrors?.job?.name} />}
            control={control}
            name="job.name"
          />
        </TextField>
        <Label>Job Description</Label>
        {isClient && (
          <PastJobDescription onClick={handlePastDescriptionClick} />
        )}
        <TextField textarea label="e.g. Birthday party for 100 people">
          <Controller
            as={<Input data-cy="jobDescription" />}
            control={control}
            name="job.description"
          />
        </TextField>
        <Separator />
        <Title>Location</Title>
        {isClient && <PastJobLocations onClick={handlePastLocationClick} />}
        <Label required>Address</Label>
        <RadioGroup>
          <Controller
            as={
              <Radio label="Residential" key="residential">
                <NativeRadioControl
                  value={VenueType.RESIDENTIAL}
                  id="venue.type.residential"
                  data-cy="venueTypeResidential"
                  onChange={() => {
                    setVenueId();
                    setVenueType(VenueType.RESIDENTIAL);
                  }}
                  checked={venueType === VenueType.RESIDENTIAL}
                />
              </Radio>
            }
            control={control}
            name="venue.type"
          />
          <Controller
            as={
              <Radio label="Commercial" key="commercial">
                <NativeRadioControl
                  value={VenueType.COMMERCIAL}
                  id="venue.type.commercial"
                  data-cy="venueTypeCommercial"
                  onChange={() => {
                    setVenueId();
                    setVenueType(VenueType.COMMERCIAL);
                  }}
                  checked={venueType === VenueType.COMMERCIAL}
                />
              </Radio>
            }
            control={control}
            name="venue.type"
          />
        </RadioGroup>
        <TextFieldVisible
          display={venueType === VenueType.COMMERCIAL ? '' : 'none'}
          label="Location name"
        >
          <Controller
            as={<Input isValid={!formErrors?.venue?.name} />}
            control={control}
            name="venue.name"
            data-cy="venueName"
            onChange={([event]) => {
              setVenueId();
              return event.target.value;
            }}
          />
        </TextFieldVisible>
        <Controller
          as={
            <AddressLookup
              onChange={(address: Address) =>
                setValue('venue.address', address)
              }
              initialAddress={initialAddress}
              isValid={!formErrors?.venue?.address}
            />
          }
          name="venue.address"
          control={control}
          onChange={([value]) => {
            setVenueId();
            return value;
          }}
        />
        <Label required>Parking</Label>
        <Select
          data-cy="parkingSubType"
          options={parkingOptions?.map((option: { name: string }) => ({
            label: option.name,
            value: option.name,
          }))}
          defaultValue={parking?.name}
          value={parking?.name}
          onChange={(value) => {
            setVenueId();
            updateParking(value);
          }}
        />
        <FormErrorMessage
          errors={formErrors}
          name="venue.parkingSubType"
          as={<ErrorMessage />}
        />
        <RadioGroup>
          {parking?.options &&
            parking.options.length > 1 &&
            parking.options.map((option) => {
              return (
                <div key={option.value}>
                  <Radio label={option.label} key={option.value}>
                    <NativeRadioControl
                      value={option.value}
                      id={`venue.parkingType.${option.value}`}
                      data-cy={`venue.parkingType.${option.value}`}
                      onChange={() => {
                        setVenueId();
                        updateParking(option.value);
                      }}
                      checked={parkingType === option.value}
                    />
                  </Radio>
                </div>
              );
            })}
        </RadioGroup>
        <FormErrorMessage
          errors={formErrors}
          name="venue.parkingType"
          as={<ErrorMessage />}
        />
        <Label>Parking Instructions</Label>
        <TextField
          textarea
          label="e.g. Parking available in front of the main building"
        >
          <Controller
            as={<Input />}
            control={control}
            name="venue.parkingInstructions"
            data-cy="venueParkingInstructions"
            onChange={([event]) => {
              setVenueId();
              return event.target.value;
            }}
          />
        </TextField>
        <Label>Arrival Instructions</Label>
        <Controller
          as={
            <Checkbox
              label="Staff meal provided"
              name="job.mealProvided"
              data-cy="jobMealProvided"
              nativeControlId="job.mealProvided"
            />
          }
          name="job.mealProvided"
          control={control}
        />
        <Controller
          as={
            <Checkbox
              label="Use back door"
              name="job.useBackDoor"
              data-cy="jobUseBackDoor"
              nativeControlId="job.useBackDoor"
            />
          }
          name="job.useBackDoor"
          control={control}
        />
        <Label>Other Location Details</Label>
        <TextField textarea label="e.g. Staff has to go through security check">
          <Controller
            as={<Input />}
            control={control}
            name="venue.otherInstructions"
            data-cy="venueOtherInstructions"
            onChange={([event]) => {
              setVenueId();
              return event.target.value;
            }}
          />
        </TextField>
        <Separator />
        <Title>Location Contact</Title>
        <Label required>Name</Label>
        <TextField label="Enter contact name">
          <Controller
            as={<Input isValid={!formErrors.locationContact?.name} />}
            control={control}
            name="locationContact.name"
            data-cy="contactName"
          />
        </TextField>
        <Label>Phone</Label>
        <TextField label="Enter contact phone number">
          <Controller
            as={<Input isValid={!formErrors.locationContact?.phoneNumber} />}
            control={control}
            name="locationContact.phoneNumber"
            data-cy="contactPhoneNumber"
          />
        </TextField>
        <Separator />
      </BookingFormContent>
      <ErrorMessage block visible={!objectIsEmpty(errors)} />
      {formButtons}
    </form>
  );
};

export default BookingFormJob;
