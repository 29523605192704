import React from 'react';
import styled from 'styled-components';
import { NativeRadioControl } from '@material/react-radio';

import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Radio from 'src/components/Radio';
import Text from 'src/components/Text';
import { ModalContextParams } from 'src/components/Modal/ModalContext';

type HandleConfirm = (
  newStatus: ClientTenderStatus | null,
  snackbarMessage: string,
) => void;
type ModalContext = ModalContextParams;

const RadioContainer = styled.div<{ checked: boolean }>`
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
  :not(:last-child) {
    margin-bottom: 8px;
  }
  ${({ checked, theme }) =>
    checked
      ? `
      background-color: ${theme.color.primaryLight};
      border: 1px solid ${theme.color.primary};
    `
      : `
      background-color: ${theme.color.backgroundApp};
      border: 1px solid ${theme.color.supportLinesDarker};
    `}
`;

export const openDontLikeWorkModal = (
  onConfirm: HandleConfirm,
  modalContext: ModalContext,
  tenderName: string,
  onRadioChange: (status?: ClientTenderStatus) => void,
  newStatus?: ClientTenderStatus,
) => {
  modalContext.setIsModalOpen(true);
  const snackbarMessage =
    newStatus === ClientTenderStatus.LESS_PREFERRED
      ? 'Reduced Tender preference successfully!'
      : 'Tender blocked successfully';
  modalContext.setModalContent(
    <ConfirmationDialog
      title="We're sorry to hear that, what would you like to do?"
      primaryButtonText="Confirm"
      handleConfirm={() => {
        newStatus && onConfirm(newStatus, snackbarMessage);
        onRadioChange(undefined);
      }}
      disabled={!newStatus}
    >
      <RadioContainer
        onClick={() => onRadioChange(ClientTenderStatus.LESS_PREFERRED)}
        checked={newStatus === ClientTenderStatus.LESS_PREFERRED}
      >
        <Radio
          label={`Only Staff ${tenderName} as a last resort on future events`}
        >
          <NativeRadioControl
            checked={newStatus === ClientTenderStatus.LESS_PREFERRED}
            onChange={() => onRadioChange(ClientTenderStatus.LESS_PREFERRED)}
          />
        </Radio>
      </RadioContainer>
      <RadioContainer
        checked={newStatus === ClientTenderStatus.BLOCKED}
        onClick={() => onRadioChange(ClientTenderStatus.BLOCKED)}
      >
        <Radio label={`Block ${tenderName} from any further events with us`}>
          <NativeRadioControl
            checked={newStatus === ClientTenderStatus.BLOCKED}
            onChange={() => onRadioChange(ClientTenderStatus.BLOCKED)}
          />
        </Radio>
      </RadioContainer>
    </ConfirmationDialog>,
  );
};

export const openAddToFavoritesModal = (
  onConfirm: HandleConfirm,
  modalContext: ModalContext,
  tenderName: string,
) => {
  modalContext.setIsModalOpen(true);
  modalContext.setModalContent(
    <ConfirmationDialog
      title={`Add ${tenderName} to Favorites?`}
      primaryButtonText="Add to Favorites"
      handleConfirm={() => {
        onConfirm(
          ClientTenderStatus.FAVORITE,
          'Tender added to Favorites successfully!',
        );
      }}
    >
      <Text preset="preset5">
        Adding people to favorites increases the chances of having them staffed
        in your next jobs
      </Text>
    </ConfirmationDialog>,
  );
};

export const openBlockTenderModal = (
  onConfirm: HandleConfirm,
  modalContext: ModalContext,
  tenderName: string,
) => {
  modalContext.setIsModalOpen(true);
  modalContext.setModalContent(
    <ConfirmationDialog
      title={`Are you sure you want to block ${tenderName}?`}
      primaryButtonText="Block Tender"
      dangerButton
      handleConfirm={() => {
        onConfirm(ClientTenderStatus.BLOCKED, 'Tender blocked successfully!');
      }}
    >
      <Text preset="preset5">
        This Tender won't be able to see and apply to future Jobs with you
      </Text>
    </ConfirmationDialog>,
  );
};

export const openRemoveRestrictionModal = (
  onConfirm: HandleConfirm,
  modalContext: ModalContext,
  tenderName: string,
) => {
  modalContext.setIsModalOpen(true);
  modalContext.setModalContent(
    <ConfirmationDialog
      title={`Are you sure you want to remove ${tenderName}'s restriction?`}
      primaryButtonText="Remove Restriction"
      dangerButton
      handleConfirm={() => {
        onConfirm(null, 'Restriction removed successfully!');
      }}
    >
      <Text preset="preset5">
        This tender will still be able to see and apply to future Jobs with you
      </Text>
    </ConfirmationDialog>,
  );
};

export const openUnblockTenderModal = (
  onConfirm: HandleConfirm,
  modalContext: ModalContext,
  tenderName: string,
) => {
  modalContext.setIsModalOpen(true);
  modalContext.setModalContent(
    <ConfirmationDialog
      title={`Unblock ${tenderName}?`}
      primaryButtonText="Unblock Tender"
      handleConfirm={() => {
        onConfirm(null, 'Tender unblocked successfully!');
      }}
    >
      <Text preset="preset5">
        We will start considering this Tender for your future Jobs
      </Text>
    </ConfirmationDialog>,
  );
};

export const openUnfavoriteModal = (
  onConfirm: HandleConfirm,
  modalContext: ModalContext,
  tenderName: string,
) => {
  modalContext.setIsModalOpen(true);
  modalContext.setModalContent(
    <ConfirmationDialog
      title={`Are you sure you want to remove ${tenderName} from your Favorites?`}
      primaryButtonText="Remove from Favorites"
      handleConfirm={() => {
        onConfirm(null, 'Tender removed from Favorites successfully!');
      }}
    >
      <Text preset="preset5">
        This Tender will still be able to see and apply to your Jobs but they
        won't have a preference over the other applicants
      </Text>
    </ConfirmationDialog>,
  );
};
