import React, { Children, cloneElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Separator = styled.div<{ touched?: boolean }>`
  height: 3px;
  background-color: ${({ touched }) =>
    touched ? 'var(--color-primary)' : 'var(--color-ink-notasdark)'};
  flex-grow: 1;
  border-radius: 2px;
  margin-bottom: 35px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

interface StepsProps {
  current: number;
  onChange: (current: number) => void;
}

const Steps: React.FC<StepsProps> = (props) => {
  const filteredChildren = Children.toArray(props.children).filter((c) => !!c);
  const last = filteredChildren.length;
  return (
    <Container>
      {Children.map(filteredChildren, (child, index) => {
        const stepNumber = index + 1;
        const touched = props.current > stepNumber;
        const childProps = {
          current: props.current,
          stepNumber,
          onClick: props.onChange,
          touched,
        };
        if (React.isValidElement(child)) {
          return (
            <>
              {cloneElement(child, childProps)}
              {last !== stepNumber && <Separator touched={touched} />}
            </>
          );
        }
        return null;
      })}
    </Container>
  );
};

export default Steps;
