import React, { useContext, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

import { Modal, ModalContext } from 'src/components/Modal';
import StaffingCard from 'src/components/StaffingCard/StaffingCard';
import { Position, StaffingData } from 'src/components/StaffingCard';
import TenderInstructionsInput, {
  FormData as TenderInstructionsFormData,
} from 'src/components/TenderInstructionsInput';
import { useAddShiftToClientJobMutation } from 'src/graphql/mutations/AddShiftToClientJob';
import { TipType } from 'src/__generated__/globalTypes';
import { MAX_TENDERS_ALLOWED } from 'src/utils/constants';
import { transformStartAndEndTimes } from 'src/utils/dates';
import Footer from './Footer';

const SHIFT_INDEX_IN_SCHEMA = 0;

const shiftValidationSchema = Yup.object({
  position: Yup.object().required(),
  unpaidBreakMinutes: Yup.number(),
  quantity: Yup.number().max(MAX_TENDERS_ALLOWED).required(),
  date: Yup.date().required(),
  endDateTime: Yup.date().required(),
  startDateTime: Yup.date().required(),
  tipType: Yup.string().when('position', (position: Position) =>
    position?.tipTypes?.length ? Yup.string().required() : Yup.string(),
  ),
  tipAmount: Yup.number().when('tipType', (tipType: string) =>
    tipType === 'INCLUDE_TIP' ? Yup.number().required() : Yup.number(),
  ),
});

const jobInstructionSchema = Yup.object({
  attire: Yup.string().required('* Please select an attire option.'),
  customAttire: Yup.string().when('attire', {
    is: 'custom',
    then: Yup.string().required(),
  }),
});

const shallowJobInstructionSchema = Yup.object({
  attire: Yup.string(),
  customAttire: Yup.string(),
});

interface Props {
  jobId: string;
  location: { lat?: number; lng?: number };
  selectedPositions?: string[];
  timezone: string;
}

interface ShiftFormValues extends Omit<StaffingData, 'attire'> {
  startDateTime: NonNullable<StaffingData['startDateTime']>;
  endDateTime: NonNullable<StaffingData['endDateTime']>;
  quantity: NonNullable<StaffingData['quantity']>;
  position: NonNullable<StaffingData['position']>;
}

interface FormValues {
  shifts: ShiftFormValues[];
  jobInstructions: TenderInstructionsFormData;
}

const NewShiftModal: React.FC<Props> = ({
  jobId,
  location,
  selectedPositions = [],
  timezone,
}) => {
  const [newPosition, setNewPosition] = useState<Position>();
  const modalContext = useContext(ModalContext);
  const [addShiftToClientJob, { loading }] =
    useAddShiftToClientJobMutation(jobId);
  const validationSchema = Yup.object().shape({
    shifts: Yup.array().of(shiftValidationSchema),
    jobInstructions: newPosition
      ? jobInstructionSchema
      : shallowJobInstructionSchema,
  });
  const methods = useForm<FormValues>({
    validationSchema,
    defaultValues: {
      shifts: [{}],
    },
  });
  const handlePositionChange = (position: Position) => {
    if (!selectedPositions.includes(position.id)) {
      setNewPosition(position);
    } else {
      setNewPosition(undefined);
    }
  };
  const handleAttireChange = (
    attireInstructions: Record<string, TenderInstructionsFormData>,
  ) => {
    if (!newPosition) {
      return;
    }

    const {
      attire,
      customAttire,
      blackNonSlipShoes,
      blackApron,
      barKit,
      knifeKit,
    } = attireInstructions[newPosition.id];

    methods.setValue('jobInstructions', {
      attire,
      customAttire,
      blackNonSlipShoes,
      blackApron,
      barKit,
      knifeKit,
    });
  };
  const onSubmit = async (formValues: FormValues) => {
    const { shifts, jobInstructions } = formValues;
    const [shift] = shifts;
    const { startDateTime, endDateTime } = transformStartAndEndTimes({
      date: shift.date,
      startDateTime: shift.startDateTime,
      endDateTime: shift.endDateTime,
    });

    await addShiftToClientJob({
      variables: {
        jobId,
        input: {
          quantity: shift.quantity,
          positionId: shift.position.id,
          startDateTime: startDateTime
            ? zonedTimeToUtc(startDateTime, timezone)
            : startDateTime,
          endDateTime: endDateTime
            ? zonedTimeToUtc(endDateTime, timezone)
            : endDateTime,
          tipType: shift.tipType && TipType[shift.tipType],
          tipAmount: shift.tipAmount,
          unpaidBreakMinutes: shift.unpaidBreakMinutes,
          attire: jobInstructions.customAttire || jobInstructions.attire,
          blackNonSlipShoes: jobInstructions.blackNonSlipShoes,
          blackApron: jobInstructions.blackApron,
          barKit: jobInstructions.barKit,
          knifeKit: jobInstructions.knifeKit,
        },
      },
    });

    modalContext?.setIsModalOpen(false);
  };

  useEffect(() => {
    methods.register('jobInstructions');
  });

  return (
    <Modal title="Add Shift">
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <StaffingCard
            index={SHIFT_INDEX_IN_SCHEMA}
            isClient
            location={location}
            validationSchema={shiftValidationSchema}
            onPositionChange={handlePositionChange}
            displayTitle={false}
            noMargin
          />
          {newPosition && (
            <TenderInstructionsInput
              index={SHIFT_INDEX_IN_SCHEMA}
              name={newPosition.id}
              label={newPosition.name}
              displayTitle={false}
              errors={methods.errors.jobInstructions}
              onChange={handleAttireChange}
            />
          )}
          <Footer disabled={loading} />
        </form>
      </FormContext>
    </Modal>
  );
};

export default NewShiftModal;
