import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const RESET_TENDER_STATUS_AS_CLIENT = gql`
  mutation ResetTenderStatusAsClient($tenderId: ID!) {
    resetTenderStatusAsClient(tenderId: $tenderId) {
      tender {
        id
      }
    }
  }
`;

export const Factory = (tenderId: string) => {
  return useMutation<{
    tenderId: string;
  }>(RESET_TENDER_STATUS_AS_CLIENT, {
    variables: {
      tenderId,
    },
  });
};
