import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import SHIFT_FOR_JOB_DETAILS from '../fragments/ShiftForJobDetails';
import {
  UpdateShiftInClientJob,
  UpdateShiftInClientJobVariables,
} from './__generated__/UpdateShiftInClientJob';

const UPDATE_SHIFT_IN_CLIENT_JOB = gql`
  mutation UpdateShiftInClientJob(
    $shiftId: ID!
    $input: UpdateShiftInClientJobInput!
  ) {
    updateShiftInClientJob(shiftId: $shiftId, input: $input) {
      id
      startDateTime
      endDateTime
      total
      shifts {
        ...ShiftForJobDetails
      }
    }
  }
  ${SHIFT_FOR_JOB_DETAILS}
`;

type MutationOptions = MutationHookOptions<
  UpdateShiftInClientJob,
  UpdateShiftInClientJobVariables
>;
export const useUpdateShiftInClientJobMutation = (
  shiftId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<UpdateShiftInClientJob, UpdateShiftInClientJobVariables>(
    UPDATE_SHIFT_IN_CLIENT_JOB,
    {
      ...options,
      onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
        (data) => {
          track(EVENTS.UPDATED_SHIFT_DETAILS, {
            'Shift ID': shiftId,
          });
          onCompleted?.(data);
        },
        [onCompleted, shiftId],
      ),
    },
  );
};

export default UPDATE_SHIFT_IN_CLIENT_JOB;
