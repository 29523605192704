import React, { useContext, useState } from 'react';
import {
  Controller,
  ErrorMessage as FormErrorMessage,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';
import Button from 'src/components/Button';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import PasswordInput from 'src/components/PasswordInput';
import { authContext } from 'src/contexts/AuthContext';
import { useChangeClientPasswordMutation } from 'src/graphql/mutations/ChangePassword';

const CustomPasswordInput = styled(PasswordInput)`
  width: 45%;
  text-align: left;
  display: flex;
`;

const StatusMessage = styled(ErrorMessage)`
  color: var(--color-ink-notasdark);
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

type FormValues = {
  newPassword: string;
  passwordConfirmation: string;
};

const PasswordChangeSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(' ')
    .min(8, 'Your password must be at least 8 characters.')
    .max(255, 'Your password must be less than 255 characters.'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

interface PasswordResetProps {
  token: string;
}

const PasswordResetForm: React.FC<PasswordResetProps> = (props) => {
  const { token: temporaryToken } = props;

  const auth = useContext(authContext);

  const {
    control,
    errors,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    mode: 'onBlur',
    validationSchema: PasswordChangeSchema,
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [changePassword] = useChangeClientPasswordMutation();

  const onSubmit = async (values: FormValues) => {
    auth.setAuthToken(temporaryToken);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const result = await changePassword({
        variables: { input: { password: values.newPassword } },
      });

      if (result.data?.changeClientPassword?.id) {
        setSuccessMessage(
          'Password changed successfully. Please sign in with your new password.',
        );
      }
    } catch (error) {
      setErrorMessage(
        'The link you just used is either invalid or has already expired. Please obtain a new verification email.',
      );
    } finally {
      // remove token regardless of the outcome
      // if it's invalid then there's no use in keeping it
      // and if it's valid we should still remove it, since we want to enforce users to sign in with the new password
      auth.removeAuthToken();
    }

    reset({
      newPassword: '',
      passwordConfirmation: '',
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Label required>New Password</Label>
      <Controller
        name="newPassword"
        control={control}
        label=" "
        isValid={!errors.newPassword}
        as={CustomPasswordInput}
      />
      <FormErrorMessage errors={errors} name="newPassword" as={ErrorMessage} />
      <Label required>Confirm Password</Label>
      <Controller
        name="passwordConfirmation"
        control={control}
        label=" "
        isValid={!errors.passwordConfirmation}
        as={CustomPasswordInput}
      />
      <FormErrorMessage
        errors={errors}
        name="passwordConfirmation"
        as={ErrorMessage}
      />
      <Container>
        <StatusMessage>{successMessage}</StatusMessage>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Container>
      <Container>
        <Button type="submit" disabled={isSubmitting}>
          Change Password
        </Button>
      </Container>
    </form>
  );
};

export default PasswordResetForm;
