import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner: React.FC = () => {
  return (
    <Container>
      <svg height="20" viewBox="0 0 40 15" fill="#333" width="120">
        <circle cx="5" cy="10" r="5">
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="20" cy="10" r="5">
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0.2s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="35" cy="10" r="5">
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0.4s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </Container>
  );
};

export default Spinner;
