import React, { FC } from 'react';
import styled from 'styled-components';
import { default as RadioMaterial, RadioProps } from '@material/react-radio';

const RadioContainer = styled.div`
  display: inline-block;
  padding-right: 10px;
`;

type Props = Pick<RadioProps, 'children' | 'label'>;

const Radio: FC<Props> = (props) => (
  <RadioContainer>
    <RadioMaterial {...props} />
  </RadioContainer>
);

export default Radio;
