// Breakpoints, Bootstrap knows best
export const SM_BREAK_POINT = '576px';
export const MD_BREAK_POINT = '768px';
export const LG_BREAK_POINT = '992px';
export const XL_BREAK_POINT = '1200px';

export const LAYOUT_MAX_WIDTH = '1024px';

export const NAVBAR_HEIGHT = 69;
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const DEFAULT_COORDINATES = {
  lat: 34.052235,
  lng: -118.243683,
};

export const DEFAULT_CONTACT_EMAIL = 'business@hiretend.com';

export const MAX_TENDERS_ALLOWED = 500;
