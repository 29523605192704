import React from 'react';
import styled from 'styled-components';

export const BlankCard = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  width: 30%;
  padding: 32px 12px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  opacity: 80%;
`;

export const BlankLine = styled.div<{ longer?: boolean }>`
  background-color: ${({ theme }) => theme.color.supportLines};
  height: 8px;
  width: ${({ longer }) => (longer ? '85%' : '70%')};
  margin: 8px;
  border-radius: 4px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinesContainer = styled.div`
  padding-top: 40px;
  width: 33%;
`;

const EmptyCard: React.FC = () => {
  return (
    <BlankCard>
      <BlankLine />
      <BlankLine longer />
      <Row>
        <LinesContainer>
          <BlankLine />
          <BlankLine longer />
        </LinesContainer>
        <LinesContainer>
          <BlankLine />
          <BlankLine longer />
        </LinesContainer>
        <LinesContainer>
          <BlankLine />
          <BlankLine longer />
        </LinesContainer>
      </Row>
    </BlankCard>
  );
};

export default EmptyCard;
