import React, { useContext } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import { Modal, ModalContext } from 'src/components/Modal';
import TextField from 'src/components/TextField';
import objectIsEmpty from 'src/utils/objectIsEmpty';
import { useEditJobDescriptionMutation } from 'src/graphql/mutations/EditJobDescription';
import Footer from './Footer';

const Form = styled.form`
  height: 100%;
  padding: 4px;
  text-align: left;
`;

export type FormData = {
  id: string;
  description: string | null;
};

type FormErrors = {
  description?: FieldError;
};

type Props = {
  defaultValues: FormData;
};

const validationSchema = Yup.object().shape({
  description: Yup.string(),
});

const EditLocationModal: React.FC<Props> = ({ defaultValues }) => {
  const { control, handleSubmit, errors } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      description: defaultValues.description || '',
    },
    validationSchema,
  });

  const [editJobDescription, { loading }] = useEditJobDescriptionMutation(
    defaultValues.id,
  );
  const modalContext = useContext(ModalContext);
  const onSubmit = async (data: FormData) => {
    const description = data.description || '';
    await editJobDescription({
      variables: {
        jobId: defaultValues.id,
        description,
      },
    });
    modalContext?.setIsModalOpen(false);
  };
  const formErrors: FormErrors = errors as unknown as FormErrors;
  return (
    <Modal title="Edit Job Description">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>Job Description</Label>
        <TextField textarea label="e.g. Birthday party for 100 people">
          <Controller
            as={<Input />}
            control={control}
            name="description"
            data-cy="description"
          />
        </TextField>
        <ErrorMessage block visible={!objectIsEmpty(formErrors)} />
        <Footer disabled={loading} />
      </Form>
    </Modal>
  );
};

export default EditLocationModal;
