import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: none;
  width: 180px;
  height: 52px;
  background-color: var(--color-brand-tend);
  color: var(--color-ink-clear);
  font-size: 18px;
  border-radius: 26px;

  &:hover {
    background-color: #023a30;
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
`;

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => <StyledButton {...props}>{children}</StyledButton>;

export default Button;
