import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';

import { EVENTS, track } from 'src/analytics';
import {
  UploadJobTimesheetFile,
  UploadJobTimesheetFileVariables,
} from './__generated__/UploadJobTimesheetFile';

const UPLOAD_TIMESHEET_FILE = gql`
  mutation UploadJobTimesheetFile(
    $jobId: ID!
    $input: UploadJobTimesheetFileInput!
  ) {
    uploadJobTimesheetFile(jobId: $jobId, input: $input) {
      id
      timesheetFiles {
        id
        name
      }
    }
  }
`;

type MutationOptions = MutationHookOptions<
  UploadJobTimesheetFile,
  UploadJobTimesheetFileVariables
>;
export const useUploadTimesheetFile = (
  jobId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<UploadJobTimesheetFile, UploadJobTimesheetFileVariables>(
    UPLOAD_TIMESHEET_FILE,
    {
      ...options,
      onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
        (data) => {
          track(EVENTS.UPLOADED_JOB_TIMESHEET_FILE, {
            'Job ID': jobId,
          });
          onCompleted?.(data);
        },
        [onCompleted, jobId],
      ),
    },
  );
};
