import React, { FC } from 'react';
import styled from 'styled-components';

import Text from 'src/components/Text';

const Container = styled.div`
  :not(:first-child) {
    margin-top: 24px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 16px;
`;

const DetailsSection: FC<{ title: string | undefined }> = ({
  title,
  children,
}) => (
  <Container>
    <Title preset="preset5" bold>
      {title}
    </Title>
    {children}
  </Container>
);

export default DetailsSection;
