import jwt_decode from 'jwt-decode';

export type Audience = 'admin' | 'tender' | 'client';

export interface Authorization {
  sub: string;
  exp?: number;
  aud: Audience[];
  email?: string;
}

const tokenExpirationTolerance = 1000 * 60; // One minute

export const decodeToken = (token: string): Authorization | undefined => {
  if (!token) {
    return;
  }

  try {
    return jwt_decode<Authorization>(token);
  } catch (error) {
    console.error(error);
  }
};

export const tokenIsValid = (token: string): boolean => {
  const decoded = decodeToken(token);

  if (!decoded) {
    return false;
  }

  if (!decoded.exp) {
    return true;
  }

  const now = Date.now();
  const exp = decoded.exp * 1000; // convert to milliseconds

  return now < exp - tokenExpirationTolerance;
};
