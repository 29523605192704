import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { authContext } from 'src/contexts/AuthContext';
import Logo from 'src/images/tend-logo.svg';
import {
  BurgerLine,
  Button,
  ButtonLink,
  CenteredContainer,
  CenteredLink,
  Container,
  Divider,
  ImgLogo,
  MenuContainer,
  MenuItem,
  RightContainer,
  StyledBurger,
  Tab,
  TabBar,
} from './TopNavigation.styled';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

const HamburgerMenu: React.FC<Props> = ({ children, isOpen, onClick }) => {
  return (
    <>
      <StyledBurger onClick={onClick}>
        <BurgerLine isOpen={isOpen} />
        <BurgerLine isOpen={isOpen} />
        <BurgerLine isOpen={isOpen} />
      </StyledBurger>
      {children}
    </>
  );
};

const TopNavigation: React.FC = () => {
  const auth = useContext(authContext);
  const [isOpen, setIsOpen] = React.useState(false);

  const history = useHistory();

  return (
    <Container>
      <CenteredContainer>
        <CenteredLink to="/">
          <ImgLogo src={Logo} />
        </CenteredLink>
        <TabBar>
          <Tab to="/bookings">Bookings</Tab>
          <Tab to="/past-tenders">Past Tenders</Tab>
        </TabBar>
        <RightContainer>
          <ButtonLink
            raised
            onClick={() => {
              history.push('/client');
            }}
          >
            Book Now
          </ButtonLink>
          <Button onClick={auth.removeAuthToken}>Sign Out</Button>
          <HamburgerMenu
            isOpen={isOpen}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MenuContainer isOpen={isOpen}>
              <MenuItem
                to="/bookings"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Bookings
              </MenuItem>
              <MenuItem
                to="/past-tenders"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Past Tenders
              </MenuItem>
              <Divider />
              <MenuItem to="#" onClick={auth.removeAuthToken}>
                Sign Out
              </MenuItem>
            </MenuContainer>
          </HamburgerMenu>
        </RightContainer>
      </CenteredContainer>
    </Container>
  );
};

export default TopNavigation;
