import React, { useContext, useEffect, useState } from 'react';
import {
  Controller,
  ErrorMessage as FormErrorMessage,
  FieldError,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { NativeRadioControl } from '@material/react-radio';
import { Input } from '@material/react-text-field';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as Yup from 'yup';

import { Address } from '@hiretend/google-places';

import useParking from 'src/hooks/Parking';
import AddressLookup from 'src/components/AddressLookup/AddressLookup';
import { RadioGroup, Separator, Title } from 'src/components/FormComponents';
import Checkbox from 'src/components/Checkbox';
import { ErrorMessage } from 'src/components/StatusMessages';
import Label from 'src/components/Label';
import { Modal, ModalContext } from 'src/components/Modal';
import PastJobLocations from 'src/components/PastLocations';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import TextField from 'src/components/TextField';
import { MyMostRecentJobsByVenue_myMostRecentJobsByVenue as myMostRecentJobsType } from 'src/components/PastLocations/__generated__/MyMostRecentJobsByVenue';
import objectIsEmpty from 'src/utils/objectIsEmpty';
import { EditJobLocationVariables } from 'src/graphql/mutations/__generated__/EditJobLocation';
import { useEditJobLocationMutation } from 'src/graphql/mutations/EditJobLocation';
import { VenueType } from 'src/__generated__/globalTypes';
import Footer from './Footer';

const TextFieldVisible = styled(TextField)<{ display: string }>`
  display: ${({ display }) => display};
`;

const Form = styled.form`
  height: 100%;
  padding: 4px;
  text-align: left;
`;

interface AddressWithTimezone extends Address {
  timezone?: string;
  __typename?: string;
}

export type FormData = {
  id: string;
  mealProvided?: boolean;
  useBackDoor?: boolean;
  venue?: {
    id?: string | null;
    type?: VenueType | undefined;
    name?: string | null;
    address?: AddressWithTimezone;
    otherInstructions?: string | null;
    parking?: {
      value: string;
      label: string;
    };
    parkingType?: string | null;
    parkingSubType?: string | null;
    parkingInstructions?: string | null;
  } | null;
  locationContact?: {
    name?: string;
    phoneNumber?: string | null;
  } | null;
};

type FormErrors = {
  venue?: {
    name?: FieldError;
    address?: FieldError;
    parkingType?: FieldError;
    parkingSubType?: FieldError;
  };
  locationContact?: {
    name?: FieldError;
    phoneNumber?: FieldError;
  };
};

type Props = {
  defaultValues: FormData;
};

const validationSchema = Yup.object().shape({
  venue: Yup.object().shape({
    name: Yup.string().when('type', {
      is: 'COMMERCIAL',
      then: Yup.string().required(),
    }),
    address: Yup.object().required(),
    parkingType: Yup.string().required('* Choose a parking option.'),
  }),
  locationContact: Yup.object().shape({
    name: Yup.string().required(),
    phoneNumber: Yup.string()
      .test('phoneNumber', 'Not a valid phone number', (value) => {
        if (!value) {
          return true;
        }
        return parsePhoneNumberFromString(value, 'US')?.isValid() || false;
      })
      .notRequired(),
  }),
});

const EditLocationModal: React.FC<Props> = ({ defaultValues }) => {
  const [venueType, setVenueType] = useState<VenueType | undefined>(
    defaultValues.venue?.name ? VenueType.COMMERCIAL : VenueType.RESIDENTIAL,
  );
  const { parking, parkingType, updateParking, parkingOptions } = useParking(
    defaultValues.venue?.parking?.value,
  );
  const [initialAddress, setInitialAddress] = useState<Address | undefined>(
    defaultValues.venue?.address,
  );

  const { control, handleSubmit, setValue, register, errors } =
    useForm<FormData>({
      mode: 'onBlur',
      defaultValues: {
        ...defaultValues,
        locationContact: {
          name: defaultValues.locationContact?.name,
          phoneNumber: defaultValues.locationContact?.phoneNumber || undefined,
        },
        venue: {
          ...defaultValues.venue,
          name: defaultValues.venue?.name ?? '',
          type: defaultValues.venue?.name
            ? VenueType.COMMERCIAL
            : VenueType.RESIDENTIAL,
          otherInstructions: defaultValues.venue?.otherInstructions ?? '',
          parkingInstructions: defaultValues.venue?.parkingInstructions ?? '',
        },
      },
      validationSchema,
    });
  register('venue.id');
  register('venue.parkingType');

  useEffect(() => {
    setValue('venue.parkingType', parkingType);
  }, [parkingType, setValue]);

  const handlePastLocationClick = (selectedData: myMostRecentJobsType) => {
    const newAddress = selectedData.venue?.address;
    setVenueType(selectedData.venue?.type as VenueType | undefined);
    updateParking(selectedData.venue?.parking.value);
    setInitialAddress(selectedData.venue?.address);
    setValue('venue.type', selectedData.venue?.type);
    setValue('locationContact.name', selectedData.locationContact?.name);
    setValue(
      'locationContact.phoneNumber',
      selectedData.locationContact?.phoneNumber || undefined,
    );
    setValue('job.mealProvided', selectedData?.mealProvided);
    setValue('job.useBackDoor', selectedData?.useBackDoor);
    setValue('venue.address', newAddress);
    setValue('venue.name', selectedData.venue?.name || undefined);
    setValue(
      'venue.otherInstructions',
      selectedData.venue?.otherInstructions || undefined,
    );
    setValue(
      'venue.parkingInstructions',
      selectedData.venue?.parkingInstructions || undefined,
    );
    setVenueId(selectedData.venue?.id);
  };
  const [editJobLocation, { loading }] = useEditJobLocationMutation(
    defaultValues.id,
  );
  const modalContext = useContext(ModalContext);
  const onSubmit = async (locationData: FormData) => {
    delete locationData.venue?.address?.timezone;
    delete locationData.venue?.address?.__typename;
    const input = locationData as EditJobLocationVariables['input'];
    await editJobLocation({
      variables: {
        id: defaultValues.id,
        input,
      },
    });
    modalContext?.setIsModalOpen(false);
  };
  const setVenueId = (value?: string): void => {
    setValue('venue.id', value);
    handleSubmit(onSubmit);
  };
  const formErrors: FormErrors = errors as unknown as FormErrors;
  return (
    <Modal title="Edit Location Details">
      <PastJobLocations onClick={handlePastLocationClick} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label required>Address</Label>
        <RadioGroup>
          <Controller
            as={
              <Radio label="Residential" key="residential">
                <NativeRadioControl
                  value={VenueType.RESIDENTIAL}
                  id="venue.type.residential"
                  data-cy="venueTypeResidential"
                  onChange={() => {
                    setVenueId();
                    setVenueType(VenueType.RESIDENTIAL);
                  }}
                  checked={venueType === VenueType.RESIDENTIAL}
                />
              </Radio>
            }
            control={control}
            name="venue.type"
          />
          <Controller
            as={
              <Radio label="Commercial" key="commercial">
                <NativeRadioControl
                  value={VenueType.COMMERCIAL}
                  id="venue.type.commercial"
                  data-cy="venueTypeCommercial"
                  onChange={() => {
                    setVenueId();
                    setVenueType(VenueType.COMMERCIAL);
                  }}
                  checked={venueType === VenueType.COMMERCIAL}
                />
              </Radio>
            }
            control={control}
            name="venue.type"
          />
        </RadioGroup>
        <TextFieldVisible
          display={venueType === VenueType.COMMERCIAL ? '' : 'none'}
          label="Location name"
        >
          <Controller
            as={<Input isValid={!formErrors?.venue?.name} />}
            control={control}
            id="venue.name"
            name="venue.name"
            data-cy="venueName"
            onChange={([event]) => {
              setVenueId();
              return event.target.value;
            }}
          />
        </TextFieldVisible>
        <Controller
          as={
            <AddressLookup
              onChange={(address: Address) =>
                setValue('venue.address', address)
              }
              initialAddress={initialAddress}
              isValid={!formErrors?.venue?.address}
            />
          }
          name="venue.address"
          control={control}
          onChange={([value]) => {
            setVenueId();
            return value;
          }}
        />
        <Label required>Parking</Label>
        <Select
          data-cy="parkingSubType"
          options={parkingOptions?.map((option) => ({
            label: option.name,
            value: option.name,
          }))}
          defaultValue={parking?.name}
          value={parking?.name}
          onChange={(value) => {
            setVenueId();
            updateParking(value);
          }}
        />
        <FormErrorMessage
          errors={formErrors}
          name="venue.parkingSubType"
          as={<ErrorMessage />}
        />
        <RadioGroup>
          {parking?.options &&
            parking.options.length > 1 &&
            parking.options.map((option) => {
              return (
                <div key={option.value}>
                  <Radio label={option.label} key={option.value}>
                    <NativeRadioControl
                      value={option.value}
                      id={`venue.parkingType.${option.value}`}
                      data-cy={`venue.parkingType.${option.value}`}
                      onChange={() => {
                        setVenueId();
                        updateParking(option.value);
                      }}
                      checked={parkingType === option.value}
                    />
                  </Radio>
                </div>
              );
            })}
        </RadioGroup>
        <FormErrorMessage
          errors={formErrors}
          name="venue.parkingType"
          as={<ErrorMessage />}
        />
        <Label>Parking Instructions</Label>
        <TextField
          textarea
          label="e.g. Parking available in front of the main building"
        >
          <Controller
            as={<Input />}
            control={control}
            name="venue.parkingInstructions"
            data-cy="venueParkingInstructions"
            onChange={([event]) => {
              setVenueId();
              return event.target.value;
            }}
          />
        </TextField>
        <Label>Arrival Instructions</Label>
        <Controller
          as={
            <Checkbox
              label="Staff meal provided"
              name="mealProvided"
              data-cy="mealProvided"
              nativeControlId="mealProvided"
            />
          }
          name="mealProvided"
          control={control}
        />
        <Controller
          as={
            <Checkbox
              label="Use back door"
              name="useBackDoor"
              data-cy="useBackDoor"
              nativeControlId="useBackDoor"
            />
          }
          name="useBackDoor"
          control={control}
        />
        <Label>Other Location Details</Label>
        <TextField textarea label="e.g. Staff has to go through security check">
          <Controller
            as={<Input />}
            control={control}
            name="venue.otherInstructions"
            data-cy="venueOtherInstructions"
            onChange={([event]) => {
              setVenueId();
              return event.target.value;
            }}
          />
        </TextField>
        <Separator />
        <Title>Location Contact</Title>
        <Label required>Name</Label>
        <TextField label="Enter contact name">
          <Controller
            as={<Input isValid={!formErrors.locationContact?.name} />}
            control={control}
            name="locationContact.name"
            data-cy="contactName"
          />
        </TextField>
        <Label>Phone</Label>
        <TextField label="Enter contact phone number">
          <Controller
            as={<Input isValid={!formErrors.locationContact?.phoneNumber} />}
            control={control}
            name="locationContact.phoneNumber"
            data-cy="contactPhoneNumber"
          />
        </TextField>
        <ErrorMessage block visible={!objectIsEmpty(formErrors)} />
        <Footer disabled={loading} />
      </Form>
    </Modal>
  );
};

export default EditLocationModal;
