import React, { FC } from 'react';
import ReactSelect, { OptionTypeBase, Styles, Theme } from 'react-select';

type OptionsMap = { [key: string]: OptionTypeBase };

interface Props {
  options?: OptionTypeBase[];
  defaultValue?: string;
  value?: string;
  isValid?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  className?: string;
  height?: number;
  isSearchable?: boolean;
  'data-cy'?: string;
}

const customTheme =
  (height?: number) =>
  (theme: Theme): Theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#045445',
      primary25: '#E7EEED',
      primary50: '#C0D9D4',
      neutral50: 'hsl(0, 0%, 60%)',
      neutral20: 'hsl(0, 0%, 60%)',
    },
    spacing: {
      ...theme.spacing,
      controlHeight: height ?? 56,
    },
  });

const getStyles = (isValid: boolean): Styles => ({
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (base) => ({
    ...base,
    backgroundColor: 'var(--color-background-app)',
    borderColor: isValid ? base.borderColor : 'var(--color-status-destructive)',
    '&:hover': {
      borderColor: isValid ? '#333' : 'var(--color-status-destructive)',
    },
  }),
});

const Select: FC<Props> = ({
  value,
  isValid = true,
  options,
  placeholder = 'Select an option',
  onChange,
  isDisabled,
  className,
  height,
  isSearchable = true,
  ...props
}) => {
  const optionsMap: OptionsMap = {};
  options?.forEach((option) => {
    optionsMap[option.value] = option;
  });
  const findOption = (selectedValue?: string) =>
    selectedValue && optionsMap[selectedValue];
  const handleChange = (selectedOption: OptionTypeBase) =>
    onChange && onChange(selectedOption.value);
  const currentOption =
    findOption(value) || findOption(props.defaultValue) || '';
  return (
    <div data-cy={props['data-cy']} className={className}>
      <ReactSelect
        onChange={handleChange}
        options={options}
        value={currentOption}
        placeholder={placeholder}
        isValid={false}
        theme={customTheme(height)}
        styles={getStyles(isValid)}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        isSearchable={isSearchable}
      />
    </div>
  );
};

export default Select;
