import React from 'react';
import DefaultAvatar from 'src/components/DefaultAvatar';

import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import blockIcon from 'src/images/icons/block.svg';
import dislikeIcon from 'src/images/icons/dislike.svg';
import loveIcon from 'src/images/icons/love.svg';
import {
  StatusIcon,
  TenderAvatar,
  TenderAvatarContainer,
  TenderStatusBadge,
} from './TenderCard.styled';

type Props = {
  avatarURL: string | null;
  status: ClientTenderStatus | null;
  firstName: string;
  lastName: string;
};

const AvatarImage: React.FC<Props> = ({
  avatarURL,
  status,
  firstName,
  lastName,
}) => {
  const getIcon = (tenderStatus: ClientTenderStatus) => {
    switch (tenderStatus) {
      case ClientTenderStatus.FAVORITE:
        return <StatusIcon src={loveIcon} alt="favorite" />;
      case ClientTenderStatus.LESS_PREFERRED:
        return <StatusIcon src={dislikeIcon} alt="thumbs down" />;
      case ClientTenderStatus.BLOCKED:
        return <StatusIcon src={blockIcon} alt="block" />;
    }
  };

  return (
    <TenderAvatarContainer>
      {avatarURL ? (
        <TenderAvatar src={avatarURL} status={status} />
      ) : (
        <DefaultAvatar firstName={firstName} lastName={lastName} />
      )}
      {status && (
        <TenderStatusBadge status={status}>{getIcon(status)}</TenderStatusBadge>
      )}
    </TenderAvatarContainer>
  );
};

export default AvatarImage;
