import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import { MD_BREAK_POINT } from 'src/utils/constants';

export const GroupInput = styled.div<{ flex?: string }>`
  flex: ${({ flex }) => flex || '0 1 24%'};
  @media only screen and (max-width: ${MD_BREAK_POINT}) {
    flex: 0 1 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 40px;
  min-width: 50px;
  ${GroupInput} + ${GroupInput} {
    padding-left: 1%;
  }
`;

export const ColumnWithPadding = styled(Column)`
  padding-left: 15px;
`;

export const TotalText = styled.h3`
  margin: 0 2px 0 0;
`;

export const TipTitle = styled.h5`
  margin: 0;
`;

export const Title = styled.h3<{ isDark?: boolean; noMargin?: boolean }>`
  margin: 0;
  padding: ${({ noMargin }) => (noMargin ? '0' : '16px 24px')};
  border-bottom: 1px solid var(--color-support-lines);
  color: ${({ isDark }) =>
    isDark ? 'var(--color-ink-dark)' : 'var(--color-ink-notasdark)'};
`;

export const TotalNumber = styled.p`
  margin: 0;
`;

export const Row = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${({ noMargin }) => (noMargin ? '0' : '0 24px')};
`;

export const TotalRow = styled(Row)`
  padding-bottom: 24px;
  align-items: end;
  justify-content: flex-end;
`;

export const DayAfterText = styled.p`
  height: 0;
  margin: 0;
  color: var(--color-secondary);
`;

export const DeleteIcon = styled(MaterialIcon)`
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 54px;
  color: var(--color-status-destructive);
`;

export const DeleteContainer = styled.div<{ deleted: boolean }>`
  transition: all 0.3s ease-in-out;
  ${({ deleted }) => (deleted ? 'max-height: 0;' : 'max-height: 500px;')};
  ${({ deleted }) => (deleted ? 'opacity: 0;' : 'opacity: 1;')};
  ${({ deleted }) => (deleted ? 'overflow: hidden;' : 'overflow: unset;')};
  @media only screen and (max-width: ${MD_BREAK_POINT}) {
    ${({ deleted }) => (deleted ? 'max-height: 0;' : 'max-height: 1040px;')};
  }
`;
