import mixpanelSingleton, { Dict, Mixpanel } from 'mixpanel-browser';
import * as FullStory from '@fullstory/browser';

import { decodeToken } from './auth';
import apolloClient from './ApolloClient';
import GET_CLIENT_PROFILE_QUERY from './graphql/queries/GetClientProfile';
import { GetClientProfile } from './graphql/queries/__generated__/GetClientProfile';
const { REACT_APP_MIXPANEL_PROJECT_TOKEN, NODE_ENV } = process.env;

export let mixpanel: Mixpanel | undefined;

export const initializeMixpanel = () => {
  if (NODE_ENV !== 'production' || !REACT_APP_MIXPANEL_PROJECT_TOKEN) {
    return;
  }

  mixpanelSingleton.init(REACT_APP_MIXPANEL_PROJECT_TOKEN);
  mixpanel = mixpanelSingleton;
};

export enum EVENTS {
  ADDED_SHIFT_TO_BOOKING = 'Added shift to booking',
  CREATED_BOOKING = 'Created booking',
  DELETED_SHIFT = 'Deleted shift',
  DELETED_JOB_TIMESHEET_FILE = 'Deleted a job timesheet file',
  EMAIL_VERIFIED = 'Email verified',
  NEW_BOOKING_NAME_AND_LOCATION_INFO_SUBMITTED = 'New booking name and location info submitted',
  NEW_BOOKING_POSITION_INSTRUCTIONS_SUBMITTED = 'New booking position instructions submitted',
  NEW_BOOKING_SHIFTS_SUBMITTED = 'New booking shifts submitted',
  OPENED_BOOKING_DETAILS = 'Opened booking details',
  PAGE_VIEW = 'Page view',
  RESET_PASSWORD_REQUEST_SENT = 'Reset password request sent',
  SIGNED_IN = 'Signed in',
  SIGNED_OUT = 'Signed out',
  SIGNED_UP = 'Signed up',
  STARTED_BOOKING_FORM = 'Started booking form',
  UPDATED_BOOKING_DESCRIPTION = 'Updated booking description',
  UPDATED_BOOKING_LOCATION_DETAILS = 'Updated booking location details',
  UPDATED_SHIFT_DETAILS = 'Updated shift details',
  UPDATED_SHIFT_POSITION_INSTRUCTIONS = 'Updated shift position instructions',
  UPLOADED_JOB_TIMESHEET_FILE = 'Uploaded a job timesheet file',
}

export function track(
  eventName: EVENTS.UPDATED_SHIFT_DETAILS | EVENTS.DELETED_SHIFT,
  properties: { 'Shift ID': string },
): void;
export function track(
  eventName:
    | EVENTS.ADDED_SHIFT_TO_BOOKING
    | EVENTS.DELETED_JOB_TIMESHEET_FILE
    | EVENTS.OPENED_BOOKING_DETAILS
    | EVENTS.UPDATED_BOOKING_DESCRIPTION
    | EVENTS.UPDATED_BOOKING_LOCATION_DETAILS
    | EVENTS.UPDATED_SHIFT_POSITION_INSTRUCTIONS
    | EVENTS.UPLOADED_JOB_TIMESHEET_FILE,
  properties: { 'Job ID': string },
): void;
export function track(
  eventName: EVENTS.CREATED_BOOKING,
  properties: { 'User type': 'guest' | 'client' },
): void;
export function track(
  eventName: EVENTS.PAGE_VIEW,
  properties: { pathname: string },
): void;
export function track(
  eventName:
    | EVENTS.EMAIL_VERIFIED
    | EVENTS.NEW_BOOKING_NAME_AND_LOCATION_INFO_SUBMITTED
    | EVENTS.NEW_BOOKING_POSITION_INSTRUCTIONS_SUBMITTED
    | EVENTS.NEW_BOOKING_SHIFTS_SUBMITTED
    | EVENTS.RESET_PASSWORD_REQUEST_SENT
    | EVENTS.SIGNED_IN
    | EVENTS.SIGNED_OUT
    | EVENTS.SIGNED_UP
    | EVENTS.STARTED_BOOKING_FORM,
): void;
export function track(eventName: EVENTS, properties?: Dict) {
  return mixpanel?.track(eventName, properties);
}

export async function identify(token: string) {
  if (!mixpanel) {
    return;
  }

  const auth = decodeToken(token);
  auth && mixpanel.identify(auth.sub);

  let profile;
  try {
    const { data } = await apolloClient.query<GetClientProfile>({
      query: GET_CLIENT_PROFILE_QUERY,
    });
    profile = data.profile;
  } catch {
    return;
  }

  if (!profile) {
    return;
  }

  mixpanel.people.set({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    $first_name: profile.firstName,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    $last_name: profile.lastName,
    $email: profile.email,
  });
  if (!auth) {
    return;
  }
  FullStory.identify(auth.sub, {
    displayName: `${profile.firstName} ${profile.lastName}`,
    email: profile.email || 'no-email',
  });
}
