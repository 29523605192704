import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { GRAPHQL_ENDPOINT } from 'src/routes';

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem('authToken');
  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(
              message,
            )}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
              path,
            )}`,
          );
          if (!extensions) {
            return;
          }
          switch (extensions.code) {
            case 'AUTHORIZATION_ERROR':
              localStorage.removeItem('authToken');
              window.location.replace('/signin');
          }
        });
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    authLink.concat(httpLink),
  ]),
  cache: new InMemoryCache(),
});

export default client;
