import React, { FC } from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';

interface Props {
  block?: boolean;
  visible?: boolean;
}

const BlockErrorContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--color-status-destructive);
  border-radius: 4px;
  padding: 12px 40px;

  p,
  a {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: var(--color-ink-clear);
  }

  .icon {
    color: white;
    margin-right: 8px;
  }
`;

const SimpleErrorMessage = styled.p`
  font-size: 14px;
  margin: 0;
  color: var(--color-status-destructive);
`;

const ErrorMessage: FC<Props> = ({ block, children, visible = true }) => {
  const content = children || 'Please fill all required fields.';
  if (!visible) {
    return null;
  }
  if (block) {
    return (
      <BlockErrorContainer>
        <MaterialIcon icon="error_outline" className="icon" />
        <p>{content}</p>
      </BlockErrorContainer>
    );
  }
  return <SimpleErrorMessage>{content}</SimpleErrorMessage>;
};
export default ErrorMessage;
