import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EVENTS, track } from 'src/analytics';
import {
  EditJobDescription,
  EditJobDescriptionVariables,
} from './__generated__/EditJobDescription';

const EDIT_JOB_DESCRIPTION = gql`
  mutation EditJobDescription($jobId: ID!, $description: String!) {
    editJobDescription(jobId: $jobId, description: $description) {
      id
      description
    }
  }
`;

type MutationOptions = MutationHookOptions<
  EditJobDescription,
  EditJobDescriptionVariables
>;
export const useEditJobDescriptionMutation = (
  jobId: string,
  options?: MutationOptions,
) => {
  const onCompleted = options?.onCompleted;
  return useMutation<EditJobDescription, EditJobDescriptionVariables>(
    EDIT_JOB_DESCRIPTION,
    {
      ...options,
      onCompleted: useCallback<NonNullable<MutationOptions['onCompleted']>>(
        (data) => {
          track(EVENTS.UPDATED_BOOKING_LOCATION_DETAILS, { 'Job ID': jobId });
          onCompleted?.(data);
        },
        [jobId, onCompleted],
      ),
    },
  );
};

export default EDIT_JOB_DESCRIPTION;
