import gql from 'graphql-tag';

export const GET_MY_PAST_TENDERS = gql`
  query GetMyPastTenders($filter: String, $status: ClientTenderStatus) {
    getMyPastTenders(filter: $filter, status: $status) {
      id
      firstName
      lastName
      avatarURL
      status
      completedJobs
      latestShift {
        id
        jobId
        startDateTime
        endDateTime
      }
      positions {
        name
      }
    }
  }
`;
