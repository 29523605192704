import React from 'react';
import HomeTemplate from 'src/components/HomeTemplate';
import SignInForm from 'src/components/SignInForm';

const SignInView: React.FC = () => (
  <HomeTemplate signAction="signup">
    <SignInForm />
  </HomeTemplate>
);

export default SignInView;
