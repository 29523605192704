import React, { useContext } from 'react';
import styled from 'styled-components';
import { format, utcToZonedTime } from 'date-fns-tz';
import { differenceInHours } from 'date-fns';
import { Button } from '@material/react-button';
import MaterialIcon from '@material/react-material-icon';
import IconButton from '@material/react-icon-button';

import Card from 'src/components/Card';
import Text from 'src/components/Text';
import {
  EditShiftModal,
  NewShiftModal,
} from 'src/components/EditBookingModals';
import { DEFAULT_TIMEZONE } from 'src/utils/constants';
import { formatMoney } from 'src/utils/money';
import { calculateTotalRate } from 'src/utils/position';
import {
  MyClientJob_myClientJob as Job,
  MyClientJob_myClientJob_shifts as Shift,
} from 'src/graphql/queries/__generated__/MyClientJob';
import { TipType } from 'src/__generated__/globalTypes';
import { Modal, ModalContainer, ModalContext } from 'src/components/Modal';
import addBtn from 'src/images/icons/add.svg';
import editBtn from 'src/images/icons/edit.svg';
import deleteBtn from 'src/images/icons/delete.svg';
import deleteBtnGray from 'src/images/icons/deleteGray.svg';
import { useDeleteShiftFromClientJobMutation } from 'src/graphql/mutations/DeleteShiftFromClientJob';

const PositionDetail = styled.div`
  border: 1px solid var(--color-support-lines);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  :not(:first-child) {
    margin-top: 16px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  :not(:first-child) {
    margin-top: 8px;
    border-bottom: 1px solid var(--color-support-lines);
  }
`;

const PositionTitle = styled(Text)`
  margin-bottom: 16px;
`;

const PositionTotal = styled(Text)`
  text-align: right;
  margin-top: 16px;
`;

const SmallerText = styled(Text)`
  max-width: 80%;
  text-align: right;
  margin: 8px 0;
`;

const TitleText = styled(Text)`
  margin: 8px 0;
`;

const ButtonWithIcon = styled(Button)`
  text-transform: none;
  > span {
    align-items: center;
    display: flex;
  }
`;
const Img = styled.img`
  margin-right: 8px;
  margin-top: 1px;
  width: 16px;
`;
const MenuContainer = styled.div``;
const MenuIcon = styled(MaterialIcon)`
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  transition: opacity 0.2s linear;
  &:hover {
    opacity: 1;
  }
`;
const Divider = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px ${({ theme }) => theme.color.supportLines} solid;
`;
const PopUpContainer = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSurface};
  padding: 8px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;
const PopUpItem = styled.div`
  height: 32px;
  padding: 0px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  :hover {
    background-color: ${({ theme }) => theme.color.backgroundApp};
  }
`;

const PopUpItemWithDescription = styled.div`
  cursor: not-allowed;
  width: 172px;
  padding: 0px 16px;
  padding-top: 8px;
`;

const PopUpDescriptionContainer = styled.div`
  padding: 8px 0px;
`;

const PopUpDescriptionText = styled(Text)`
  color: ${({ theme }) => theme.color.inkNotasdark};
`;

const TextWithIcon = styled(Text)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) =>
    disabled ? theme.color.inkNotasdark : undefined};
  > i {
    margin-right: 8px;
  }
`;

const DialogContainer = styled.div`
  width: 360px;
  padding-bottom: 24px;
`;

const DialogDescription = styled.div`
  margin-top: 40px;
  padding-bottom: 16px;
  ${({ theme }) => theme.text.preset5P}
`;
const DialogDisclaimer = styled.div`
  ${({ theme }) => theme.text.preset5P};
  color: ${({ theme }) => theme.color.inkNotasdark};
`;

const DialogPosition = styled.span`
  font-weight: ${({ theme }) => theme.text.semiBold};
`;

const DialogFooter = styled.div`
  padding-top: 16px;
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 12px;
  text-transform: none;
  padding-left: 16px;
  padding-right: 16px;
  &.mdc-button:not(:disabled) {
    color: ${({ theme }) => theme.color.inkDark};
    border-color: ${({ theme }) => theme.color.inkDark};
  }
`;

const ConfirmButton = styled(Button)`
  text-transform: none;
  padding-left: 16px;
  padding-right: 16px;
  &.mdc-button:not(:disabled) {
    background-color: ${({ theme }) => theme.color.statusDestructive};
    color: ${({ theme }) => theme.color.inkClear};
  }
`;

interface RemoveShiftDialogProps {
  positionName: string;
  shiftId: string;
}

const RemoveShiftDialog: React.FC<RemoveShiftDialogProps> = ({
  positionName,
  shiftId,
}) => {
  const modalContext = useContext(ModalContext);
  const [deleteShiftFromClientJob, { loading }] =
    useDeleteShiftFromClientJobMutation(shiftId);

  const handleSubmit = async () => {
    await deleteShiftFromClientJob({ variables: { shiftId } });

    modalContext?.setIsModalOpen(false);
  };

  return (
    <Modal small title="Remove Shift">
      <DialogContainer>
        <DialogDescription>
          {'Are you sure you want to remove this '}
          <DialogPosition>{positionName} </DialogPosition>
          shift?
        </DialogDescription>
        <DialogDisclaimer>
          You will need to create it from scratch if you want it back
        </DialogDisclaimer>
      </DialogContainer>
      <DialogFooter>
        <CancelButton
          outlined
          disabled={loading}
          onClick={() => modalContext?.setIsModalOpen(false)}
        >
          Cancel
        </CancelButton>
        <ConfirmButton disabled={loading} onClick={handleSubmit}>
          Remove it
        </ConfirmButton>
      </DialogFooter>
    </Modal>
  );
};

interface ShiftProps {
  shift: Shift;
  timezone: string;
  isDeletable: boolean;
  canEdit: boolean;
  location: {
    lat?: number;
    lng?: number;
  };
}

const ShiftDetail: React.FC<ShiftProps> = ({
  shift,
  timezone,
  location,
  isDeletable,
  canEdit,
}) => {
  const {
    endDateTime,
    position,
    quantity,
    rate,
    startDateTime,
    tipAmount,
    unpaidBreakMinutes,
    tipType,
  } = shift;
  const modalContext = useContext(ModalContext);

  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  const tzStartDateTime = utcToZonedTime(startDateTime, timezone);
  const tzEndDateTime = utcToZonedTime(endDateTime, timezone);

  const totalRate = calculateTotalRate(
    rate,
    startDate,
    endDate,
    unpaidBreakMinutes,
    quantity,
    tipAmount,
  );
  const formattedRate = formatMoney(rate);
  const positionTitle = `${quantity} ${position.name}`;
  const startTime = format(tzStartDateTime, 'MMMM dd, yyyy h:mm aaaa');
  const endTime = format(tzEndDateTime, 'MMMM dd, yyyy h:mm aaaa');
  let date = `${startTime} - ${endTime}`;
  let tipText = '';
  if (tipType === TipType.INCLUDE_TIP && tipAmount) {
    tipText = `${formatMoney(tipAmount)}/hr`;
  } else if (tipType === TipType.ON_SITE) {
    tipText = 'Not included, Tip on-site';
  } else if (tipType === TipType.ALLOW_JAR) {
    tipText = 'Not included, Allow Tip Jar';
  }
  const rateText = formatMoney(totalRate);
  if (unpaidBreakMinutes) {
    date += ` (${unpaidBreakMinutes} min break)`;
  }
  const handleDeleteShift = () => {
    modalContext?.setModalContent(
      <RemoveShiftDialog
        positionName={shift.position.name}
        shiftId={shift.id}
      />,
    );
    modalContext?.setCloseOnOutsideClick(false);
    modalContext?.setLightboxBackground(undefined);
  };

  if (!modalContext?.isModalOpen) {
    modalContext?.setModalContent(null);
  }

  const handleOpenEditModal = () => {
    modalContext?.setCloseOnOutsideClick(false);
    modalContext?.setModalContent(
      <EditShiftModal
        location={location}
        timezone={timezone}
        shift={{
          ...shift,
          startDateTime: tzStartDateTime,
          endDateTime: tzEndDateTime,
        }}
      />,
    );
  };

  const handleMenuClick = (e: { clientY: number; clientX: number }) => {
    const menuItemOffScreenMargin = 15;
    const top = e.clientY;
    let left = e.clientX;
    const excess = left + menuItemOffScreenMargin - window.innerWidth;

    left = left > window.innerWidth ? left - excess : left;

    modalContext?.setCloseOnOutsideClick(true);
    modalContext?.setLightboxBackground('transparent');
    modalContext?.setIsModalOpen(true);
    modalContext?.setModalContent(
      <ModalContainer top={top + 'px'} left={left + 'px'}>
        <PopUpContainer>
          <PopUpItem onClick={handleOpenEditModal}>
            <TextWithIcon preset="preset6">
              <Img src={editBtn} alt="edit shift" />
              Edit Shift
            </TextWithIcon>
          </PopUpItem>
          <Divider />
          {isDeletable ? (
            <PopUpItem onClick={handleDeleteShift}>
              <TextWithIcon
                preset="preset6"
                color="var(--color-status-destructive)"
              >
                <Img src={deleteBtn} alt="remove shift" />
                Remove Shift
              </TextWithIcon>
            </PopUpItem>
          ) : (
            <PopUpItemWithDescription>
              <TextWithIcon preset="preset6" disabled>
                <Img src={deleteBtnGray} alt="remove shift" />
                Remove Shift
              </TextWithIcon>
              <PopUpDescriptionContainer>
                <PopUpDescriptionText preset="preset7P">
                  We are already working on staffing this shift. For removal
                  please contact us.
                </PopUpDescriptionText>
              </PopUpDescriptionContainer>
            </PopUpItemWithDescription>
          )}
        </PopUpContainer>
      </ModalContainer>,
    );
  };

  return (
    <PositionDetail>
      <FlexContainer>
        <PositionTitle preset="preset4" bold>
          {positionTitle}
        </PositionTitle>
        <MenuContainer>
          {canEdit && (
            <IconButton isLink onClick={handleMenuClick}>
              <MenuIcon icon="more_horiz" />
            </IconButton>
          )}
        </MenuContainer>
      </FlexContainer>
      <FlexContainer>
        <TitleText preset="preset6" color="var(--color-ink-notasdark)">
          Date
        </TitleText>
        <SmallerText preset="preset6">{date}</SmallerText>
      </FlexContainer>
      <FlexContainer>
        <TitleText preset="preset6" color="var(--color-ink-notasdark)">
          Rate
        </TitleText>
        <SmallerText preset="preset6">{formattedRate}/hr each</SmallerText>
      </FlexContainer>
      {tipText && (
        <FlexContainer>
          <TitleText preset="preset6" color="var(--color-ink-notasdark)">
            Tip
          </TitleText>
          <SmallerText preset="preset6">{tipText}</SmallerText>
        </FlexContainer>
      )}
      <PositionTotal preset="preset3" bold>
        {rateText}
      </PositionTotal>
    </PositionDetail>
  );
};

type Props = {
  booking: Job;
};

const ShiftsCard: React.FC<Props> = ({ booking }) => {
  const timezone = booking.venue?.address.timezone || DEFAULT_TIMEZONE;
  const { startDateTime } = booking;
  const isEventSoon =
    differenceInHours(new Date(startDateTime), new Date()) < 24;
  const modalContext = useContext(ModalContext);

  if (!modalContext?.isModalOpen) {
    modalContext?.setModalContent(null);
  }

  const openModal = () => {
    modalContext?.setModalContent(
      <NewShiftModal
        jobId={booking.id}
        location={{
          lat: booking.venue?.address.lat,
          lng: booking.venue?.address.lng,
        }}
        selectedPositions={booking.shifts?.map((shift) => shift.position.id)}
        timezone={timezone}
      />,
    );
    modalContext?.setIsModalOpen(true);
  };

  return (
    <Card
      title="Shifts"
      actionComponent={
        !isEventSoon && (
          <ButtonWithIcon onClick={openModal}>
            <Img src={addBtn} alt="add new shift" /> New Shift
          </ButtonWithIcon>
        )
      }
    >
      {booking.shifts?.map((shift, i) => {
        return (
          <ShiftDetail
            key={`${shift.startDateTime}-${i}`}
            timezone={timezone}
            location={{
              lng: booking.venue?.address.lng,
              lat: booking.venue?.address.lat,
            }}
            isDeletable={
              !!booking.shifts && booking.shifts.length > 1 && shift.isDeletable
            }
            canEdit={!isEventSoon}
            shift={shift}
          />
        );
      })}
    </Card>
  );
};

export default ShiftsCard;
