import React, { useState } from 'react';
import {
  Controller,
  ErrorMessage as FormErrorMessage,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '@material/react-text-field';
import * as Yup from 'yup';
import Button from 'src/components/Button';
import Label from 'src/components/Label';
import TextField from 'src/components/TextField';
import Text from 'src/components/Text';
import { ErrorMessage } from 'src/components/StatusMessages';
import { PASSWORD_RESET_EMAIL_ENDPOINT } from 'src/routes';

const Container = styled.div`
  margin-top: 16px;
`;

type FormValues = {
  email: string;
};

const VerificationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required(''),
});

const VerificationEmailForm: React.FC = () => {
  const [statusMessage, setStatusMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    control,
    errors: formErrors,
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onBlur',
    validationSchema: VerificationSchema,
  });

  const onSubmit = async (values: FormValues) => {
    setStatusMessage(undefined);
    setErrorMessage(undefined);
    const rawResponse = await fetch(PASSWORD_RESET_EMAIL_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
    if (rawResponse.ok) {
      const successMessage = `We have sent a password reset email to ${values.email}. Please reset your password through the link in this email. If you cannot find it, please check the Junk/Spam folder.`;
      setStatusMessage(successMessage);
    } else {
      setErrorMessage(
        'The server is having issues processing your request. Please try again later',
      );
    }
  };

  return (
    <>
      <Text preset="preset5P">
        Please enter your email address to validate your account and reset your
        password. You may need to check your spam folder.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label required>Email</Label>
        <TextField>
          <Controller
            as={
              <Input
                data-testid="email-input"
                type="email"
                isValid={!formErrors.email}
              />
            }
            control={control}
            name="email"
          />
        </TextField>
        <FormErrorMessage
          errors={formErrors}
          name="email"
          as={<ErrorMessage />}
        />
        <Container>
          {statusMessage && <Text preset="preset7">{statusMessage}</Text>}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
        <Container>
          <Button data-testid="submit-button" type="submit">
            Send email
          </Button>
        </Container>
      </form>
    </>
  );
};

export default VerificationEmailForm;
