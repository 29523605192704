import styled from 'styled-components';
import { Button } from '@material/react-button';

export const AddButton = styled(Button)`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  align-content: center;
  > .mdc-button__label {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: inherit;
  }
`;

export const Centered = styled.div`
  text-align: center;
  margin-top: 24px;
`;
