import gql from 'graphql-tag';

export const GET_MY_CLIENT_JOBS = gql`
  query MyClientJobs(
    $first: Int!
    $after: String
    $filters: MyClientJobsFilters
  ) {
    myClientJobs(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
        remainingEdges
        totalCount
      }
      edges {
        cursor
        node {
          id
          name
          description
          startDateTime
          endDateTime
          mealProvided
          useBackDoor
          tendersAmount
          total
          locationContact {
            id
            name
          }
          status
          venue {
            id
            name
            parkingInstructions
            address {
              streetName
              streetNumber
              interiorNumber
              neighborhood
              city
              state
              country
              zip
              lat
              lng
              timezone
            }
          }
        }
      }
    }
  }
`;
