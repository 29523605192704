import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GetClientProfile } from './__generated__/GetClientProfile';

const GET_CLIENT_PROFILE_QUERY = gql`
  query GetClientProfile {
    profile: getMyClientProfile {
      allowNoTipOption
      email
      firstName
      lastName
    }
  }
`;

export const useGetClientProfileQuery = (
  options?: QueryHookOptions<GetClientProfile>,
) => useQuery<GetClientProfile>(GET_CLIENT_PROFILE_QUERY, options);

export default GET_CLIENT_PROFILE_QUERY;
